<template>
  <div class="">
    <div class="p-3 d-flex flex-wrap">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 mb-0"
        v-for="(event_c, index) in event_categories"
        :key="index"
      >
        <div class="event-category">
          <h5>{{ event_c.category_name }}</h5>
          <div
            class="food-checkbox px-2"
            v-for="(day, index1) in event_c.days"
            :key="index1"
          >
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              DAY {{ index1 + 1 }}
            </label>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              BREAK - FAST
              <input
                v-if="day.breakfast == true"
                type="checkbox"
                v-model="day.c_breakfast"
              />
            </label>
            <div class="food-name">
              <label> {{ day.breakfast_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
              >LUNCH
              <input
                v-if="day.lunch == true"
                type="checkbox"
                v-model="day.c_lunch"
              />
            </label>
            <div class="food-name">
              <label> {{ day.lunch_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              DINNER
              <input
                v-if="day.dinner == true"
                type="checkbox"
                v-model="day.c_dinner"
              />
            </label>
            <div class="food-name">
              <label> {{ day.dinner_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              OTHER SERVICE 1
              <input
                v-if="day.other_service_1 == true"
                type="checkbox"
                v-model="day.c_other_service_1"
              />
            </label>
            <div class="food-name">
              <label> {{ day.other_service_1_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              OTHER SERVICE 2
              <input
                v-if="day.other_service_2 == true"
                type="checkbox"
                v-model="day.c_other_service_2"
              />
            </label>
            <div class="food-name">
              <label> {{ day.other_service_2_name }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex me-2">
        <button class="submit-btn" variant="success" @click="postEvent()">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      event_categories: [],

      current_event_id: "",
      categories: [],
      services: [],
      selected_food_category: [],
    };
  },

  watch: {
    $route: {
      handler: function () {
        if (localStorage.eventId) {
          this.current_event_id = localStorage.eventId;
          this.getEvent();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  //  mounted() {
  //      if (localStorage.eventId){
  //     this.current_event_id = localStorage.eventId;
  //      this.getEvent();
  //   }
  // },
  methods: {
    async getEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);

      await axios
        .post(`${this.$store.getters.baseUrl}/manage-event`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.categories = response.data.event_Details.categories;
            this.services = response.data.event_services;
            this.getCategory();
            /* for(var x = 0; x < this.categories.length; x++) {
                    this.event_categories.push({category_name:categories[x].name,category_id:categories[x].id,days:[]});
                  for(var y = 0;y < this.services.length;y ++){
                      this.event_categories[x].days.push({breakfast:services[y].break_fast == 1 ? true : false,breakfast_name:services[y].break_fast_name,
                      lunch:services[y].lunch == 1 ? true : false,lunch_name:services[y].lunch_name,dinner:services[y].dinner == 1 ? true : false,dinner_name: services[y].dinner_name,
                      other_service_1: services[y].other_service_1 == 1 ? true : false,other_service_1_name: services[y].other_service_1_name,
                      other_service_2: services[y].other_service_2 == 1 ? true : false,other_service_2_name: services[y].other_service_2_name}); 
                  }
                 
               }*/
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getCategory() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/manage-event-category-service`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            var c_selected_category_foods = response.data.category_services;
            for (var q = 0; q < this.categories.length; q++) {
              if (
                c_selected_category_foods.hasOwnProperty(this.categories[q].id)
              ) {
                this.selected_food_category.push(this.categories[q].id);
              }
            }

            for (var x = 0; x < this.categories.length; x++) {
              this.event_categories.push({
                category_name: this.categories[x].name,
                category_id: this.categories[x].id,
                days: [],
              });
              if (this.selected_food_category.includes(this.categories[x].id)) {
                for (var y = 0; y < this.services.length; y++) {
                  this.event_categories[x].days.push({
                    c_breakfast:
                      c_selected_category_foods[this.categories[x].id][y]
                        .break_fast == 1
                        ? true
                        : false,
                    breakfast: this.services[y].break_fast == 1 ? true : false,
                    breakfast_name: this.services[y].break_fast_name,
                    c_lunch:
                      c_selected_category_foods[this.categories[x].id][y]
                        .lunch == 1
                        ? true
                        : false,
                    lunch: this.services[y].lunch == 1 ? true : false,
                    lunch_name: this.services[y].lunch_name,
                    c_dinner:
                      c_selected_category_foods[this.categories[x].id][y]
                        .dinner == 1
                        ? true
                        : false,
                    dinner: this.services[y].dinner == 1 ? true : false,
                    dinner_name: this.services[y].dinner_name,
                    c_other_service_1:
                      c_selected_category_foods[this.categories[x].id][y]
                        .other_service_1 == 1
                        ? true
                        : false,
                    other_service_1:
                      this.services[y].other_service_1 == 1 ? true : false,
                    other_service_1_name: this.services[y].other_service_1_name,
                    c_other_service_2:
                      c_selected_category_foods[this.categories[x].id][y]
                        .other_service_2 == 1
                        ? true
                        : false,
                    other_service_2:
                      this.services[y].other_service_2 == 1 ? true : false,
                    other_service_2_name: this.services[y].other_service_2_name,
                  });
                }
              } else {
                for (var y = 0; y < this.services.length; y++) {
                  this.event_categories[x].days.push({
                    breakfast: this.services[y].break_fast == 1 ? true : false,
                    breakfast_name: this.services[y].break_fast_name,
                    lunch: this.services[y].lunch == 1 ? true : false,
                    lunch_name: this.services[y].lunch_name,
                    dinner: this.services[y].dinner == 1 ? true : false,
                    dinner_name: this.services[y].dinner_name,
                    other_service_1:
                      this.services[y].other_service_1 == 1 ? true : false,
                    other_service_1_name: this.services[y].other_service_1_name,
                    other_service_2:
                      this.services[y].other_service_2 == 1 ? true : false,
                    other_service_2_name: this.services[y].other_service_2_name,
                  });
                }
              }
            }

            //  console.log(this.selected_food_category);
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async postEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);
      for (var z = 0; z < this.event_categories.length; z++) {
        formdata.append(
          "category_services[" + z + "][category]",
          this.event_categories[z].category_id
        );

        for (var s = 0; s < this.event_categories[z].days.length; s++) {
          formdata.append(
            "category_services[" + z + "][days][" + s + "][break_fast]",
            this.event_categories[z].days[s].c_breakfast == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][lunch]",
            this.event_categories[z].days[s].c_lunch == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][dinner]",
            this.event_categories[z].days[s].c_dinner == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][other_service_1]",
            this.event_categories[z].days[s].c_other_service_1 == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][other_service_2]",
            this.event_categories[z].days[s].c_other_service_2 == true ? 1 : 0
          );
        }
      }

      await axios
        .post(
          `${this.$store.getters.baseUrl}/event-category-service-update`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // this.$swal(
            //   "Success",
            //   "Event Category foods updated successfully",
            //   "success"
            // );
            this.$swal({
              position: 'middle',
              icon: 'success',
              title: 'Event Category foods updated successfully',
              timer: 1500
            })
            this.$router.push({ name: "manage-event" });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
