<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="px-2">
          <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
          <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
        </div>
        <router-link class="add-role" to="/scan" title="Role">
          Back</router-link
        >
      </div>
      <div class="general-page px-3 py-3">
        <div class="event-select">
          <div class="d-flex flex-wrap">
            <div class="col-6 col-lg-4 col-xl-3 px-1 mb-1 mb-xxl-1">
              <p class="choose-evnet">Food Service</p>
              <select
                v-if="default_setting"
                v-model="event_type"
                @focus="event_typeEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in events_details.total_hall_entry"
                  :key="index"
                  :value="'Hall-' + event"
                >
                  Hall-{{ event }}
                </option>
                <option v-if="events_details.kitbag == 1" value="kitbag">
                  kitbag
                </option>
                <option v-if="events_details.magazine == 1" value="magazine">
                  magazine
                </option>
                <option
                  v-if="events_details.certificates == 1"
                  value="certificates"
                >
                  certificates
                </option>
                <option v-if="event_service.break_fast == 1" value="break_fast">
                  break_fast
                </option>
                <option v-if="event_service.lunch == 1" value="lunch">
                  lunch
                </option>
                <option v-if="event_service.dinner == 1" value="dinner">
                  dinner
                </option>
              </select>
            </div>

            <div class="col-6 col-lg-4 col-xl-3 px-1 mb-1 mb-xxl-1">
              <p class="choose-evnet">Select Day</p>
              <select
                v-if="default_setting"
                v-model="day"
                @focus="dayEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in event_name.total_days"
                  :key="index"
                  :value="event"
                >
                  Day-{{ event }}
                </option>
              </select>
            </div>
            <div class="col-6 col-lg-4 col-xl-3 px-1 mb-1 mb-xxl-1">
              <p class="choose-evnet">Select Session</p>
              <select
                v-if="default_setting"
                v-model="session"
                @focus="sessionEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in event_name.max_session_per_day"
                  :key="index"
                  :value="default_setting.session"
                >
                  Session-{{ event }}
                </option>
              </select>
            </div>
          </div>
          <button class="submit-btn ms-1 mt-3" @click="setDefault()">
            Set Default
          </button>
        </div>
      </div>
    </div>
    <div class="ps-3 mt-3">
      <div class="general-page px-2 py-3">
        <h5 class="mb-3 text-black">
          Food Entry({{ this.event_name.name }}, {{ this.event_type }}, Day-{{
            this.day
          }}, Session-{{ this.session }} )
        </h5>
        <div class="">
          <input
            type="text"
            name="barcode"
            v-on:keyup.enter="postKitbafService()"
            v-model="reg_no"
            @focus="reg_noEmpty == false"
            required
            class="form-control"
            style="height: 65px; width: 41%; font-size: 30px"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="col-12 col-md-6 col-lg-5 col-xl-4 px-1 mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5>Hall Entry Count</h5>
              <button class="ms-2 add-role mb-2" @click="exportData()">
                Export All
              </button>
            </div>
            <table class="table-page">
              <tr class="w-100">
                <th>SNO</th>
                <th>Category</th>
                <th>Total</th>
              </tr>
              <tr v-if="hall_entries.length == 0">
                <td colspan="15" class="text-center">No records found</td>
              </tr>
              <tr
                v-else
                v-for="(registration, index) in hall_entries"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td class="">{{ registration.category_name }}</td>
                <td>{{ registration.category_count }}</td>
              </tr>
              <tr>
                <td></td>
                <td><b>Total Count</b></td>
                <td>{{ hall_entries.total_count }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      event: "",
      eventEmpty: false,
      event_type: "",
      event_typeEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      day: "",
      dayEmpty: false,
      barcode: "",
      barcodeEmpty: false,
      session: "",
      sessionEmpty: false,
      events: [],
      event_categories: [],
      hall_entries: [],
      default_setting: "",
      url: "",
      event_name: "",
      event_service: "",
      service_registration: "",
      events_details: "",
      eventId: localStorage.getItem("eventId"),
    };
  },

  watch: {
    $route: {
      handler: function () {
        this.getEvents();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getEvents();
    this.getHallExitReport();
  },
  methods: {
    async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/general-default-list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.default_setting = response.data.default_settings;
            if (this.default_setting != null) {
              this.event = this.default_setting.event_id;
              this.event_type = this.default_setting.entry_type;
              this.day = this.default_setting.days;
              this.session = this.default_setting.session;
              this.getEventDetails();
              this.getEventsDetail();
            }
            this.event_service = response.data.event_service;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventsDetail() {
      await axios
        .get(
          `${this.$store.getters.baseUrl}/general-default-data/${this.event}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events_details = response.data.events;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async getEventDetails() {
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/get-spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_name = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async postKitbafService() {
      const formdata = new FormData();
      formdata.append("barcode", this.reg_no);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/post-food-service-report`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            console.log(response);
            // this.$swal("Success", "Entry Made successfully", "success");
            this.$swal({
              position: 'middle',
              icon: 'success',
              title: 'Entry Made successfully',
              timer: 1500
            })
            this.$router.push({ name: "foodentry" });
            this.$router.go();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async setDefault() {
      if (this.event_type == "") {
        this.event_typeEmpty = true;
        return;
      }
      if (this.day == "") {
        this.dayEmpty = true;
        return;
      }
      if (this.session == "") {
        this.sessionEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("entry_type", this.event_type);
      formdata.append("day", this.day);
      formdata.append("session", this.session);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/default-hall-entry-report`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "General set successfully", "success");
            this.$router.push({ name: "foodentry" });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getHallExitReport() {
      await axios
        .get(`${this.$store.getters.baseUrl}/food-service-report`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.hall_entries = response.data.hall_entries;
            this.url = response.data.url;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async exportData() {
      const link = document.createElement("a");
      link.href = this.url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      this.$swal("Success", "Report exported successfully", "success");
    },
  },
};
</script>
