<template>
  <div class="">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <TopHeader />
    <div
      class="ps-3 mt-3"
      v-if="
        $store.getters.adminDetail.includes('CAN-REGISTRATION-REGISTRATION')
      "
    >
      <div class="general-page px-3 py-3">
        <div
          class="d-flex justify-content-between align-items-center mb-2"
          v-if="
            $store.getters.adminDetail.includes('CAN-EXCEL UPLOAD-REGISTRATION')
          "
        >
          <h5 class="mb-0">Import Registration CSV File</h5>
          <div class="d-flex">
            <!-- <button v-on:click="addRole" class="upload-img">
              Upload Images
            </button> -->
            <button
              class="add-role ms-2"
              variant="success"
              @click="downloadFile()"
            >
              Sample Download
            </button>
            <router-link
              class="add-role"
              style="margin-left: 15px"
              to="/registration"
              title="Role"
            >
              Back</router-link
            >
          </div>
        </div>
        <div class="event-select">
          <div class="d-flex">
            <div class="col px-1">
              <p class="choose-evnet">Choose Event</p>
              <select
                v-model="event"
                @change="getRegistrationEvents()"
                @focus="eventEmpty = false"
                required
                name=""
                id=""
              >
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
              <div v-if="eventEmpty" style="color: red">
                Event ID is required
              </div>
            </div>
            <div class="col px-1">
              <p class="choose-evnet">File</p>
              <input
                type="file"
                v-on:change="uploadFiles"
                @focus="fileEmpty = false"
                required
              />
              <div v-if="fileEmpty" style="color: red">File is required</div>
            </div>
          </div>
          <button
            class="submit-btn ms-1 mt-3"
            @click="
              (pageSelected = 1), (rowsAndFiltersClicked = true), saveFile()
            "
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    <div class="ps-3 mt-3">
      <div class="general-page tabel-data-page px-4 py-3">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mt-2"
        >
          <div class="sort d-flex">
            <p class="mb-0">Show</p>
            <select
              class="mx-2 form-control"
              v-model="rows"
              @click="getRegistrations()"
              @change="
                (pageSelected = 1),
                  (rowsAndFiltersClicked = true),
                  getRegisterations()
              "
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p class="mb-0">Entries</p>
          </div>
          <div class="search-input">
            <!-- <input type="text" placeholder="Search" /> -->
            <input
              class="form-control"
              type="text"
              v-model="search"
              placeholder="Search..."
              aria-label="Search"
              style="width: 300px"
              @keyup="
                (pageSelected = 1),
                  (rowsAndFiltersClicked = true),
                  getRegisterations()
              "
            />
          </div>
        </div>
        <table class="table-page mt-2">
          <tr class="w-100">
            <th>S.No</th>
            <th>Registration No</th>
            <th>Category Name</th>
            <th>Full Name</th>
            <th>Mobile No</th>
            <th>City</th>
            <th>Print Staus</th>
          </tr>
          <tr v-if="registrations.length == 0">
            <td colspan="8" class="text-center">No records found</td>
          </tr>
          <tr
            v-else
            v-for="(registration, index) in registrations"
            :key="index"
          >
            <td>{{ (currentPage - 1) * rows + index + 1 }}</td>
            <td>{{ registration.reg_no }}</td>
            <td v-if="registration.category != null">
              {{ registration.category.name }}
            </td>
            <td v-else>-</td>
            <td v-if="registration.full_name != null">
              {{ registration.full_name }}
            </td>
            <td v-else>-</td>
            <td v-if="registration.mobile != null">
              {{ registration.mobile }}
            </td>
            <td v-else>-</td>
            <td v-if="registration.city != null">{{ registration.city }}</td>
            <td v-else>-</td>
            <td v-if="registration.printed_from != null">Printed</td>
            <td v-else>Not Printed</td>
            <!-- <td>
              <button class="ms-2">
                <i class="fa-solid fa-trash"></i>
              </button>
            </td> -->
          </tr>
        </table>
        <div class="pagi-nation">
          <ul class="d-flex mt-3 mb-0 p-0">
            <li v-if="lastPage != 1 && registrations.length > 0">
              <div
                class="pagi-a left-rit-arrow disabled"
                variant="outline-primary"
                @click="first()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[0].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-double-left"></i>
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                variant="outline-primary"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[0].display
                      ? true
                      : false
                    : false
                "
                @click="previousPage()"
              >
                <i class="fas fa-angle-left"></i>
              </div>
            </li>
            <li class="d-flex">
              <div
                class="pagi-a"
                :pressed="page.activeStatus == true ? true : false"
                v-show="page.display"
                :id="page.id"
                @click="buttonClick(index)"
                size="lg"
                variant="outline-primary"
                v-for="(page, index) in totalPages"
                :key="index"
              >
                {{ index + 1 }}
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                href="#"
                variant="outline-primary"
                @click="nextPage()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[totalPages.length - 1].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-right"></i>
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                href="#"
                variant="outline-primary"
                @click="last()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[totalPages.length - 1].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-double-right"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="text-center" v-if="error_details.length > 0">
      <p>Duplicate Registration Id:</p>
      <!-- <div v-for="(error_detail, index) in error_details" :key="index">         -->
      <p>{{ this.error_details.join(" , ") }}</p>
      <!-- </div> -->
    </div>

    <div class="modal-upload-img" v-bind:class="{ uploadImg: isActive }">
      <div class="in-modal-upload-img">
        <div class="bg-white px-5 py-4 rounded-1 rounded">
          <div class="form-action">
            <p class="text-dark mb-1">Choose Event</p>
            <select
              style="width: 60%"
              v-model="event"
              @change="getRegistrationEvents()"
              required
              name="event"
              id="event"
            >
              <option
                v-for="(event, index) in events"
                :key="index"
                :value="event.id"
              >
                {{ event.name }}
              </option>
            </select>
            <div v-if="eventEmpty" style="color: red">Event ID is required</div>
          </div>
          <div class="form-action mt-2 mb-2">
            <p class="text-dark mb-1">Upload File</p>
            <input
              class="mt-0 ms-0 p-0"
              type="file"
              v-on:change="uploadPhotos"
              @focus="photoEmpty == false"
              required
            />
          </div>
          <div v-if="photoEmpty" style="color: red">File is required</div>
          <button
            class="save-btn"
            type="submit"
            variant="success"
            @click="uploadImage()"
          >
            Upload
          </button>
        </div>
      </div>
      <div @click="addRole" v-bind:class="{ dummy_add_cate: isActive }"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TopHeader from "../components/TopHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    TopHeader,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,

      loader: false,
      isActive: false,
      addCategory: false,
      event: "",
      eventEmpty: false,
      file: "",
      fileEmpty: false,
      photo: "",
      photoEmpty: false,
      events: [],
      registration_events: [],
      registrations: [],
      error_details: [],

      totalUsers: 0,
      rowsAndFiltersClicked: false,
      search: "",
      firstDisableStatus: false,
      lastDisableStatus: false,
      rows: 10,
      pageSelected: "",
      currentPage: "",
      lastPage: "",
      totalPages: [],
      buttonVariant: "success",
    };
  },
  mounted() {
    this.getRegistrationEvents();
    this.pagination();
  },
  methods: {
    addRole: function () {
      this.isActive = !this.isActive;
    },
    dummy_add_cate: function () {
      this.isActive = !this.isActive;
    },

    buttonClick(index) {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[index].activeStatus = true;
      let displayedPages = this.totalPages.filter((ele) => ele.display == true);
      if (displayedPages[0] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
          this.totalPages[index + 4].display = false;
        }
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == true
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index + 4].display = false;
        }
      }
      if (displayedPages[1] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
        }
      }
      if (displayedPages[3] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
        }
      }
      if (displayedPages[4] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
          this.totalPages[index - 4].display = false;
        }
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == true
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index - 4].display = false;
        }
      }
      this.pageSelected = this.totalPages[index].id.split(" ")[1];
      this.getRegisterations();
    },
    last() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[this.totalPages.length - 1].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      if (this.lastPage + 1 > 5) {
        for (var j = 0; j < 5; j++) {
          this.totalPages[this.totalPages.length - 1 - j].display = true;
        }
      } else {
        for (var k = 0; k < this.totalPages.length; k++) {
          this.totalPages[this.totalPages.length - 1 - k].display = true;
        }
      }
      this.pageSelected = this.totalPages.length;
      this.getRegisterations();
    },
    first() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[0].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      for (var j = 0; j < 5; j++) {
        this.totalPages[j].display = true;
      }
      this.pageSelected = 1;
      this.getRegisterations();
    },
    previousPage() {
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first - 1].display = true;
      this.totalPages[last].display = false;
      this.totalPages = this.totalPages.map((ele, index, totalArray) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
          totalArray[index - 1].activeStatus = true;
        }
        return ele;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.getRegisterations();
    },
    nextPage() {
      var nextArrStatus = false;
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first].display = false;
      this.totalPages[last + 1].display = true;
      this.totalPages = this.totalPages.map((ele) => {
        var data = ele;
        if (data.activeStatus == true) {
          data.activeStatus = false;
          nextArrStatus = true;
        } else if (nextArrStatus) {
          data.activeStatus = true;
          nextArrStatus = false;
        }
        return data;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.getRegisterations();
    },

    pagination() {
      console.log("page");
      if (this.lastPage + 1 > 5) {
        this.totalPages = [];
        for (var i = 1; i < this.lastPage + 1; i++) {
          this.totalPages.push({
            id: `page ${i}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: index < 5,
          activeStatus: index == 0,
        }));
      } else {
        this.totalPages = [];
        for (var j = 1; j < this.lastPage + 1; j++) {
          this.totalPages.push({
            id: `page ${j}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: true,
          activeStatus: index == 0,
        }));
      }
    },

    async getRegistrationEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/registration-events`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.url = `${this.$store.getters.url}/excel-template-download`;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getRegisterations() {
      this.isLoading = true;
      await axios
        .get(
          `${this.$store.getters.baseUrl}/registration-list?page=${this.pageSelected}&row=${this.rows}&search=${this.search}`,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.registrations = response.data.registrations;
            this.lastPage = response.data.pagination.last_page;
            this.currentPage = response.data.pagination.current_page;
            // this.totalUsers = response.data.pagination.total;
            // this.pagination();
            if (this.rowsAndFiltersClicked) {
              this.pagination();
              this.rowsAndFiltersClicked = false;
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    uploadFiles(event) {
      this.file = event.target.files[0];
    },
    uploadPhotos(event) {
      this.photo = event.target.files[0];
    },
    async saveFile() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      if (this.file == "") {
        this.fileEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      formdata.append("file", this.file);
      this.isLoading = true;
      await axios
        .post(`${this.$store.getters.baseUrl}/registration`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.error_details = response.data.error_details;
            // this.$swal("Success", "Registration file added successfully", "success");

            this.$swal({
              position: "middle",
              icon: "success",
              title: "Registration file added successfully",
              timer: 1500,
            });
            this.$router.push({ name: "registrationlist" });
            this.getRegisterations();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            console.log("error");
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.error_details = error.response.data.error_details;
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async uploadImage() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      if (this.photo == "") {
        this.photoEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      formdata.append("photos", this.photo);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/registration-image-upload`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal(
              "Success",
              "Registration Image added successfully",
              "success"
            );
            this.$router.push({ name: "registrationlist" });
            this.$router.go();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async downloadFile() {
      await axios
        .get(`${this.$store.getters.baseUrl}/registration-events`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.url = `${this.$store.getters.url}/excel-template-download`;
          const link = document.createElement("a");
          link.href = this.url;
          console.log(this.url);
          link.setAttribute("download", "");
          document.body.appendChild(link);
          link.click();
          this.$swal(
            "Success",
            "Registration report download successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
