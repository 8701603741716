<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page add-role-page px-2 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2">
            <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" />
              <div v-if="roleNameEmpty" style="color: red">Role name is required</div>
          </div>
          <router-link class="add-role" to="/roles" title="Role"> Back</router-link> 
        </div>
        <!-- <div class="px-2">
          <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" />
              <div v-if="roleNameEmpty" style="color: red">Role name is required</div>
              
        <div class="d-flex px-2">
        <router-link to="/home" title="Home">
              <i class="fa fa-arrow-left"> <h4 class="mb-0"> Dashboard </h4></i>
            </router-link>
        </div>
        </div> -->
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-md-6 col-lg-4 col-xl-3 px-2" v-for="(permission, index) in permissions" :key="index">
            <div class="per-missions">
              <div class="all-select d-flex align-items-center">
                <label>{{ permission.module_type }}</label>
              </div>
              <div class="single-select mt-1" v-for="(can, index1) in permission.permissions" :key="index1">
                <label><input class="" @change="addPermission(permission.permissions[index1])"
              
                switches
                stacked
                 type="checkbox" />{{ can.code }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex px-2 justify-content-end" v-if="selectedPermissions != ''">
          <button class="submit-btn" @click="savePermissions()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TopHeader from "../components/TopHeader.vue";
export default {
  components: { TopHeader },
  data(){
     return {
       loader: "",
      roleNameEmpty: false,
      roleName: "",
      selectedPermissions: [],
      permissions: [],
     };
  },
  mounted: async function () {
    await this.getRoles();
  },
  methods:{

      addPermission(permissionData) {
      if (this.selectedPermissions.includes(permissionData)) {
        let index = this.selectedPermissions.indexOf(permissionData);
        this.selectedPermissions.splice(index, 1);
      } else {
        this.selectedPermissions.push(permissionData);
      }
    },

     async getRoles(){
       await axios
         .get(`${this.$store.getters.baseUrl}/role/create`,{
          headers: { Authorization: `Bearer ${this.$store.getters.token}`},
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.permissions = response.data.modules;
           /* this.lastPage = response.data.pagination.last_page;
            this.currentPage = response.data.pagination.current_page;
            if (this.rowsAndFiltersClicked) {
              this.pagination();
              this.rowsAndFiltersClicked = false;
            }*/
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async savePermissions() {
      this.loader = true;

      if(this.roleName == "") {
        this.roleNameEmpty = true
        return
      }
      const formdata = new FormData();
      formdata.append("role", this.roleName);
      for(var i = 0; i < this.selectedPermissions.length; i++) {
        formdata.append("permissions["+i+"]", this.selectedPermissions[i].id)
      }
      await axios
        .post(`${this.$store.getters.baseUrl}/role`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => { 
          console.log(response);
          if (response.data.status == "success") {
            this.loader = false;
            this.$swal("Success", "Permission added for given role successfully", "success");
            this.roleName = ""
            this.selectedPermissions = []
            this.$router.push({name: 'roles'});
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
           this.loader = false;
          this.$swal("Error", error.response.data.message, "error");
        });
    },

  }
};
</script>
