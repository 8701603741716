<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3" v-if="$store.getters.adminDetail.includes('CAN-LIST-MANAGE EVENTS')">
      <div class="general-page px-3 py-3">
        <div class="event-select">
          <p class="choose-evnet">Choose Event Name</p>
          <select v-model="event" @change="getEvents()"  @focus="eventEmpty = false" required name="event" id="event">
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
              <div v-if="eventEmpty" style="color: red">
                Event ID is required
              </div>
        </div>
          <div class="col-md-6 mt-5">
          <button type="submit" class="btn btn-primary" v-on:click="set()">Set</button>
        </div>
      </div>
    </div>

    <div class="ps-3 mt-3">
      <div class="general-page">
        <div
          class="step-wizard d-flex flex-wrap align-items-center justify-content-center mt-3"
        >
          <button
            v-on:click="step1"
            v-bind:class="{ active: isActive1 }"
            class="steps"
          >
            <p>1</p>
            <h6>Basic Details</h6>
          </button>
          <button
            v-on:click="step2"
            v-bind:class="{ active: isActive2 }"
            class="steps"
          >
            <p>2</p>
            <h6>Event Categories</h6>
          </button>
          <button
            class="steps"
            v-on:click="step3"
            v-bind:class="{ active: isActive3 }"
          >
            <p>3</p>
            <h6>Food Privilege</h6>
          </button>
          <button
            class="steps"
            v-on:click="step4"
            v-bind:class="{ active: isActive4 }"
          >
            <p>4</p>
            <h6>Hall Categories</h6>
          </button>
          <button
            class="steps"
            v-on:click="step5"
            v-bind:class="{ active: isActive5 }"
          >
            <p>5</p>
            <h6>Batch Setup</h6>
          </button>
        </div>
        <StepWizard1 class="hid-step" v-bind:class="{ step1: isStep1 }" />
        <StepWizard2 class="hid-step" v-bind:class="{ step2: isStep2 }" />
        <StepWizard3 class="hid-step" v-bind:class="{ step3: isStep3 }" />
        <StepWizard4 class="hid-step" v-bind:class="{ step4: isStep4 }" />
        <StepWizard5 class="hid-step" v-bind:class="{ step5: isStep5 }" />
      </div>
    </div>
  </div>
</template>

<script>
import StepWizard1 from "../components/wizard/StepWizardUpdate1.vue";
import StepWizard2 from "../components/wizard/StepWizardUpdate2.vue";
import StepWizard3 from "../components/wizard/StepWizardUpdate3.vue";
import StepWizard4 from "../components/wizard/StepWizardUpdate4.vue";
import StepWizard5 from "../components/wizard/StepWizardUpdate5.vue";
import axios from "axios";

import TopHeader from "../components/TopHeader.vue";
export default {
  name: "HomeView",
  components: {
    TopHeader,
    StepWizard2,
    StepWizard1,
    StepWizard3,
    StepWizard4,
    StepWizard5,
  },
  data() {
    return {
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isStep4: false,
      isStep5: false,
      isActive1: true,
       isActive2: false,
        isActive3: false,
         isActive4: false,
          isActive5: false,

      event:"",
      events:[],
       eventEmpty:false,

    };
  },
  mounted(){
    this.getEvents();
    if (localStorage.eventId){
      this.event = localStorage.eventId;
    }
  },
  methods: {
          set() {
          localStorage.eventId = this.event;
           this.$swal("Success", "Event set successfully", "success");
             this.step1();
            this.$router.go();	
         //  this.getEvents();
      },
     async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/registration-events`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.events = response.data.events;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
      
    },
    step1: function () {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isActive1 = true;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
    },
    step2: function () {
      this.isStep1 = false;
      this.isStep2 = true;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isActive1 = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
    },
    step3: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = true;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = true;
      this.isActive4 = false;
      this.isActive5 = false;
    },
    step4: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = true;
      this.isStep5 = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = true;
      this.isActive5 = false;
    },
    step5: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = true;
    },
  },
};
</script>
