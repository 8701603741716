<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Event Name</label>
              <input type="text"  v-model="name" @focus="nameEmpty = false" placeholder="" />
                <div v-if="nameEmpty" style="color: red">
                  Event Name is required
                </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Event ID</label>
              <input type="text" v-model="code" @focus="codeEmpty = false" placeholder="" />
                <div v-if="codeEmpty" style="color: red">
                  Event ID is required
                </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Regsitration Start With</label>
              <input type="text" v-model="reg_start_with" @focus="reg_start_withEmpty = false" placeholder="" />
                <div v-if="reg_start_withEmpty" style="color: red">
                  Regsitration Start With is required
                </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Year</label>
              <input type="date" v-model="year" @focus="yearEmpty = false" placeholder="" />
                <div v-if="yearEmpty" style="color: red">
                  Year is required
                </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Event Type</label>
              <select class="py-0" name="" id="" v-model="event_type" @focus="event_typeEmpty = false" >
                <option value="Exhibition">Exhibition</option>
                <option value="Conference">Conference</option>
              </select>
                <div v-if="event_typeEmpty" style="color: red">
                  Event Type is required
                </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Conference / Exhibition Type</label>
              <select class="py-0" name="" id="" v-model="con_exh_type" @focus="con_exh_typeEmpty = false" >
                <option value="National">National</option>
                <option value="International">International</option>
              </select>
            </div>
              <div v-if="con_exh_typeEmpty" style="color: red">
                Conference / Exhibition Type is required
              </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Total Hall</label>
              <input type="number" v-model="hall_count" @focus="hall_countEmpty = false" placeholder="" />
            </div>
              <div v-if="hall_countEmpty" style="color: red">
                Total Hall is required
              </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Max Session/ Day</label>
              <input type="number" v-model="max_session_per_day" @focus="max_session_per_dayEmpty = false" placeholder="" />
            </div>
              <div v-if="max_session_per_dayEmpty" style="color: red">
                Max Session/ Day is required
              </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Event Days</label>
              <input type="number" name="event_days" id="event_days" v-model="event_days" @focus="event_daysEmpty = false" placeholder="" />
            </div>
              <div v-if="event_daysEmpty" style="color: red">
                Event Days is required
              </div>
          </div>
        </div>
        <div class="mt-0 col-12 d-flex flex-wrap px-3">
          <div class="mt-3 col">
            <div class="">
              <div class="all-select d-flex align-items-center">
                <label><input class="" type="checkbox" />Day-1</label>
              </div>
              <div class="single-select mt-2">
                <label><input class="" type="checkbox" />Break Fast</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Lunch</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Dinner</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
            </div>
          </div>
          <div class="mt-3 col">
            <div class="">
              <div class="all-select d-flex align-items-center">
                <label><input class="" type="checkbox" />Day-2</label>
              </div>
              <div class="single-select mt-2">
                <label><input class="" type="checkbox" />Break Fast</label>
              </div>
              <input class="food-input mb-1" type="text" />
              <input class="food-input mb-1" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Lunch</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Dinner</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
            </div>
          </div>
          <div class="mt-3 col">
            <div class="">
              <div class="all-select d-flex align-items-center">
                <label><input class="" type="checkbox" />Day-3</label>
              </div>
              <div class="single-select mt-2">
                <label><input class="" type="checkbox" />Break Fast</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Lunch</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Dinner</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
            </div>
          </div>
          <div class="mt-3 col">
            <div class="">
              <div class="all-select d-flex align-items-center">
                <label><input class="" type="checkbox" />Day-4</label>
              </div>
              <div class="single-select mt-2">
                <label><input class="" type="checkbox" />Break Fast</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Lunch</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
              <div class="single-select mt-1">
                <label><input class="" type="checkbox" />Dinner</label>
              </div>
              <input class="food-input" type="text" />
              <button class="add-food">+</button>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6 px-2">
            <div class="per-missions">
              <div class="all-select d-flex align-items-center">
                <label>
                   <!-- v-model="event_days" @focus="event_daysEmpty = false" -->
                  <input class="" type="checkbox" v-model="spot_register_info['']" @focus="spot_register_infoEmpty" />Choose Spot Register
                  Information
                </label>
              </div>
              <div class="d-flex flex-wrap">
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['title']" @focus="srititleEmpty"/>Title</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['name']" @focus="srinameEmpty" />Full Name</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['company']" @focus="sricompanyEmpty" />Company</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['designation']" @focus="sridesignationEmpty" />Designation</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['category']" @focus="sricategoryEmpty" />Category</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['photo']" @focus="sriphotoEmpty"/>Photo</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['city']" @focus="sricityEmpty" />City</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['mobile']" @focus="srimobileEmpty" />Mobile</label>
                </div>
                <div class="single-select col-12 col-sm-6 mt-1">
                  <label><input class="" type="checkbox" v-model="spot_register_info['email']" @focus="sriemailEmpty" />Email</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 col-12 col-lg-6 px-2">
          <div class="per-missions">
            <div class="all-select d-flex align-items-center">
              <label
                ><input class="" type="checkbox" />Choose KitBag/ Certificates/
                Exhibitor Directory/ Magazine</label
              >
            </div>
            <div class="single-select mt-1">
              <label><input class="" type="checkbox" v-model="kitbag" @focus="kitbagEmpty = false" />KitBag</label>
              <div v-if="kitbagEmpty" style="color: red">
                KitBag is required
              </div>
            </div>
            <div class="single-select mt-1">
              <label><input class="" type="checkbox" v-model="certificate" @focus="certificateEmpty = false" />Certificates</label>
              <div v-if="certificateEmpty" style="color: red">
                Certificates is required
              </div>
            </div>
            <div class="single-select mt-1">
              <label
                ><input class="" type="checkbox" v-model="magazine" @focus="magazineEmpty = false"/>Exhibitor Directory /
                Magazine</label
              >
              <div v-if="magazineEmpty" style="color: red">
                Exhibitor Directory/ Magazine required
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-2 all-select">
          <div class="single-select mt-3 d-flex align-items-center">
            <h6 class="mb-0 payment-h6">Payment</h6>
            <label><input class="ms-3 me-2" type="checkbox" v-model="payment" @focus="paymentEmpty = false"/>Yes</label>
          </div>
          <div v-if="paymentEmpty" style="color: red">
            Payment is required
          </div>
        </div>
        <div class="d-flex justify-content-end me-2">
          <button class="submit-btn" variant="success" @click="save()">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data(){
    return {
      name: "",
      nameEmpty:false,
      code: "",
      codeEmpty:false,
      year: "",
      yearEmpty:false,
      event_type: "",
      event_typeEmpty:false,
      con_exh_type: "",
      con_exh_typeEmpty:false,
      hall_count: "",
      hall_countEmpty:false,
      max_session_per_day: "",
      max_session_per_dayEmpty:false,
      event_days: "",
      event_daysEmpty:false,
      reg_start_with: "",
      reg_start_withEmpty:false,
      kitbag: "",
      kitbagEmpty:false,
      certificate: "",
      certificateEmpty:false,
      magazine: "",
      magazineEmpty:false,
      payment: "",
      paymentEmpty:false,
      spot_register_info:[],
    };
  },
   mounted() {
    this.getRoles();
  },
  methods: {
    async save() {
      if (this.name == "") {
        this.nameEmpty = true;
        return;
      }
       if (this.code == "") {
        this.codeEmpty = true;
        return;
      }
       if (this.year == "") {
        this.yearEmpty = true;
        return;
      }
       if (this.event_type == "") {
        this.event_typeEmpty = true;
        return;
      }
       if (this.con_exh_type == "") {
        this.con_exh_typeEmpty = true;
        return;
      }
      if(this.hall_count == "") {
        this.hall_countEmpty = true;
        return;
      }
        if (this.max_session_per_day == "") {
        this.max_session_per_dayEmpty = true;
        return;
      }
        if (this.event_days == "") {
        this.event_daysEmpty = true;
        return;
      }
        if (this.reg_start_with == "") {
        this.reg_start_withEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("name", this.name);
       formdata.append("code", this.code);
        formdata.append("year", this.year);
        formdata.append("event_type", this.event_type);
        formdata.append("con_exh_type", this.con_exh_type);
        formdata.append("hall_count", this.hall_count);
        formdata.append("max_session_per_day", this.max_session_per_day);
        formdata.append("event_days", this.event_days);
        formdata.append("reg_start_with", this.reg_start_with);
        formdata.append("kitbag", this.kitbag);
        formdata.append("certificate", this.certificate);
        formdata.append("magazine", this.magazine);
        formdata.append("payment", this.payment);
        formdata.append("spot_register_info[]", this.spot_register_info['title']);
        formdata.append("spot_register_info[]", this.spot_register_info['company']);
        formdata.append("spot_register_info[]", this.spot_register_info['name']);
        formdata.append("spot_register_info[]", this.spot_register_info['designation']);
        formdata.append("spot_register_info[]", this.spot_register_info['category']);
        formdata.append("spot_register_info[]", this.spot_register_info['photo']);
        formdata.append("spot_register_info[]", this.spot_register_info['mobile']);
        formdata.append("spot_register_info[]", this.spot_register_info['city']);
        formdata.append("spot_register_info[]", this.spot_register_info['email']);
      await axios
        .post(`${this.$store.getters.baseUrl}/events`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Admin added successfully", "success");
            this.$router.push({name: 'users'});
            this.name = "";
            this.username = "";
            this.email = "";
            this.password = "";
            this.confirmpassword = "";
            this.role = "";
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>


