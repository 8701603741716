<template>
  <div class="">
    <div class="p-3 d-flex flex-wrap">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 mb-3"
        v-for="(hall, index) in hall_show"
        :key="index"
      >
        <div class="event-category">
          <h5>Hall {{ index + 1 }}</h5>
          <div
            class="first-checkbox"
            v-for="(hall_c, index1) in hall.categories"
            :key="index1"
          >
            <label class="d-flex align-items-center justify-content-between">
              {{ hall_c.category_name }}
              <input type="checkbox" v-model="hall_c.category_select" />
            </label>
          </div>
        </div>
      </div>
    </div>
        <div class="d-flex me-3 mb-4">
          <button @click="postEvent()" class="submit-btn">Submit</button>
        </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      hall: [],
      categories: [],
      hall_show: [],

      current_event_id: "",
      selected_hall_category: [],
    };
  },

  watch: {
    $route: {
      handler: function () {
        if (localStorage.eventId) {
          this.current_event_id = localStorage.eventId;
          this.getEvent();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  //  mounted() {
  //      if (localStorage.eventId){
  //     this.current_event_id = localStorage.eventId;
  //       this.getEvent();
  //   }
  // },
  methods: {
    async getEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);

      await axios
        .post(`${this.$store.getters.baseUrl}/manage-event`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.hall = response.data.event_Details.total_hall_entry;
            this.categories = response.data.event_Details.categories;
            this.getHall();
            /*for(var x = 0; x < this.hall; x++) {
                    this.hall_show.push({categories:[]});
                  for(var y = 0;y < this.categories.length;y ++){
                    
                      if(this.selected_hall_category[x].hall.includes(this.categories[y].id)){
                           // console.log("includes");
                           this.hall_show[x].categories.push({category_id:this.categories[y].id,category_name:this.categories[y].name,
                      category_select:true}); 
                      }else{
                            this.hall_show[x].categories.push({category_id:this.categories[y].id,category_name:this.categories[y].name,
                      category_select:false}); 
                      }
                  }
                 
               }*/
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getHall() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/manage-event-category-hall`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            var c_selected_hall_category = response.data.hall_category;
            // console.log(c_selected_hall_category.length);
            for (var q = 0; q < this.hall; q++) {
              console.log("push");
              this.selected_hall_category.push({ hall: [] });
              var c = q + 1;
              for (var w = 0; w < c_selected_hall_category[c].length; w++) {
                this.selected_hall_category[q].hall.push(
                  c_selected_hall_category[c][w].category_id
                );
              }
            }
            console.log(this.selected_hall_category);
            for (var x = 0; x < this.hall; x++) {
              this.hall_show.push({ categories: [] });
              for (var y = 0; y < this.categories.length; y++) {
                if (
                  this.selected_hall_category[x].hall.includes(
                    this.categories[y].id
                  )
                ) {
                  // console.log("includes");
                  this.hall_show[x].categories.push({
                    category_id: this.categories[y].id,
                    category_name: this.categories[y].name,
                    category_select: true,
                  });
                } else {
                  this.hall_show[x].categories.push({
                    category_id: this.categories[y].id,
                    category_name: this.categories[y].name,
                    category_select: false,
                  });
                }
              }
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async postEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);
      for (var z = 0; z < this.hall_show.length; z++) {
        for (var s = 0; s < this.hall_show[z].categories.length; s++) {
          if (this.hall_show[z].categories[s].category_select == true) {
            formdata.append(
              "hallcategories[" + z + "][category_name][" + s + "]",
              this.hall_show[z].categories[s].category_id
            );
          }
        }
      }

      await axios
        .post(
          `${this.$store.getters.baseUrl}/event-category-hall-update`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // this.$swal(
            //   "Success",
            //   "Event Hall Categories updated successfully",
            //   "success"
            // );
            this.$swal({
              position: 'middle',
              icon: 'success',
              title: 'Event Hall Categories updated successfully',
              timer: 1500
            })
            this.$router.push({ name: "manage-event" });
            // this.getEvent();
            // this.$router.push({name: 'morecreateevent',params:{id:this.$route.params.id,type:5}});
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
