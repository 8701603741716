<template>
<html>
  <div class="">
    <div class="col-xs-12 col-md-10 offset-md-1 pt-5">
      <div class="table-responsive text-center text-uppercase"  id="printableArea" >
        <dl class="text-uppercase text-center" :style="{'margin':batch_margin}" >
          <dt :style="{ 'font-size': event_batch_name_font_size }">{{title ? title.title : ''}} {{registration.full_name}}</dt>
          <dt :style="{ 'font-size': event_batch_design_font_size }"> {{registration.designation}}</dt>
          <dt :style="{ 'font-size': event_batch_company_font_size }"> {{registration.company}}</dt>
          <dt v-if = "qrCode != null" > <div><img v-bind:src="'data:image/png;base64,'+qrCode"  :style="{ 'width': event_batch_barcode_width, 'height' : event_batch_barcode_height }" /></div></dt>
          
          <dt v-if = "barcode_data == null" > <div><img v-bind:src="'data:image/png;base64,'+barcode_data"  > </div></dt>
          
        </dl> 
      </div>
      <!-- <div> <vue-qrcode v-bind:value="barcode_data" /></div> -->
      <div class="text-center text-uppercase">
        <button class="btn btn-outline-primary text-center"  @click="printDiv('printableArea')">PRINT </button>
      </div>
    </div>
  </div>
</html>
</template>

<script>
 
// @ is an alias to /src
import axios from "axios";
import VueQrcode from 'vue-qrcode';
export default {
  name: "HomeView",
  el: '#app',
  components: {
     VueQrcode,
},
  data(){
    return {
      event_batch:'',
      registration:'',
      barcode_data:'',
      qrCode:'',
      output: null,
      title:'',
      batch_margin: '',
      barcodeValue: 'test',
      event_batch_name_font_size: '',
    };
  },
   mounted() {
    this.getPrints();
  },
  methods: {   
 
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.href = "/#/registration-kiosk";
      this.$router.go();
      
    },
     async getPrints() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk-print-registration/${this.$route.params.id}`)
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.event_batch = response.data.event_batch;
            this.batch_margin = response.data.event_batch.batch_margin;
            this.event_batch_category_font_size = response.data.event_batch.category_font_size + 'px';
            this.event_batch_city_font_size = response.data.event_batch.city_font_size + 'px';
            this.event_batch_company_font_size = response.data.event_batch.company_font_size + 'px';
            this.event_batch_design_font_size = response.data.event_batch.design_font_size + 'px';
            this.event_batch_regno_font_size = response.data.event_batch.regno_font_size + 'px';
            this.event_batch_name_font_size = response.data.event_batch.name_font_size + 'px';
            this.event_batch_barcode_width = response.data.event_batch.barcode_width + 'px';
            this.event_batch_barcode_height = response.data.event_batch.barcode_height + 'px';
            this.registration = response.data.registration;
            this.title = response.data.registration.title;
            this.barcode_data = response.data.barcode_data;
            this.qrCode = response.data.qrCode;
          console.log(this.event_batch_name_font_size);
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>


