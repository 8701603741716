<template>
  <div class="">
    <div class="p-3 d-flex flex-wrap">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 mb-3"
        v-for="(event_c, index) in event_categories"
        :key="index"
      >
        <div class="event-category">
          <h5>
            <input type="checkbox" v-model="event_c.category_select" />Event:
            {{ event_c.category_name }}
          </h5>
          <div class="first-checkbox">
            <label class="d-flex align-items-center justify-content-between">
              KitBag <input type="checkbox" v-model="event_c.kitbag" />
            </label>
            <label class="d-flex align-items-center justify-content-between">
              Certificates
              <input type="checkbox" v-model="event_c.certificate" />
            </label>
            <label class="d-flex align-items-center justify-content-between">
              Magazine
              <input type="checkbox" v-model="event_c.magazine" />
            </label>
            <label class="d-flex align-items-center justify-content-between">
              Payment <input type="checkbox" v-model="event_c.payment" />
            </label>
            <div class="payment-det">
              <p>Registration pay</p>
              <input
                type="text"
                v-on:keyup.enter="getsaveCategories()"
                v-model="event_c.register_amount"
              />
              <p>Workshop Pay</p>
              <input
                type="text"
                v-on:keyup.enter="getsaveCategories()"
                v-model="event_c.work_shop_amount"
              />
              <p>Others Pay</p>
              <input
                type="text"
                v-on:keyup.enter="getsaveCategories()"
                v-model="event_c.other_amount"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-start me-2">
        <button
          class="submit-btn"
          variant="success"
          @click="getsaveCategories()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      event_categories: [],
      selectedcategory: [],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    selectCategory(index) {
      this.selectedcategory.push(index);
    },

    /* async getEvent() {
    
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      
      await axios
        .post(`${this.$store.getters.baseUrl}/manage-event`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
             this.event_categories = response.data.event_Details.categories;
             
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },*/

    async getCategories() {
      await axios
        .get(`${this.$store.getters.baseUrl}/event/category/list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // this.event_categories = response.data.categories;
            var categories = response.data.categories;
            console.log(categories.length);
            for (var i = 0; i < categories.length; i++) {
              console.log("fdf");
              this.event_categories.push({
                category_name: categories[i].name,
                category_id: categories[i].id,
                category_select: false,
                kitbag: false,
                certificate: false,
                magazine: false,
                payment: false,
                register_amount: "",
                work_shop_amount: "",
                other_amount: "",
              });
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getsaveCategories() {
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      var senddata = [];
      for (var x = 0; x < this.event_categories.length; x++) {
        if (this.event_categories[x].category_select == true) {
          senddata.push({
            category_id: this.event_categories[x].category_id,
            kitbag: this.event_categories[x].kitbag,
            certificate: this.event_categories[x].certificate,
            magazine: this.event_categories[x].magazine,
            payment: this.event_categories[x].payment,
            register_amount: this.event_categories[x].register_amount,
            work_shop_amount: this.event_categories[x].work_shop_amount,
            other_amount: this.event_categories[x].other_amount,
          });
        }
      }

      for (var y = 0; y < senddata.length; y++) {
        formdata.append(
          "event_categories[" + y + "][category]",
          senddata[y].category_id
        );
        formdata.append(
          "event_categories[" + y + "][kitbag]",
          senddata[y].kitbag == true ? 1 : 0
        );
        formdata.append(
          "event_categories[" + y + "][certificate]",
          senddata[y].certificate == true ? 1 : 0
        );
        formdata.append(
          "event_categories[" + y + "][magazine]",
          senddata[y].magazine == true ? 1 : 0
        );
        formdata.append(
          "event_categories[" + y + "][payment]",
          senddata[y].payment == true ? 1 : 0
        );
        formdata.append(
          "event_categories[" + y + "][register_amount]",
          senddata[y].register_amount
        );
        formdata.append(
          "event_categories[" + y + "][work_shop_amount]",
          senddata[y].work_shop_amount
        );
        formdata.append(
          "event_categories[" + y + "][other_amount]",
          senddata[y].other_amount
        );
      }

      await axios
        .post(`${this.$store.getters.baseUrl}/event/category`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal({
              position: "middle",
              icon: "success",
              title: "Event categories added successfully",
              timer: 1500,
            });
            // this.$swal(
            //   "Success",
            //   "Event categories added successfully",
            //   "success"
            // );
            this.$router.push({
              name: "morecreateevent",
              params: { id: this.$route.params.id, type: 3 },
            });
            //window.location.href = "/more-create-event/"+this.$route.params.id+"/"+3;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
-->
