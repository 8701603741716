<template>

  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">


  <div class="d-flex justify-content-between align-items-center">
       <div class="dah-color text-uppercase ps-3">
            <router-link to="/dashboard" title="Dashboard"><i class="fa fa-arrow-left pe-2"></i>Dashboard
            </router-link>
       </div>
    <div class="text-uppercase d-flex align-items-center">
      <div class="name-heade">
        <p @click="isChangePassToggled = !isChangePassToggled" class="px-4 my-auto user-bttn" style="margin-right:20px;"> {{this.$store.state.adminName}}</p>
        <p :class="{changepass: isChangePassToggled}" class="change-posi">
          <router-link to="/home" title="Home">  Change Password
            </router-link>
        </p>
      </div>
      <button type="button" class="btn font-size-sm font-w500 btn-secondary" @click="logout()">Log Out</button>
    </div>
    
    <!-- <div class="d-flex top-header justify-content-between align-items-center px-4" >
      <div class="text-right text-uppercase">
        <h4> {{this.$store.state.adminName}} </h4>
      </div>
      <div class="text-right justify-content-end me-2">
        <button d-flex class="d-flex set-btn" @click="logout()">
           Logout 
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
export default {
  computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
  data(){
     return {
      show: false,
      isChangePassToggled: true,
      permissions: [],
     };
  },
  methods:{
    
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          localStorage.clear();
          this.$swal("Success", "Logout successfully", "success");
          this.$router.push('/')
        })
      }
    },
    // async logout() {
    //   this.loader = true;
    //   await axios
    //     .post(`${this.$store.getters.baseUrl}/admin/logout`, {
    //       headers: {
    //         Authorization: `Bearer ${this.$store.getters.token}`,
    //       },
    //     })
    //     .then((response) => { 
    //       console.log(response);
    //       if (response.data.status == "success") {
    //         this.$store.dispatch('logout')
    //         .then(() => {
    //           localStorage.clear();
    //           this.$swal("Success", "Logout successfully", "success");
    //           this.$router.push('/')
    //         })
    //       } else {
    //         this.$swal("Error", response.data.message, "warning");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //        this.loader = false;
    //       this.$swal("Error", error.response.data.message, "error");
    //     });
    // },
  // },

  
};
</script>
