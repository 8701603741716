<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3" v-if="$store.getters.adminDetail.includes('CAN-ADD-GENERAL')">
      <div class="general-page px-4 py-3">
        <h5 class="mb-0">Default Settings</h5>
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Conference</label>
              <select v-if="default_setting" v-model="event" @change="getEventsDetail()"  
                   @focus="eventEmpty = false,getEvents() " required>
                <option         
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
              <select v-else v-model="event" @change="getEventsDetail()"  
                   @focus="eventEmpty = false " required>
                <option         
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Entry Type</label>
              <select v-if="default_setting" v-model="event_type" @focus="event_typeEmpty = false" required>
                <option v-for="(event, index) in events_details.total_hall_entry" :key="index" :value="'Hall-'+event"> Hall-{{ event }} </option>
                <option v-if="events_details.kitbag == 1" value="kitbag"> kitbag  </option>
                 <option v-if="events_details.magazine == 1" value="magazine"> magazine  </option>
                 <option v-if="events_details.certificates == 1" value="certificates"> certificates  </option> 
                 <option v-if="event_type == 'lunch'" :value="event_type"> Hall-1</option>
                 <option v-if="event_type == 'break_fast'" :value="event_type"> Hall-1</option>
                 <option v-if="event_type == 'dinner'" :value="event_type"> Hall-1</option>
                 
              </select>
              <select v-else v-model="event_type" @focus="event_typeEmpty = false" required>
                 <option v-for="(event, index) in events_details.total_hall_entry" :key="index" :value="'Hall-'+event"> Hall-{{ event }} </option>
                  <option v-if="events_details.kitbag == 1" value="kitbag"> kitbag  </option>
                 <option v-if="events_details.magazine == 1" value="magazine"> magazine  </option>
                 <option v-if="events_details.certificates == 1" value="certificates"> certificates  </option>              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Day</label>
              <select v-if="default_setting" v-model="day" @focus="dayEmpty = false" required>
                <option v-for="(event, index) in event_name.total_days" :key="index" 
                :value="event" > Day-{{ event }} </option>
              </select>
              <select v-else v-model="day" @focus="dayEmpty = false" required>
                <option v-for="(event, index) in events_details.total_days" :key="index"
                  :value="event">
                  Day-{{ event }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Session</label>
              <select v-if="default_setting" v-model="session" @focus="sessionEmpty = false" required>
                <option v-for="(event, index) in event_name.max_session_per_day" :key="index"
                  :value="event"
                >
                  Session-{{ event }}
                </option>
              </select>
              <select v-else v-model="session" @focus="sessionEmpty = false" required>
                <option v-for="(event, index) in events_details.max_session_per_day" :key="index"      
                  :value="event"
                >
                  Session-{{ event }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end me-2">
          <button class="set-btn" variant="success" @click="setDefault()">Set Default</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      event: "",
      eventEmpty:false,
      event_type: "",
      event_typeEmpty:false,
      day: "",
      dayEmpty:false,
      session: "",
      sessionEmpty:false,
      events: [],
      event_categories: [],
      default_setting: '',
      event_name: '',
      event_default_id: '',
      event_service: '',
      events_details: '',
      default_event_type: '',
      eventId: localStorage.getItem('eventId'),
    };
  },

   mounted() {
    this.getEvents();
  },
  methods: {   
     async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/general-default-list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.events = response.data.events;
            this.default_setting = response.data.default_settings;
            this.event_type = response.data.default_settings.entry_type;
            if(this.default_setting){
              this.event_default_id = response.data.default_settings.event_id;
              this.getEventDetails();
              this.getEventsDetail();
              this.event = this.default_setting.event_id;
              this.event_type = this.default_setting.entry_type;
              this.day = this.default_setting.days;
              this.session = this.default_setting.session;
            }
            this.event_service = response.data.event_service;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    
     async getEventsDetail() {
      console.log("trfvtvtv");
      if(this.event){
        this.event_default_id = this.event;
      }
      if(this.event_default_id){
        this.event_default_id = this.event_default_id;
      }
      await axios
        .get(`${this.$store.getters.baseUrl}/general-default-data/${this.event_default_id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.events_details = response.data.events;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },


     
     async getEventDetails() {
      const formdata = new FormData();
      console.log(this.event_default_id);
      formdata.append("event_id", this.event_default_id);
      await axios
        .post(`${this.$store.getters.baseUrl}/get-spot-registration-event`,formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.event_name = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async setDefault() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      if (this.event_type == "") {
        this.event_typeEmpty = true;
        return;
      }
      if (this.day == "") {
        this.dayEmpty = true;
        return;
      }
      if (this.session == "") {
        this.sessionEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      formdata.append("entry_type", this.event_type);
      formdata.append("day", this.day);
      formdata.append("session", this.session);
      await axios
        .post(`${this.$store.getters.baseUrl}/general-default-add`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "General set successfully", "success");
            this.$router.push({name: 'general'});
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  }
};
</script>
