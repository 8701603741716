<template>
  <div class="">
    <div class="ps-3 mt-3">
      <div class="general-page">
        <div class="px-4 py-3">
          <div class="d-flex flex-wrap">
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Event Name</label>
                <input
                  type="text"
                  v-model="name"
                  @focus="nameEmpty = false"
                  v-on:keyup.enter="save()"
                  placeholder=""
                />
                <div v-if="nameEmpty" style="color: red">
                  Event Name is required
                </div>
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Event ID</label>
                <input
                  type="text"
                  v-model="code"
                  @focus="codeEmpty = false"
                  v-on:keyup.enter="save()"
                  placeholder=""
                />
                <div v-if="codeEmpty" style="color: red">
                  Event ID is required
                </div>
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Registration Start With</label>
                <input
                  type="text"
                  v-model="reg_start_with"
                  @focus="reg_start_withEmpty = false"
                  v-on:keyup.enter="save()"
                  placeholder=""
                />
                <div v-if="reg_start_withEmpty" style="color: red">
                  Registration Start With is required
                </div>
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Year</label>
                <input
                  type="date"
                  v-model="year"
                  @focus="yearEmpty = false"
                  placeholder=""
                  v-on:keyup.enter="save()"
                />
                <div v-if="yearEmpty" style="color: red">Year is required</div>
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label for="">Event Type</label>
                <select
                  class="py-0"
                  name=""
                  id=""
                  v-model="event_type"
                  @focus="event_typeEmpty = false"
                >
                  <option value="Exhibition">Exhibition</option>
                  <option value="Conference">Conference</option>
                </select>
                <div v-if="event_typeEmpty" style="color: red">
                  Event Type is required
                </div>
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label for="">Conference / Exhibition Type</label>
                <select
                  class="py-0"
                  name=""
                  id=""
                  v-model="con_exh_type"
                  @focus="con_exh_typeEmpty = false"
                >
                  <option value="National">National</option>
                  <option value="International">International</option>
                </select>
              </div>
              <div v-if="con_exh_typeEmpty" style="color: red">
                Conference / Exhibition Type is required
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Total Hall</label>
                <input
                  type="number"
                  v-on:keyup.enter="save()"
                  v-model="hall_count"
                  @focus="hall_countEmpty = false"
                  placeholder=""
                />
              </div>
              <div v-if="hall_countEmpty" style="color: red">
                Total Hall is required
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Max Session/ Day</label>
                <input
                  type="number"
                  v-on:keyup.enter="save()"
                  v-model="max_session_per_day"
                  @focus="max_session_per_dayEmpty = false"
                  placeholder=""
                />
              </div>
              <div v-if="max_session_per_dayEmpty" style="color: red">
                Max Session/ Day is required
              </div>
            </div>
            <div class="mt-3 col-12 col-lg-6">
              <div class="input-box">
                <label class="mb-0" for="">Event Days</label>
                <input
                  type="number"
                  v-on:keyup.enter="save()"
                  name="event_days"
                  id="event_days"
                  v-model="event_days"
                  @input="eventDaysSelect"
                  @focus="event_daysEmpty = false"
                  placeholder=""
                />
              </div>
              <div v-if="event_daysEmpty" style="color: red">
                Event Days is required
              </div>
            </div>
          </div>

          <div class="mt-0 col-12 d-flex flex-wrap px-3" v-if="event_days">
            <div
              class="mt-3 me-5"
              v-for="(event_code, index) in event_codes"
              :key="index"
            >
              <div class="">
                <div class="all-select d-flex align-items-center">
                  <label>Day-{{ index + 1 }}</label>
                </div>
                <div class="single-select mt-2">
                  <label>
                    <input
                      type="checkbox"
                      v-model="event_code.bfast"
                      @click="setValue(index, 1)"
                    />Break Fast</label
                  >
                </div>
                <input
                  v-if="event_code.bfast == true"
                  required
                  class="food-input"
                  type="text"
                  v-on:keyup.enter="save()"
                  v-model="event_code.bfast_value"
                />

                <div class="single-select mt-1">
                  <label
                    ><input
                      type="checkbox"
                      v-model="event_code.lunch"
                      @click="setValue(index, 2)"
                    />Lunch</label
                  >
                </div>
                <input
                  v-if="event_code.lunch == true"
                  required
                  class="food-input"
                  type="text"
                  v-on:keyup.enter="save()"
                  v-model="event_code.lunch_value"
                />

                <div class="single-select mt-1">
                  <label
                    ><input
                      type="checkbox"
                      v-model="event_code.dinner"
                      @click="setValue(index, 3)"
                    />Dinner</label
                  >
                </div>
                <input
                  v-if="event_code.dinner == true"
                  required
                  class="food-input"
                  type="text"
                  v-on:keyup.enter="save()"
                  v-model="event_code.dinner_value"
                />

                <div class="single-select mt-1">
                  <label
                    ><input
                      type="checkbox"
                      v-model="event_code.other1"
                      @click="setValue(index, 4)"
                    />other 1</label
                  >
                </div>
                <input
                  v-if="event_code.other1 == true"
                  required
                  class="food-input"
                  type="text"
                  v-on:keyup.enter="save()"
                  v-model="event_code.other1_value"
                />

                <div class="single-select mt-1">
                  <label
                    ><input
                      type="checkbox"
                      v-model="event_code.other2"
                      @click="setValue(index, 5)"
                    />other 2</label
                  >
                </div>
                <input
                  v-if="event_code.other2 == true"
                  required
                  class="food-input"
                  type="text"
                  v-on:keyup.enter="save()"
                  v-model="event_code.other2_value"
                />
              </div>
            </div>
          </div>

          <div class="">
            <div class="per-missions mt-4">
              <div class="all-select d-flex align-items-center">
                <label>
                  <!-- v-model="event_days" @focus="event_daysEmpty = false" -->
                  Choose Spot Register Information
                </label>
              </div>
              <div class="d-flex flex-wrap">
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[0]"
                    />Title</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[1]"
                    />Full Name</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[2]"
                    />Company</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[3]"
                    />Designation</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[4]"
                    />Category</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[5]"
                    />Photo</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[6]"
                    />City</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[7]"
                    />Mobile</label
                  >
                </div>
                <div class="single-select col-12">
                  <label
                    ><input
                      class=""
                      type="checkbox"
                      v-model="spot_register_info[8]"
                    />Email</label
                  >
                </div>
              </div>
            </div>
            <div class="per-missions">
              <div class="all-select d-flex align-items-center">
                <label
                  >Choose KitBag/ Certificates/ Exhibitor Directory/
                  Magazine</label
                >
              </div>
              <div class="single-select mt-1">
                <label
                  ><input
                    class=""
                    type="checkbox"
                    v-model="kitbag"
                    @focus="kitbagEmpty = false"
                  />KitBag</label
                >
              </div>
              <div class="single-select mt-1">
                <label
                  ><input
                    class=""
                    type="checkbox"
                    v-model="certificate"
                    @focus="certificateEmpty = false"
                  />Certificates</label
                >
              </div>
              <div class="single-select mt-1">
                <label
                  ><input
                    class=""
                    type="checkbox"
                    v-model="magazine"
                    v-on:keyup.enter="save()"
                    @focus="magazineEmpty = false"
                  />Exhibitor Directory / Magazine</label
                >
              </div>
            </div>
            <div class="per-missions">
              <div class="all-select d-flex align-items-center">
                <label>Payment</label>
              </div>
              <div class="d-flex flex-wrap">
                <div class="single-select col-12">
                  <label
                    ><input
                      type="checkbox"
                      v-model="payment"
                      @focus="paymentEmpty = false"
                    />Yes</label
                  >
                </div>
                <div class="d-flex justify-content-start">
                  <button class="submit-btn" variant="success" @click="save()">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      name: "",
      nameEmpty: false,
      code: "",
      codeEmpty: false,
      year: "",
      yearEmpty: false,
      event_type: "",
      event_typeEmpty: false,
      con_exh_type: "",
      con_exh_typeEmpty: false,
      hall_count: "",
      hall_countEmpty: false,
      max_session_per_day: "",
      max_session_per_dayEmpty: false,
      event_days: "",
      event_daysEmpty: false,
      reg_start_with: "",
      reg_start_withEmpty: false,
      kitbag: "",
      kitbagEmpty: false,
      certificate: "",
      certificateEmpty: false,
      magazine: "",
      magazineEmpty: false,
      payment: "",
      paymentEmpty: false,
      event_days: [],
      break_fast: "",
      break_fastEmpty: false,
      lunch: "",
      lunchEmpty: false,
      dinner: "",
      dinnerEmpty: false,
      event_codes: [],
      event_codesEmpty: false,
      bfast: "",
      bfastEmpty: false,
      roleSelected: "",
      spot_register_info: [
        "title",
        "fullname",
        "company",
        "designation",
        "category",
        "photo",
        "city",
        "mobile",
        "email",
      ],

      current_event_id: "",
    };
  },
  mounted() {},
  methods: {
    checkbox: function (event_code) {
      if (event_code == true) {
        this.event_code.bfast = 1;
        return;
      }
    },

    eventDaysSelect() {
      this.event_codes = [];
      for (var i = 0; i < this.event_days; i++) {
        this.event_codes.push({
          bfast: false,
          bfast_value: "",
          lunch: false,
          lunch_value: "",
          dinner: false,
          dinner_value: "",
          other1: false,
          other1_value: "",
          other2: false,
          other2_value: "",
        });
      }
    },
    setValue(index, type) {
      if (type == 1) {
        if (this.event_codes[index].bfast == true) {
          this.event_codes[index].bfast = false;
        } else {
          this.event_codes[index].bfast = true;
        }
      } else if (type == 2) {
        if (this.event_codes[index].lunch == true) {
          this.event_codes[index].lunch = false;
        } else {
          this.event_codes[index].lunch = true;
        }
      } else if (type == 3) {
        if (this.event_codes[index].dinner == true) {
          this.event_codes[index].dinner = false;
        } else {
          this.event_codes[index].dinner = true;
        }
      } else if (type == 4) {
        if (this.event_codes[index].other1 == true) {
          this.event_codes[index].other1 = false;
        } else {
          this.event_codes[index].other1 = true;
        }
      } else if (type == 5) {
        if (this.event_codes[index].other2 == true) {
          this.event_codes[index].other2 = false;
        } else {
          this.event_codes[index].other2 = true;
        }
      }
    },
    async save() {
      if (this.name == "") {
        this.nameEmpty = true;
        return;
      }
      if (this.code == "") {
        this.codeEmpty = true;
        return;
      }
      if (this.year == "") {
        this.yearEmpty = true;
        return;
      }
      if (this.event_type == "") {
        this.event_typeEmpty = true;
        return;
      }
      if (this.con_exh_type == "") {
        this.con_exh_typeEmpty = true;
        return;
      }
      if (this.hall_count == "") {
        this.hall_countEmpty = true;
        return;
      }
      if (this.max_session_per_day == "") {
        this.max_session_per_dayEmpty = true;
        return;
      }
      if (this.event_days == "") {
        this.event_daysEmpty = true;
        return;
      }
      if (this.reg_start_with == "") {
        this.reg_start_withEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("name", this.name);
      formdata.append("code", this.code);
      formdata.append("year", this.year);
      formdata.append("event_type", this.event_type);
      formdata.append("con_exh_type", this.con_exh_type);
      formdata.append("hall_count", this.hall_count);
      formdata.append("max_session_per_day", this.max_session_per_day);
      formdata.append("event_days", this.event_days);
      formdata.append("reg_start_with", this.reg_start_with);
      if (this.kitbag == true) {
        formdata.append("kitbag", "1");
      }
      if (this.certificate == true) {
        formdata.append("certificate", "1");
      }
      if (this.magazine == true) {
        formdata.append("magazine", "1");
      } else {
        formdata.append("magazine", "0");
      }
      if (this.payment == true) {
        formdata.append("payment", "1");
      }
      if (this.spot_register_info[0] == true) {
        formdata.append("spot_register_info[]", "title");
      }
      if (this.spot_register_info[1] == true) {
        formdata.append("spot_register_info[]", "fullname");
      }
      if (this.spot_register_info[2] == true) {
        formdata.append("spot_register_info[]", "company");
      }
      if (this.spot_register_info[3] == true) {
        formdata.append("spot_register_info[]", "designation");
      }
      if (this.spot_register_info[4] == true) {
        formdata.append("spot_register_info[]", "category");
      }
      if (this.spot_register_info[5] == true) {
        formdata.append("spot_register_info[]", "photo");
      }
      if (this.spot_register_info[6] == true) {
        formdata.append("spot_register_info[]", "city");
      }
      if (this.spot_register_info[7] == true) {
        formdata.append("spot_register_info[]", "mobile");
      }
      if (this.spot_register_info[8] == true) {
        formdata.append("spot_register_info[]", "email");
      }
      console.log(this.spot_register_info);
      for (var x = 0; x < this.event_codes.length; x++) {
        if (this.event_codes[x].bfast == true) {
          formdata.append(
            "eventcode[" + x + "][break_fast]",
            this.event_codes[x].bfast_value
          );
        }
        if (this.event_codes[x].lunch == true) {
          formdata.append(
            "eventcode[" + x + "][lunch]",
            this.event_codes[x].lunch_value
          );
        }
        if (this.event_codes[x].dinner == true) {
          formdata.append(
            "eventcode[" + x + "][dinner]",
            this.event_codes[x].dinner_value
          );
        }
        if (this.event_codes[x].other1 == true) {
          formdata.append(
            "eventcode[" + x + "][other_service_1]",
            this.event_codes[x].other1_value
          );
        }
        if (this.event_codes[x].other2 == true) {
          formdata.append(
            "eventcode[" + x + "][other_service_2]",
            this.event_codes[x].other2_value
          );
        }
      }

      await axios
        .post(`${this.$store.getters.baseUrl}/events`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.current_event_id = response.data.event_id;
            this.$swal({
              position: "middle",
              icon: "success",
              title: "Event basic details added successfully",
              timer: 1500,
            });
            // this.$swal(
            //   "Success",
            //   "Event basic details added successfully",
            //   "success"
            // );
            this.$router.push({
              name: "morecreateevent",
              params: { id: this.current_event_id, type: 2 },
            });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
