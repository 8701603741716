<template>
  <div class="">
    <div class="ps-3">
      <div
        class="top-header d-flex justify-content-between align-items-center px-2"
      >
        <div class="d-flex align-items-center">
          <router-link to="/">
            <i class="fa-solid fa-arrow-left"></i>
          </router-link>
          <h4 class="mb-0 title-h4">Category List</h4>
        </div>
        <div class="d-flex">
          <p class="mb-0"></p>
          <button class="border-0 bg-white">
            <img src="../assets/img/power.png" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div class="ps-3 mt-3">
      <div class="general-page tabel-data-page px-4 py-3">
        <div class="d-flex justify-content-end align-items-center">
          <!-- <button @click="addCategory = !addCategory" class="add-role"> -->
          <button v-on:click="addRole" class="add-role">Add Categories</button>
        </div>
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mt-2"
        >
          <div class="sort d-flex">
            <p class="mb-0">Show</p>
            <select name="" id="" class="mx-2">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
            <p class="mb-0">Entries</p>
          </div>
          <div class="search-input">
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <table class="table-page mt-2">
          <tr class="w-100">
            <th>#</th>
            <th>Category Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Category-1</td>
            <td class="de-activate">Deactivate</td>
            <td>
              <button class="ms-2">
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Category-1</td>
            <td class="activ-ate">Activate</td>
            <td>
              <button class="ms-2">
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </table>
        <div class="pagi-nation">
          <ul class="d-flex mt-3 mb-0 p-0">
            <li>
              <a class="pagi-a left-rit-arrow" href="#"
                ><i class="fa-solid fa-arrow-left"></i
              ></a>
            </li>
            <li class="active"><a class="pagi-a" href="#">1</a></li>
            <li><a class="pagi-a" href="#">2</a></li>
            <li><a class="pagi-a" href="#">3</a></li>
            <li><a class="pagi-a" href="#">4</a></li>
            <li><a class="pagi-a" href="#">5</a></li>
            <li>
              <a class="pagi-a left-rit-arrow" href="#"
                ><i class="fa-solid fa-arrow-right"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-add-categories" v-bind:class="{ modalcat: isActive }">
      <div class="in-modal-add-categories">
        <div class="form-action">
          <input type="text" placeholder="Add Categories" />
          <button class="save-btn">Submit</button>
        </div>
      </div>
      <div @click="addRole" v-bind:class="{ dummy_add_cate: isActive }"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      isActive: false,
      addCategory: false,
    };
  },
  methods: {
    addRole: function () {
      this.isActive = !this.isActive;
    },
    dummy_add_cate: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>
