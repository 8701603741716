<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3" v-if="this.eventId">
      <div class="general-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2">
            <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
            <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
          </div>
          <router-link class="add-role" to="/printer" title="Role">
            Back</router-link
          >
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Edit Registration</h5>
        </div>

        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Conference Name</label>
              <select
                v-model="event_name"
                @focus="event_nameEmpty = false"
                read_only
              >
                <option :value="event_names.id">
                  {{ event_names.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Registration No</label>
              <input
                type="text"
                v-on:keyup.enter="updateRegistration()"
                v-model="reg_no"
                @focus="reg_noEmpty = false"
                required
              />
            </div>
            <div v-if="reg_noEmpty" style="color: red">
              Registration Number is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Title</label>
              <select
                v-model="title"
                @change="getEvents()"
                @focus="titleEmpty = false"
                read_only
                placeholder="Select Event Name"
              >
                <option
                  v-for="(title, index) in titles"
                  :key="index"
                  :value="title.id"
                  readonly
                >
                  {{ title.title }}
                </option>
              </select>
            </div>
            <div v-if="event_titleEmpty" style="color: red">
              Title is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Category Name</label>
              <select
                v-model="category"
                @change="getEvents()"
                @focus="categoryEmpty = false"
                required
                placeholder="Select Category"
              >
                <option
                  v-for="(category, index) in categories"
                  :key="index"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div v-if="categoryEmpty" style="color: red">
              Category is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Full Name</label>
              <input
                type="text"
                v-on:keyup.enter="updateRegistration()"
                v-model="spot_name"
                @focus="spotnameEmpty = false"
              />
            </div>
            <div v-if="spotnameEmpty" style="color: red">
              Full Name is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">City</label>
              <input
                type="text"
                v-on:keyup.enter="updateRegistration()"
                v-model="city"
                @focus="cityEmpty = false"
              />
            </div>
            <!-- <div v-if="cityEmpty" style="color: red">
              City is required
            </div> -->
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Company Name</label>
              <input
                type="text"
                v-on:keyup.enter="updateRegistration()"
                v-model="company"
                @focus="companyEmpty = false"
              />
            </div>
            <!-- <div v-if="companyEmpty" style="color: red">
              Company Name is required
            </div> -->
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Mobile</label>
              <input
                type="mobile"
                v-on:keyup.enter="updateRegistration()"
                v-model="mobile"
                @focus="mobileEmpty = false"
                maxlength="10"
              />
            </div>
            <div v-if="mobileEmpty" style="color: red">Mobile is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Designation</label>
              <input
                type="text"
                v-on:keyup.enter="updateRegistration()"
                v-model="designation"
                @focus="designationEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Email</label>
              <input
                type="email"
                v-on:keyup.enter="updateRegistration()"
                v-model="email"
                @focus="emailEmpty = false"
              />
            </div>
            <!-- <div v-if="emailEmpty" style="color: red">
              Email is required
            </div> -->
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Snap Shot</label>
              <input
                type="file"
                name="file"
                v-on:change="uploadFiles"
                @focus="fileEmpty = false"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <button class="submit-btn" v-on:click="updateRegistration()">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      spot_name: "",
      spotnameEmpty: false,
      company: "",
      companyEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      designation: "",
      designationEmpty: false,
      category: "",
      categoryEmpty: false,
      city: "",
      cityEmpty: false,
      mobile: "",
      mobileEmpty: false,
      email: "",
      emailEmpty: false,
      file: "",
      fileEmpty: false,
      event_detail: "",
      event_detailEmpty: false,
      title: "",
      titleEmpty: false,
      events: [],
      event: "",
      eventEmpty: false,
      event_details: [],
      event_name: "",
      event_nameEmpty: false,
      registration_no: "",
      categories: [],
      titles: [],
      event_names: [],
      registrations: "",
      eventId: localStorage.getItem("eventId"),
    };
  },

  mounted() {
    if (localStorage.eventId) {
      this.event = localStorage.eventId;
      this.getRegistrationEdit();
    }
  },
  methods: {
    async getRegistrationEdit() {
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      formdata.append("registration_id", this.$route.params.id);
      await axios
        .post(`${this.$store.getters.baseUrl}/registration-edit`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log("registration_id", this.$route.params.id);
          console.log("event_id", this.event);
          console.log(response);
          if (response.data.status == "success") {
            this.event_names = response.data.event;
            this.categories = response.data.event.categories;
            this.titles = response.data.titles;
            this.registrations = response.data.registration;
            this.barcode_data = response.data.barcode_data;
            this.reg_no = this.registrations.reg_no;
            this.spot_name = this.registrations.full_name;
            this.city = this.registrations.city;
            this.company = this.registrations.company;
            this.mobile = this.registrations.mobile;
            this.designation = this.registrations.designation;
            this.email = this.registrations.email;
            this.event_name = this.registrations.event_id;
            this.title = this.registrations.title_id;
            this.category = this.registrations.category_id;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async updateRegistration() {
      if (this.event_name == "") {
        this.event_nameEmpty = true;
        return;
      }
      if (this.reg_no == "") {
        this.reg_noEmpty = true;
        return;
      }
      if (this.title == "") {
        this.titleEmpty = true;
        return;
      }
      if (this.spot_name == "") {
        this.spotnameEmpty = true;
        return;
      }
      if (this.category == "") {
        this.categoryEmpty = true;
        return;
      }
      if (this.mobile == "") {
        this.mobileEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("title_id", this.title);
      formdata.append("category_id", this.category);
      formdata.append("name", this.spot_name);
      formdata.append("mobile", this.mobile);
      formdata.append("event_id", this.event);
      formdata.append("reg_no", this.reg_no);
      if (this.designation) {
        formdata.append("designation", this.designation);
      }
      if (this.city) {
        formdata.append("city", this.city);
      }
      if (this.company) {
        formdata.append("company_name", this.company);
      }
      if (this.email) {
        formdata.append("email", this.email);
      }
      if (this.file) {
        formdata.append("photo", this.file);
      }
      await axios
        .post(
          `${this.$store.getters.baseUrl}/registration-update/${this.$route.params.id}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            localStorage.categoryId = this.category;
            this.$swal(
              "Success",
              "Registration updated successfully",
              "success"
            );
            this.$router.push({ name: "printbadge" });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
