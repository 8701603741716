<template>
  <div class="">
    <TopHeader />
    <div
      class="ps-3 mt-3"
      v-if="
        $store.getters.adminDetail.includes(
          'CAN-SPOT REGISTRATION-REGISTRATION'
        )
      "
    >
      <div class="general-page px-3 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2">
            <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
            <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
          </div>
          <router-link class="add-role" to="/registration" title="Role">
            Back</router-link
          >
        </div>
        <div class="row">
          <div class="event-select col-md-6">
            <p class="choose-evnet">Choose Event Name</p>
            <select
              v-model="event"
              @change="getEvents()"
              @focus="eventEmpty = false"
            >
              <option
                v-for="(event, index) in events"
                :key="index"
                :value="event.id"
              >
                {{ event.name }}
              </option>
            </select>
            <div v-if="eventEmpty" style="color: red">Event ID is required</div>
          </div>
          <div class="col-md-6 mt-5">
            <button type="submit" class="btn btn-primary" v-on:click="set()">
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="ps-3 mt-3" v-if="this.eventId">
      <div class="general-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Add Registration</h5>
          <router-link
            class="add-role"
            to="/titles"
            title="Event Report"
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-MANAGE TITLE-REGISTRATION'
              )
            "
          >
            Manage Title
          </router-link>
        </div>

        <div class="d-flex flex-wrap">
          <!-- <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Conference Name</label>
              <select v-model="event_name" @change="getEventDetails()"  @focus="event_nameEmpty = false" read_only>
                <option
                  :value="event_names.id"
                >
                  {{ event_names.name }}
                </option>
              </select>
            </div>
          </div> -->
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Title</label>
              <select
                v-model="event_title"
                @change="getEvents()"
                @focus="event_titleEmpty = false"
                read_only
                placeholder="Select Event Name"
              >
                <option
                  v-for="(event_title, index) in event_titles"
                  :key="index"
                  :value="event_title.id"
                  readonly
                >
                  {{ event_title.title }}
                </option>
              </select>
            </div>
            <div v-if="event_titleEmpty" style="color: red">
              Title is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Full Name</label>
              <input
                type="text"
                v-model="spot_name"
                v-on:keyup.enter="addRegistration()"
                @focus="spotnameEmpty = false"
              />
            </div>
            <div v-if="spotnameEmpty" style="color: red">
              Full Name is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Company Name</label>
              <input
                type="text"
                v-model="company"
                v-on:keyup.enter="addRegistration()"
                @focus="companyEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Registration No</label>
              <input
                type="text"
                readonly
                v-model="reg_no"
                v-on:keyup.enter="addRegistration()"
                @focus="reg_noEmpty = false"
                required
              />
            </div>
            <div v-if="reg_noEmpty" style="color: red">
              Registration Number is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Category Name</label>
              <select
                v-model="category"
                @change="getEvents()"
                @focus="categoryEmpty = false"
                required
                placeholder="Select Category"
              >
                <option
                  v-for="(event_category, index) in event_categories"
                  :key="index"
                  :value="event_category.id"
                >
                  {{ event_category.name }}
                </option>
              </select>
            </div>
            <div v-if="categoryEmpty" style="color: red">
              Category is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Designation</label>
              <input
                type="text"
                v-model="designation"
                @focus="designationEmpty = false"
                v-on:keyup.enter="addRegistration()"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">City</label>
              <input
                type="text"
                v-model="city"
                v-on:keyup.enter="addRegistration()"
                @focus="cityEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Mobile</label>
              <input
                type="mobile"
                v-model="mobile"
                @focus="mobileEmpty = false"
                v-on:keyup.enter="addRegistration()"
                maxlength="10"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Email</label>
              <input
                type="email"
                v-model="email"
                v-on:keyup.enter="addRegistration()"
                @focus="emailEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Snap Shot</label>
              <input
                type="file"
                name="file"
                v-on:change="uploadFiles"
                @focus="fileEmpty = false"
                required
              />
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <button class="submit-btn" v-on:click="addRegistration()">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none">
      <div id="printableArea">
        <div
          v-if="event_batch.template == 'Bottom'"
          class="text-uppercase"
          :style="{
            display: 'flex',
            marginLeft: '18px',
            alignItems: 'end',
          }"
        >
          <div
            :style="{
              fontFamily: font_face,
              fontSize: name_font_size,
              margin: batch_margin,
            }"
          >
            <dl
              class="text-uppercase"
              :style="{
                padding: batch_margin,
                textAlign: event_batch.template,
              }"
            >
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_name_font_size,
                }"
              >
                {{
                  registration_print.title ? registration_print.title.title : ""
                }}
                {{ registration_print.full_name }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_design_font_size,
                  'margin-top': '10px',
                }"
              >
                {{ registration_print.designation }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_company_font_size,
                  'margin-bottom': '10px',
                  'margin-top': '10px',
                }"
              >
                {{ registration_print.company }}
              </dt>
              <dt v-if="batch_info.includes('qrcode')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.qrcode_data
                    "
                    :style="{
                      width: event_batch_barcode_width,
                      height: event_batch_barcode_height,
                    }"
                  />
                </div>
              </dt>
              <dt v-if="batch_info.includes('bar_code')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.barcode_data
                    "
                  />
                </div>
              </dt>
            </dl>
          </div>
        </div>
        <div
          v-if="event_batch.template == 'Left'"
          class="text-uppercase"
          :style="{
            display: 'flex',
            marginLeft: '18px',
            justifyContent: 'left',
          }"
        >
          <div
            :style="{
              fontFamily: font_face,
              fontSize: name_font_size,
              margin: batch_margin,
            }"
          >
            <dl
              class="text-uppercase"
              :style="{
                padding: batch_margin,
                textAlign: event_batch.template,
              }"
            >
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_name_font_size,
                }"
              >
                {{
                  registration_print.title ? registration_print.title.title : ""
                }}
                {{ registration_print.full_name }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_design_font_size,
                  'margin-top': '10px',
                }"
              >
                {{ registration_print.designation }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_company_font_size,
                  'margin-bottom': '10px',
                  'margin-top': '10px',
                }"
              >
                {{ registration_print.company }}
              </dt>
              <dt v-if="batch_info.includes('qrcode')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.qrcode_data
                    "
                    :style="{
                      width: event_batch_barcode_width,
                      height: event_batch_barcode_height,
                    }"
                  />
                </div>
              </dt>
              <dt v-if="batch_info.includes('bar_code')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.barcode_data
                    "
                  />
                </div>
              </dt>
            </dl>
          </div>
        </div>
        <div
          v-if="event_batch.template == 'Center'"
          class="text-uppercase"
          :style="{
            display: 'flex',
            marginLeft: '18px',
            justifyContent: 'center',
          }"
        >
          <div
            :style="{
              fontFamily: font_face,
              margin: batch_margin,
            }"
          >
            <dl
              class="text-uppercase"
              :style="{
                padding: batch_margin,
                textAlign: event_batch.template,
              }"
            >
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_name_font_size,
                  width: '100mm',
                  padding: '0 30px',
                }"
              >
                {{
                  registration_print.title ? registration_print.title.title : ""
                }}
                {{ registration_print.full_name }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_design_font_size,
                  'margin-top': '10px',
                  width: '100mm',
                  padding: '0 30px',
                }"
              >
                {{ registration_print.designation }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_company_font_size,
                  'margin-bottom': '10px',
                  'margin-top': '10px',
                  width: '100mm',
                  padding: '0 30px',
                }"
              >
                {{ registration_print.company }}
              </dt>
              <dt v-if="batch_info.includes('qrcode')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.qrcode_data
                    "
                    :style="{
                      width: event_batch_barcode_width,
                      height: event_batch_barcode_height,
                    }"
                  />
                </div>
              </dt>
              <dt v-if="batch_info.includes('bar_code')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.barcode_data
                    "
                  />
                </div>
              </dt>
            </dl>
          </div>
        </div>
        <div
          v-if="event_batch.template == 'Right'"
          class="text-uppercase"
          :style="{
            display: 'flex',
            marginLeft: '18px',
            justifyContent: 'right',
          }"
        >
          <div
            :style="{
              fontFamily: font_face,
              fontSize: name_font_size,
              margin: batch_margin,
            }"
          >
            <dl
              class="text-uppercase"
              :style="{
                padding: batch_margin,
                textAlign: event_batch.template,
              }"
            >
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_name_font_size,
                }"
              >
                {{
                  registration_print.title ? registration_print.title.title : ""
                }}
                {{ registration_print.full_name }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_design_font_size,
                  'margin-top': '10px',
                }"
              >
                {{ registration_print.designation }}
              </dt>
              <dt
                :style="{
                  color: '#000',
                  'font-size': event_batch_company_font_size,
                  'margin-bottom': '10px',
                  'margin-top': '10px',
                }"
              >
                {{ registration_print.company }}
              </dt>
              <dt v-if="batch_info.includes('qrcode')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.qrcode_data
                    "
                    :style="{
                      width: event_batch_barcode_width,
                      height: event_batch_barcode_height,
                    }"
                  />
                </div>
              </dt>
              <dt v-if="batch_info.includes('bar_code')">
                <div>
                  <img
                    v-bind:src="
                      'data:image/png;base64,' + registration_print.barcode_data
                    "
                  />
                </div>
              </dt>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      print: false,
      spot_name: "",
      spotnameEmpty: false,
      company: "",
      companyEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      designation: "",
      designationEmpty: false,
      category: "",
      categoryEmpty: false,
      city: "",
      cityEmpty: false,
      mobile: "",
      mobileEmpty: false,
      email: "",
      emailEmpty: false,
      file: "",
      fileEmpty: false,
      event_detail: "",
      event_detailEmpty: false,
      event_title: "",
      event_titleEmpty: false,
      events: [],
      event: "",
      eventEmpty: false,
      event_details: [],
      event_name: "",
      event_nameEmpty: false,
      registration_no: "",
      event_categories: [],
      event_titles: [],
      event_names: [],
      eventId: localStorage.getItem("eventId"),
      categoryId: localStorage.getItem("categoryId"),

      event_batch: "",
      font_face: "",
      registration_print: "",
      barcode_data: "",
      qrCode: "",
      title: "",
      batch_margin: "",
      batch_info: "",
      event_batch_category_font_size: "",
      event_batch_city_font_size: "",
      event_batch_company_font_size: "",
      event_batch_design_font_size: "",
      event_batch_regno_font_size: "",
      event_batch_name_font_size: "",
      event_batch_barcode_width: "",
      event_batch_barcode_height: "",
    };
  },

  mounted() {
    this.getEvents();
    if (localStorage.eventId) {
      this.event = localStorage.eventId;
      this.getEventDetails();
    }
    if (localStorage.categoryId) {
      this.category = localStorage.categoryId;
    }
  },
  methods: {
    printDiv(divName) {
      console.log("name", divName);
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      // window.close();
      location.reload();
    },

    set() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      localStorage.eventId = this.event;
      console.log(this.event);
      console.log(this.eventId);
      this.$router.push({ name: "spotregistration" });
      this.$router.go();
      this.getEventDetails();
    },

    uploadFiles(event) {
      this.file = event.target.files[0];
    },
    async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/registration-events`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventDetails() {
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/get-spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_names = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
            this.reg_no = response.data.registration_no;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async registration() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event", this.event);
      await axios
        .post(`${this.$store.getters.baseUrl}/events`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Title added successfully", "success");
            this.$router.push({ name: "titles" });
            this.title = "";
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async addRegistration() {
      if (this.spot_name == "") {
        this.spotnameEmpty = true;
        return;
      }
      if (this.reg_no == "") {
        this.reg_noEmpty = true;
        return;
      }
      if (this.category == "") {
        this.categoryEmpty = true;
        return;
      }

      const formdata = new FormData();
      formdata.append("title_id", this.event_title);
      formdata.append("category_id", this.category);
      formdata.append("name", this.spot_name);
      formdata.append("event_id", this.eventId);
      formdata.append("reg_no", this.reg_no);
      if (this.mobile) {
        formdata.append("mobile", this.mobile);
      }
      if (this.designation) {
        formdata.append("designation", this.designation);
      }
      if (this.city) {
        formdata.append("city", this.city);
      }
      if (this.company) {
        formdata.append("company_name", this.company);
      }
      if (this.email) {
        formdata.append("email", this.email);
      }
      if (this.file) {
        formdata.append("photo", this.file);
      }
      await axios
        .post(
          `${this.$store.getters.baseUrl}/spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            localStorage.categoryId = this.category;
            this.getPrints(response.data.registration.id);
            // this.$swal("Success", "Spot Registration added successfully", "success");
            this.$swal({
              position: "middle",
              icon: "success",
              title: "Spot Registration added successfully",
              timer: 1500,
            });
            // this.$router.push({name: 'print', params: { id: response.data.registration.id }});
            // this.printDiv('printableArea');
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getPrints(id) {
      const formdata = new FormData();
      formdata.append("registration_id", id);
      await axios
        .post(`${this.$store.getters.baseUrl}/print-registration`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.registration_print = response.data.registration;
            this.event_batch = response.data.event_batch;
            this.batch_info = response.data.event_batch.batch_info;

            this.font_face = response.data.event_batch.font_face;

            this.batch_margin = response.data.event_batch.batch_margin;
            this.title = response.data.registration.title;
            this.barcode_data = response.data.barcode_data;
            this.qrCode = response.data.qrCode;
            this.event_batch_category_font_size =
              response.data.event_batch.category_font_size + "px";
            this.event_batch_city_font_size =
              response.data.event_batch.city_font_size + "px";
            this.event_batch_company_font_size =
              response.data.event_batch.company_font_size + "px";
            this.event_batch_design_font_size =
              response.data.event_batch.design_font_size + "px";
            this.event_batch_regno_font_size =
              response.data.event_batch.regno_font_size + "px";
            this.event_batch_name_font_size =
              response.data.event_batch.name_font_size + "px";
            this.event_batch_barcode_width =
              response.data.event_batch.barcode_width + "px";
            this.event_batch_barcode_height =
              response.data.event_batch.barcode_height + "px";
            if (this.print == false) {
              this.print = true;
            }
            console.log("print", this.print);
            setTimeout(
              function () {
                this.printDiv("printableArea");
              }.bind(this),
              1000
            );
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    printed(id) {
      console.log("print");
      if (this.print == true) {
        this.printDiv(id);
      }
    },
  },
};
</script>
