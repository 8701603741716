<template>
  <div class="">
    <TopHeader />
    <div class="d-flex justify-content-center align-items-center regis-spot" v-if="$store.getters.adminDetail.includes('CAN-REGISTRATION-REGISTRATION')">
      <div class="d-flex p-2 bg-white in-regis-spot">
        <router-link class="left-regist" to="/registration-list" >
            <img src="../assets/img/login-form-img.png" alt="" />
          <h5>Registration</h5>
          </router-link>
        <router-link class="left-regist" to="/spot-registration" >
            <img src="../assets/img/login-form-img.png" alt="" />
          <h5>Spot Registration</h5>
          </router-link>
        <!-- <a href="/#/registration-list" class="left-regist">
          <img src="../assets/img/login-form-img.png" alt="" />
          <h5>Registration</h5>
        </a> -->
        <!-- <a href="/#/spot-registration" class="left-regist">
          <img src="../assets/img/login-form-img.png" alt="" />
          <h5>Spot Registration</h5>
        </a> -->
      </div>
    </div>
  </div>
</template>
<script>
import TopHeader from "../components/TopHeader.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
}
  </script>
