<template>
  <div class="">
    <div class="p-3 d-flex flex-wrap">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 mb-3"
        v-for="(hall, index) in hall_show"
        :key="index"
      >
        <div class="event-category">
          <h5>Hall {{ index + 1 }}</h5>
          <div
            class="first-checkbox"
            v-for="(hall_c, index1) in hall.categories"
            :key="index1"
          >
            <label class="d-flex align-items-center justify-content-between">
              {{ hall_c.category_name }}
              <input type="checkbox" v-model="hall_c.category_select" />
            </label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-start mt-0 ms-3 mb-4">
          <button @click="postEvent()" class="submit-btn">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      hall: [],
      categories: [],
      hall_show: [],
    };
  },

  watch: {
    "$route.params.type": {
      handler: function (type) {
        console.log("type", type);
        if (type == "4") {
          this.getEvent();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  //  mounted() {
  //   this.getEvent();
  // },
  methods: {
    async getEvent() {
      console.log("event hall categories");
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);

      await axios
        .post(`${this.$store.getters.baseUrl}/manage-event`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.hall = response.data.event_Details.total_hall_entry;
            this.categories = response.data.event_Details.categories;
            for (var x = 0; x < this.hall; x++) {
              this.hall_show.push({ categories: [] });
              for (var y = 0; y < this.categories.length; y++) {
                this.hall_show[x].categories.push({
                  category_id: this.categories[y].id,
                  category_name: this.categories[y].name,
                  category_select: false,
                });
              }
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async postEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      for (var z = 0; z < this.hall_show.length; z++) {
        for (var s = 0; s < this.hall_show[z].categories.length; s++) {
          if (this.hall_show[z].categories[s].category_select == true) {
            console.log("selected");
            formdata.append(
              "hallcategories[" + z + "][category_name][" + s + "]",
              this.hall_show[z].categories[s].category_id
            );
          }
        }
      }

      await axios
        .post(`${this.$store.getters.baseUrl}/event/hall-category`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal({
              position: 'middle',
              icon: 'success',
              title: 'Event Hall Categories added successfully',
              timer: 1500
            })
            // this.$swal(
            //   "Success",
            //   "Event Hall Categories added successfully",
            //   "success"
            // );
            this.$router.push({
              name: "morecreateevent",
              params: { id: this.$route.params.id, type: 5 },
            });
            // window.location.href = "/more-create-event/"+this.$route.params.id+"/"+5;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
