<template>
  <!-- <div class="" v-bind:style="{backgroundImage: 'url(' + settings.banner_path + ')'}"> -->

   
    <div class="">
    <div class="ps-3">
      <div
        class="top-header d-flex justify-content-between align-items-center px-2"
      >
        <div class="d-flex align-items-center">
          <h4 class="mb-0 title-h4">Kiosk Spot Registration </h4>
        </div>
        <div class="d-flex">
          <p class="mb-0"></p>
          <router-link class="add-role" to="/kiosk-spotregistration" > Back</router-link> 
         
        </div>
      </div>
    </div>
  <div class="col-12">
              <!-- <label>QR Code</label> -->
              <img
                v-bind:src="settings.banner_path"
                style="width: 100% !important"
              />
     </div>

<div class="row">
<div class="col-md-4"> </div>
    <div class="text-center mt-5 col-md-4" id="printableArea">
            <table class="table table-bordered" style="background:#fff; font-size:16px">
               <tbody><tr>
                  <th>Reg. No.</th>
                  <td>{{reg_info.reg_no}}</td>
               </tr>
               <tr>
                  <th>Name</th>
                  <td>{{reg_info.full_name}}</td>
               </tr>
               <tr>
                  <th>Mobile No.</th>
                  <td>{{reg_info.mobile}}</td>
               </tr>
               <tr>
                  <th>Email ID</th>
                  <td>{{reg_info.email}}</td>
               </tr>
               <tr>
                  <th>Company Name</th>
                  <td>{{reg_info.company}}</td>
               </tr>

            </tbody></table>
            <div class="row">
              <div class="col-sm-4 text-center">                
                  <input type="hidden" name="reg_id" value="23">  
                  <button class="btn btn-danger btn-lg">
                  <router-link :to="{name: 'kioskprint'}"> Print </router-link>
                  </button>
                  <!-- <button class="btn btn-danger btn-lg"  @click="printDiv('printableArea')">Print</button> -->
              </div>
              <!-- <div class="col-sm-4">
              <a href="http://192.168.1.100/event/public/kiosk" class="btn btn-default btn-lg pull-right">Back</a>  
               </div> -->
            </div>
         </div>
<div class="col-md-4"> </div></div>

  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      isActive: false,
      addCategory: false,
      reg_info: '',
      settings:'',
    };
  },
   mounted() {
    this.getAdmins();
    this.getKiosk();
  },
  methods: {  

     async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.settings = response.data.settings;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    addRole: function () {
      this.isActive = !this.isActive;
    },
    dummy_add_cate: function () {
      this.isActive = !this.isActive;
    }, 
 
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    
     async getAdmins() {
      console.log(this.$route.name);
      const formdata = new FormData();
      formdata.append("reg_id", this.$route.params.id);
      await axios
        .post(`${this.$store.getters.baseUrl}/kiosk-pre-registration-edit`, formdata)
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.reg_info = response.data.reg_info;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
