<template>
  <div class="">
    <div class="px-3 mt-3 pb-2">
      <div class="general-page px-4 py-3">
        <div class="d-flex flex-wrap">
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0" for="">Top <small>(max: 300)</small></label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="top"
                placeholder="10"
              />
            </div>
          </div>
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0" for="">Left <small>(max: 200)</small></label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="bottom"
                placeholder="20"
              />
            </div>
          </div>
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0" for=""
                >Right <small>(max: 200)</small>
              </label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="left"
                placeholder="10"
              />
            </div>
          </div>
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0" for=""
                >Bottom <small>(max: 300)</small></label
              >
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="right"
                placeholder="5"
              />
            </div>
          </div>
        </div>

        <h5 class="mt-4">Choose Badge Information:</h5>
        <div class="d-flex flex-wrap">
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0 mt-0">
                <input
                  type="checkbox"
                  v-model="title"
                  placeholder="10"
                />Title</label
              >
              <!-- <div class="fs-cust">
                <label>Font Size</label>
                <input type="number" v-model="title_font_size" placeholder="14" />
              </div> -->
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="name"
                  placeholder="10"
                />Name</label
              >
              <div class="fs-cust">
                <label>Font Size</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="name_font_size"
                  placeholder="14"
                />
              </div>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="company"
                  placeholder="10"
                />Company</label
              >
              <div class="fs-cust">
                <label>Font Size</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="company_font_size"
                  placeholder="14"
                />
              </div>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="mobile"
                  placeholder="10"
                />Mobile No</label
              >
              <!-- <div class="fs-cust">
                <label>Font Size</label>
                <input type="number" v-model="designation_font_size" placeholder="14" />
              </div> -->
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="designation"
                  placeholder="10"
                />Designation</label
              >
              <div class="fs-cust">
                <label>Font Size</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="designation_font_size"
                  placeholder="14"
                />
              </div>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="city"
                  placeholder="10"
                />City</label
              >
              <div class="fs-cust">
                <label>Font Size</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="city_font_size"
                  placeholder="14"
                />
              </div>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="reg_no"
                  placeholder="10"
                />Registration No</label
              >
              <div class="fs-cust">
                <label>Font Size</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="reg_font_size"
                  placeholder="14"
                />
              </div>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0" for="">
                <input
                  type="checkbox"
                  v-model="category"
                  placeholder="10"
                />Category</label
              >
              <div class="fs-cust">
                <label>Font Size</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="category_font_size"
                  placeholder="14"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <h5 class="mb-0 mt-0">
                <input type="checkbox" v-model="photo" placeholder="10" />Photo
              </h5>
            </div>
            <div class="d-flex flex-wrap">
              <div class="mt-3 col-6">
                <div class="input-box">
                  <label class="mb-0" for="">Width</label>
                  <input
                    type="number"
                    v-on:keyup.enter="designBatch()"
                    v-model="photo_width"
                    placeholder="50"
                  />
                </div>
              </div>
              <div class="mt-3 col-6">
                <div class="input-box">
                  <label class="mb-0" for="">Height</label>
                  <input
                    type="number"
                    v-on:keyup.enter="designBatch()"
                    v-model="photo_height"
                    placeholder="50"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="col-12">
              <div class="single-select">
                <h5 class="mb-0 mt-0">
                  <input
                    @change="changeQr"
                    type="checkbox"
                    v-model="qr_code"
                    placeholder="10"
                  />Qrcode
                </h5>
              </div>
            </div>
            <div class="mt-3 col-6">
              <div class="input-box">
                <label class="mb-0" for=""> Width</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="bar_code_width"
                  placeholder="10"
                />
              </div>
            </div>
            <div class="mt-3 col-6">
              <div class="input-box">
                <label class="mb-0"> Height</label>
                <input
                  type="number"
                  v-on:keyup.enter="designBatch()"
                  v-model="bar_code_height"
                  placeholder="5"
                />
              </div>
            </div>

            <div class="mt-5 col-12">
              <div class="single-select">
                <h5 class="mb-0 mt-0">
                  <input
                    @change="changeBr"
                    type="checkbox"
                    v-model="bar_code"
                    placeholder="10"
                  />Bar code :
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <div class="mt-4 mb-3 col-6 col-sm-4 d-flex">
          <div class="input-box col">
            <label class="mb-0" for="">Font Family</label>
            <select name="font" id="font" v-model="font">
              <option value="calibri">calibri</option>
              <option value="open sance">open sance</option>
              <option value="Arial">Arial</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Helvetica">Helvetica</option>
            </select>
          </div>
        </div>
        <div class="mt-4 mb-3 col-6 col-sm-4 d-flex">
          <div class="input-box col">
            <label class="mb-0" for="">Choose Template</label>
            <select name="template" id="template" v-model="template">
              <option value="Center">Center</option>
              <option value="Top">Top</option>
              <option value="Left">Left</option>
              <option value="Right">Right</option>
              <option value="Bottom">Bottom</option>
            </select>
          </div>
        </div>
        <div class="mt-4 mb-3 col-6 col-sm-4 d-flex">
          <div class="input-box col">
            <label class="mb-0" for="">Choose Badge Image</label>
            <input type="file" @change="uploadImage($event)" />
          </div>
        </div>
        <div class="mt-4 mb-3 col-12 ps-4">
          <div v-if="qr_code == true && qr_code_data">
            <div class="input-box ps-2">
              <h5 class="mb-0 mt-0">Qr code :</h5>
            </div>
            <img
              class="ps-2 bar_code_width bar_code_height"
              v-bind:src="'data:image/png;base64,' + qr_code_data"
              :style="{
                width: event_batch_barcode_width,
                height: event_batch_barcode_height,
              }"
            />
          </div>
          <div v-if="bar_code == true && barcode_data">
            <div class="input-box">
              <h5 class="mb-0 mt-0">Bar code :</h5>
            </div>
            <img v-bind:src="'data:image/png;base64,' + barcode_data" />
          </div>
        </div>
        <div class="mt-1 mb-3 col-12 ps-4">
          <div v-if="batch_image_path">
            <h5 class="mb-0 mt-0">Badge Image :</h5>
            <img
              v-if="batch_image_path"
              class="bar_code_width bar_code_height"
              v-bind:src="batch_image_path"
              :style="{
                width: batch_photo_width,
                height: batch_photo_height,
              }"
            />
          </div>
          <!-- <div class="id-card" :style="{'margin':batch_margin, textAlign:template}"  > -->
          <div
            class="id-card"
            v-if="
              template == 'Left' || template == 'Right' || template == 'Center'
            "
            :style="{
              textAlign: template,
              backgroundImage: `url(${batch_image_path})`,
              width: '105mm',
              height: '135mm',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden',
            }"
          >
            <div
              class="bac d-flex"
              :style="{
                background: 'rgba(0,0,0,0.5)',
                fontFamily: font,
                padding: batch_margin,
                'font-size': name_font_size,
                width: '105mm',
                height: '135mm',
                color: '#fff',
              }"
            >
              <div
                class=""
                :style="{
                  textAlign: template,
                  width: '105mm',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }"
              >
                <h5
                  :style="{
                    'font-size': name_font_size,
                    fontWeight: 'bold',
                    color: '#fff',
                    marginBottom: '10px',
                    marginTop: '0px',
                  }"
                >
                  Mr.CANDIDATE NAME
                </h5>
                <div
                  v-if="photo == true && event_batch.template == 'Left'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 auto 0 0',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="photo == true && event_batch.template == 'Right'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 0 0 auto',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="photo == true && event_batch.template == 'Center'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 auto 0 auto',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <h5
                  :style="{ 'font-size': designation_font_size, color: '#fff' }"
                >
                  ASST. MANAGER
                </h5>
                <p :style="{ 'font-size': company_font_size, color: '#fff' }">
                  COMPANY NAME
                </p>
                <p>9587458956</p>
                <img
                  v-if="qr_code_data"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + qr_code_data"
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
                <img
                  v-if="bar_code == true"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + barcode_data"
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </div>
          </div>

          <div
            class="id-card"
            v-if="template == 'Bottom'"
            :style="{
              textAlign: template,
              backgroundImage: `url(${batch_image_path})`,
              width: '105mm',
              height: '135mm',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden',
            }"
          >
            <div
              class="bac d-flex"
              :style="{
                background: 'rgba(0,0,0,0.5)',
                fontFamily: font,
                padding: batch_margin,
                'font-size': name_font_size,
                width: '105mm',
                height: '135mm',
                color: '#fff',
              }"
            >
              <div
                class=""
                :style="{
                  textAlign: template,
                  width: '105mm',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  margin: 'auto 0 0 0',
                }"
              >
                <h5
                  :style="{
                    'font-size': name_font_size,
                    fontWeight: 'bold',
                    color: '#fff',
                    marginBottom: '10px',
                  }"
                >
                  Mr.CANDIDATE NAME
                </h5>
                <div
                  v-if="photo == true && event_batch.template == 'Left'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 auto 0 0',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="photo == true && event_batch.template == 'Right'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 0 0 auto',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="photo == true && event_batch.template == 'Center'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 auto 0 auto',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <h5
                  :style="{ 'font-size': designation_font_size, color: '#fff' }"
                >
                  ASST. MANAGER
                </h5>
                <p :style="{ 'font-size': company_font_size, color: '#fff' }">
                  COMPANY NAME
                </p>
                <p>9587458956</p>
                <img
                  v-if="qr_code_data"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + qr_code_data"
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
                <img
                  v-if="bar_code == true"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + barcode_data"
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </div>
          </div>

          <div
            class="id-card"
            v-if="template == 'Top'"
            :style="{
              textAlign: template,
              backgroundImage: `url(${batch_image_path})`,
              width: '105mm',
              height: '135mm',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden',
            }"
          >
            <div
              class="bac d-flex"
              :style="{
                background: 'rgba(0,0,0,0.5)',
                fontFamily: font,
                padding: batch_margin,
                'font-size': name_font_size,
                width: '105mm',
                height: '135mm',
                color: '#fff',
              }"
            >
              <div
                class=""
                :style="{
                  textAlign: template,
                  width: '105mm',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  margin: '0 0 auto 0',
                }"
              >
                <h5
                  :style="{
                    'font-size': name_font_size,
                    fontWeight: 'bold',
                    color: '#fff',
                    marginBottom: '10px',
                  }"
                >
                  Mr.CANDIDATE NAME
                </h5>
                <div
                  v-if="photo == true && event_batch.template == 'Left'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 auto 0 0',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="photo == true && event_batch.template == 'Right'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 0 0 auto',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="photo == true && event_batch.template == 'Center'"
                  :style="{
                    width: event_batch.photo_width + 'px',
                    height: event_batch.photo_height + 'px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    overflow: 'hidden',
                    background: '#fff',
                    margin: '0 auto 0 auto',
                  }"
                >
                  <img
                    :style="{
                      width: event_batch.photo_width + 'px',
                      height: event_batch.photo_height + 'px',
                    }"
                    src="../../assets/img/user1.png"
                    alt=""
                  />
                </div>
                <h5
                  :style="{ 'font-size': designation_font_size, color: '#fff' }"
                >
                  ASST. MANAGER
                </h5>
                <p :style="{ 'font-size': company_font_size, color: '#fff' }">
                  COMPANY NAME
                </p>
                <p>9587458956</p>
                <img
                  v-if="qr_code_data"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + qr_code_data"
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
                <img
                  v-if="bar_code == true"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + barcode_data"
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-start ms-4">
          <button @click="designBatch" class="submit-btn">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueQrcode from "vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      top: "",
      left: "",
      right: "",
      bottom: "",
      title: false,
      title_font_size: "",
      name: false,
      name_font_size: "",
      company: false,
      company_font_size: "",
      mobile: false,
      designation: false,
      designation_font_size: "",
      city: false,
      city_font_size: "",
      reg_no: false,
      reg_font_size: "",
      category: false,
      category_font_size: "",
      photo: false,
      photo_width: "",
      photo_height: "",
      bar_code: false,
      bar_code_width: "",
      bar_code_height: "",
      qr_code: false,
      qr_code_width: "",
      qr_code_height: "",
      font: "",
      template: "",
      file: "",
      barcode_data: "",
      selectedImage: [],
      batch_margin: "",
      batch_image_path: "",

      current_event_id: "",
      event_batch: "",
      batch_image: "",

      qr_code_data: "",
    };
  },

  mounted() {
    if (localStorage.eventId) {
      this.current_event_id = localStorage.eventId;
      this.eventbatch();
    }
  },
  methods: {
    uploadImage(event) {
      this.selectedImage = event.target.files[0];
    },

    // changeQr(){
    //   if(this.qr_code == true){
    //     this.bar_code = false;
    //   }else{
    //     this.bar_code = true;
    //   }
    // },

    //  changeBr(){
    //   if(this.bar_code == true){
    //     this.qr_code = false;
    //   }else{
    //     this.qr_code = true;
    //   }
    // },

    async eventbatch() {
      console.log("5fdf");
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);

      await axios
        .post(`${this.$store.getters.baseUrl}/manage-event-batch`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_batch = response.data.event_batch;
            this.batch_margin = response.data.event_batch.batch_margin;

            this.batch_image = response.data.event_batch.image;
            this.batch_image_path = response.data.event_batch.batch_image_path;

            this.template = this.event_batch.template;
            this.reg_font_size = this.event_batch.regno_font_size;
            this.batch_photo_width =
              response.data.event_batch.photo_width + "px";
            this.batch_photo_height =
              response.data.event_batch.photo_height + "px";

            this.photo_width = this.event_batch.photo_width;
            this.photo_height = this.event_batch.photo_height;

            this.name_font_size = this.event_batch.name_font_size;
            this.font = this.event_batch.font_face;
            this.designation_font_size = this.event_batch.design_font_size;
            this.company_font_size = this.event_batch.company_font_size;
            this.city_font_size = this.event_batch.city_font_size;
            this.category_font_size = this.event_batch.category_font_size;
            this.bar_code_width = this.event_batch.barcode_width;
            this.bar_code_height = this.event_batch.barcode_height;
            this.event_batch_barcode_width =
              response.data.event_batch.barcode_width + "px";
            this.event_batch_barcode_height =
              response.data.event_batch.barcode_height + "px";

            this.top = response.data.top.replace("px", "");
            this.left = response.data.left.replace("px", "");
            this.right = response.data.right.replace("px", "");
            this.bottom = response.data.bottom.replace("px", "");

            var batch_info = this.event_batch.batch_info;
            if (batch_info.includes("title")) {
              this.title = batch_info.includes("title") == 1 ? true : false;
            }
            if (batch_info.includes("name")) {
              this.name = batch_info.includes("name") == 1 ? true : false;
            }
            if (batch_info.includes("company")) {
              this.company = batch_info.includes("company") == 1 ? true : false;
            }
            if (batch_info.includes("mobile")) {
              this.mobile = batch_info.includes("mobile") == 1 ? true : false;
            }
            if (batch_info.includes("designation")) {
              this.designation =
                batch_info.includes("designation") == 1 ? true : false;
            }
            if (batch_info.includes("category")) {
              this.category =
                batch_info.includes("category") == 1 ? true : false;
            }
            if (batch_info.includes("city")) {
              this.city = batch_info.includes("city") == 1 ? true : false;
            }
            if (batch_info.includes("photo")) {
              this.photo = batch_info.includes("photo") == 1 ? true : false;
            }
            if (batch_info.includes("reg_no")) {
              this.reg_no = batch_info.includes("reg_no") == 1 ? true : false;
            }
            if (batch_info.includes("bar_code")) {
              this.bar_code =
                batch_info.includes("bar_code") == 1 ? true : false;
            }
            if (batch_info.includes("qrcode")) {
              this.qr_code = batch_info.includes("qrcode") == 1 ? true : false;
            }
            if (batch_info.includes("qrcode")) {
              this.qr_code_data = response.data.qrCode;
            } /*else {
              this.barcode_data = response.data.barcode_data;
            }*/
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async designBatch() {
      const formdata = new FormData();
      formdata.append("event_id", this.current_event_id);
      if (this.title == true) {
        formdata.append("batch_info[]", "title");
      }
      if (this.name == true) {
        formdata.append("batch_info[]", "name");
      }
      if (this.company == true) {
        formdata.append("batch_info[]", "company");
      }
      if (this.mobile == true) {
        formdata.append("batch_info[]", "mobile");
      }
      if (this.designation == true) {
        formdata.append("batch_info[]", "designation");
      }
      if (this.category == true) {
        formdata.append("batch_info[]", "category");
      }
      if (this.city == true) {
        formdata.append("batch_info[]", "city");
      }
      if (this.reg_no == true) {
        formdata.append("batch_info[]", "reg_no");
      }
      if (this.qr_code == true) {
        this.bar_code = false;
        formdata.append("batch_info[]", "qrcode");
      }
      if (this.photo == true) {
        formdata.append("batch_info[]", "photo");
      }
      if (this.bar_code == true) {
        this.qr_code = false;
        formdata.append("batch_info[]", "bar_code");
      }

      formdata.append("top", this.top);
      formdata.append("left", this.left);
      formdata.append("right", this.right);
      formdata.append("bottom", this.bottom);
      formdata.append("template", this.template);
      // formdata.append("title_font_size",this.title_font_size);
      formdata.append("name_font_size", this.name_font_size);
      formdata.append("company_font_size", this.company_font_size);
      formdata.append("design_font_size", this.designation_font_size);
      formdata.append("city_font_size", this.city_font_size);
      formdata.append("reg_font_size", this.reg_font_size);
      formdata.append("category_font_size", this.category_font_size);
      formdata.append("photoHeight", this.photo_height);
      formdata.append("photo_width", this.photo_width);
      formdata.append("barHeight", this.bar_code_height);
      formdata.append("font_family", this.font);
      formdata.append("barWidth", this.bar_code_width);
      formdata.append("qr_code_height", this.qr_code_height);
      formdata.append("qr_code_width", this.qr_code_width);

      if (this.selectedImage != null) {
        formdata.append("file", this.selectedImage);
      }

      await axios
        .post(`${this.$store.getters.baseUrl}/event-batch-update`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // this.barcode_data = response.data.barcode_data;
            // this.$swal(
            //   "Success",
            //   "Event Batch updated successfully",
            //   "success",
            //   {timer: 3000}
            // );
            this.$swal({
              position: "middle",
              icon: "success",
              title: "Event Batch updated successfully",
              timer: 1500,
            });
            var batch_info = response.data.event_batch.batch_info;
            this.batch_image = response.data.event_batch.image;
            this.batch_image_path = response.data.event_batch.batch_image_path;
            this.barcode_data = response.data.barcode_data;
            if (batch_info.includes("qrcode")) {
              this.qr_code_data = response.data.qrCode;
              this.barcode_data = "";
            } else {
              this.barcode_data = response.data.barcode_data;
              this.qr_code_data = "";
            }
            this.eventbatch();
            //  this.eventbatch();
            // this.$router.push({name: 'morecreateevent',params:{id:this.$route.params.id,type:5}});
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
