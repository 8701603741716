import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueLoading from 'vue-loading-overlay';
createApp(App).use(router).use(Loading).use(VueLoading).use(store).use(VueSweetalert2).mount("#app");


import { useRouter } from 'vue-router'

let currentPathObject = router.currentRoute.value; 
 
// console.log("Route Object", currentPathObject.path);
console.log(localStorage.token)



// Axios interceptors
import axios from 'axios'

// axios.interceptors.response.use((response) => responseHandler(response),
//   (error) => errorHandler(error))
var currentUrl = window.location.pathname;
// console.log(currentUrl);
// console.log(router);
const errorHandler = error => {
  if (error.response.status === 401) {
    router.push({ name: 'signIn' })
  }
  return;
};


const responseHandler = response => {
  return Promise.resolve(response);
};

// End of Axios interceptors

      // console.log("url", window.location.assign.hash);
// console.log(currentUrl);
//  if(localStorage.token){
//   if(currentUrl == '/'){
//     // router.push("/dashboard");
//     router.push({ name: 'dashboard' });
//   }
// }
// else{
//   router.push({ name: 'signIn' });
// }

if(localStorage.token){
  console.log('sss',window.location);
  if(window.location.hash == "#/"){
    console.log("dfgdfg");
    router.push("/dashboard");
  }
}else{
router.push({ name: 'signIn' });
}

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/1.0.18/vue.min.js"></script>

