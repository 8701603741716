import { createStore } from "vuex";
import router from "../router";
import axios from "axios";

export default createStore({
  state: {
    // baseUrl: "http://192.168.1.38/brand_blitz_laravel/public/crm",
     // url: "http://192.168.1.38/brand_blitz_laravel/public",
    baseUrl: "https://backend.brandblitz.in/public/crm",
    url: "https://backend.brandblitz.in/public",
    // liveUrl: "http://backend.brandblitz.in/",

    //Login States
    status: "",
    token: localStorage.getItem("token") || "",
    admin: localStorage.getItem("adminDetail") || "",
    adminName: localStorage.getItem("adminName") || "",
    user: localStorage.getItem("userDetail") || "",

    eventId: localStorage.getItem("eventId") || "",
    categoryId: localStorage.getItem("categoryId") || "",
    reg_type: localStorage.getItem("reg_type") || "",

    loginErrors: "",
  },
  getters: {
    baseUrl: (state) => state.baseUrl,
    url: (state) => state.url,
    //Login getters
    isLoggedIn: (state) => !!state.token,
    isAuthenticated: (state) => !!state.admin,
    StatePosts: (state) => state.posts,
    StateUser: (state) => state.admin,
    authStatus: (state) => state.status,
    loginErrors: (state) => state.loginErrors,
    token: (state) => state.token,
    adminDetail: (state) => state.admin,
    adminName: (state) => state.adminName,
    eventId: (state) => state.eventId,
    categoryId: (state) => state.categoryId,
    reg_type: (state) => state.reg_type,
    userDetails: (state) => state.user,
  },
  mutations: {
    //Login mutation
    reset_login_errors: (state) => {
      state.loginErrors = "";
    },
    set_login_errors: (state, err) => {
      state.loginErrors = err.response.data.message;
    },

    //login success
    auth_success(state, payload) {
      console.log(payload);
      state.status = "success";
      localStorage.setItem("token", payload.token);
      localStorage.setItem("adminDetail", payload.adminDetail);
      localStorage.setItem("adminName", payload.adminName);
      state.token = localStorage.getItem("token");
      state.admin = localStorage.getItem("adminDetail");
      state.adminName = localStorage.getItem("adminName");
      router.push("/dashboard");
    },

    //login error
    auth_error(state) {
      state.status = "error";
    },

    logout(state) {
      localStorage.clear();
      state.status = "";
      state.token = "";
      state.admin = {};
      state.adminName = "";
    },
  },
  actions: {
    async login({ commit }, user) {
      await axios
        .post(`${this.state.baseUrl}/admin/login`, user)
        .then((resp) => {
          //console.log("loginResponse", resp);
          const token = resp.data.token;
          const adminDetail = resp.data.permissions;
          const adminName = resp.data.admin.username;
          axios.defaults.headers.common["Authorization"] = token;
          console.log("permissions", adminDetail);
          localStorage.removeItem("stafftoken");
          commit("reset_login_errors");
          commit("auth_success", {
            token,
            adminDetail,
            adminName,
          });
        })
        .catch((err) => {
          console.log("error", err.response.data);
          if (err.response.data.status == "error") {
            commit("set_login_errors", err);
          } else {
            commit("auth_error");
            localStorage.removeItem("token");
          }
        });
    },

    //Logout action
    logout({ commit }) {
      commit("logout");
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
    },
  },
  modules: {},
});
