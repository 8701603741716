<template>
  <div class="">
    <div class="ps-3">
      <div
        class="top-header d-flex justify-content-between align-items-center px-2"
      >
        <div class="d-flex align-items-center">
          <h4 class="mb-0 title-h4">Registration</h4>
        </div>
        <div class="d-flex">
          <router-link class="add-role" to="/kiosk" title="Role">
            Back</router-link
          >
          <p class="mb-0"></p>
          <button class="border-0 bg-white">
            <!-- <img src="../assets/img/power.png" alt="" /> -->
          </button>
        </div>
      </div>
    </div>
    <div class="col-12">
              <!-- <label>QR Code</label> -->
              <img
                v-bind:src="settings.banner_path"
                style="width: 100% !important"
              />
     </div>
     
    <div class="text-center mt-5 text-uppercase">
      <h5>{{ event_name.name }}</h5>
      <h5>TODAY COUNT: {{ badge_count }}</h5>
    </div>
    <div>
      <div class="d-flex p-2 bg-white in-regis-spot" style="margin: auto">
        <router-link class="left-regist" to="/kiosk-registration">
          <img src="../assets/img/login-form-img.png" alt="" />
          <h5>Registration</h5>
        </router-link>
        <router-link class="left-regist" to="/kiosk-spotregistration">
          <img src="../assets/img/login-form-img.png" alt="" />
          <h5>Spot Registration</h5>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      kiosk_count: "",
      badge_count: "",
      settings: "",
      eventId: "",
      event_name: "",
    };
  },

  mounted() {
    this.getKiosk();
  },
  methods: {
    async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.kiosk_count = response.data.kiosk_count;
            this.badge_count = response.data.badge_count;
            this.settings = response.data.settings;
            this.eventId = this.settings.event_id;
            this.event_name = response.data.settings.event;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
