<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2">
            <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
              <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
          </div>
          <router-link class="add-role" to="/users" title="Role"> Back</router-link> 
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Name</label>
              <input v-model="name" @focus="nameEmpty = false" type="text" />
            <div v-if="nameEmpty" style="color: red">
            Name is required
          </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">User Name</label>
              <input v-model="username" @focus="usernameEmpty = false" type="name" />
          <div v-if="usernameEmpty" style="color: red">
            UserName is required
          </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Email</label>
              <input v-model="email" @focus="emailEmpty = false" type="email" required />
          <div v-if="emailEmpty" style="color: red">
            Email is required
          </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Role</label>
              <select v-model="role" @change="getRoles()" required>
                <option
                  v-for="(role, index) in roles"
                  :key="index"
                  :value="role.id"
                >
                  {{ role.role }}
                </option>
              </select>
           <div v-if="roleEmpty" style="color: red">
            Role is required
          </div>
              <!-- <select class="py-0" name="" id="">
                <option value="">Hall-1</option>
                <option value="">KitBag</option>
                <option value="">Magazine</option>
                <option value="">Certificate</option>
              </select> -->
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Password</label>
              <input
                type="password" v-model="password"
                placeholder="minimun 6 charector"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Confirm Password</label>
              <input
                type="password" v-model="confirmpassword" @focus="confirmpasswordMatch = false" />
           <div v-if="confirmpasswordMatch" style="color: red">
             Password Does not match          
           </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end me-2">
          <button class="submit-btn" variant="success" @click="update()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data(){
    return {
      name: "",
      nameEmpty:false,
      username: "",
      usernameEmpty:false,
      email: "",
      emailEmpty:false,
      password: "",
      passwordEmpty:false,
      confirmpassword:"",
      confirmpasswordMatch:false,
      role:"",
      roleEmpty:false,
      roles:[],
      admins:[],
    };
  },
   mounted() {
    this.getAdmins();
    this.getRoles();
  },
  methods: {
     async getAdmins() {
      await axios
        .get(`${this.$store.getters.baseUrl}/admin/${this.$route.params.id}/edit`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.admins = response.data.admin;
            this.name = response.data.admin.name;
            this.username = response.data.admin.username;
            this.email = response.data.admin.email;
            this.role = response.data.admin.role.id;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
     async getRoles() {
      await axios
        .get(`${this.$store.getters.baseUrl}/admin/create`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.roles = response.data.roles;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async update() {

      if (this.name == "") {
        this.nameEmpty = true;
        return;
      }
       if (this.username == "") {
        this.usernameEmpty = true;
        return;
      }    

       if (this.email == "") {
        this.emailEmpty = true;
        return;
      }    
        if (this.role == "") {
        this.roleEmpty = true;
        return;
      }
      if(!(this.confirmpassword == this.password)) {
        this.confirmpasswordMatch = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("name", this.name);
      formdata.append("username", this.username);
      formdata.append("email", this.email);
        if((this.password)){
         formdata.append("password", this.confirmpassword);
        }
      formdata.append("role_id", this.role);
      formdata.append("_method","PUT");
      await axios
        .post(`${this.$store.getters.baseUrl}/admin/${this.$route.params.id}`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
           console.log(response);
          if (response.data.status == "success") {
            this.$swal("Success", "User Updated successfully", "success");
            this.$router.push({name: 'users'});
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

