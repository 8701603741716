<template>
  <div class="">
    <div class="px-3 d-flex flex-wrap">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 mb-3"
        v-for="(event_c, index) in event_categories"
        :key="index"
      >
        <div class="event-category">
          <h5>{{ event_c.category_name }}</h5>
          <div
            class="food-checkbox px-2"
            v-for="(day, index1) in event_c.days"
            :key="index1"
          >
            <h6
              class="food-all-head d-flex align-items-center justify-content-between"
            >
              DAY {{ index1 + 1 }}
            </h6>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              BREAK - FAST
              <input
                v-if="day.breakfast == true"
                type="checkbox"
                v-model="day.c_breakfast"
              />
            </label>
            <div class="food-name">
              <label> {{ day.breakfast_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              LUNCH
              <input
                v-if="day.lunch == true"
                type="checkbox"
                v-model="day.c_lunch"
              />
            </label>
            <div class="food-name">
              <label> {{ day.lunch_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              DINNER
              <input
                v-if="day.dinner == true"
                type="checkbox"
                v-model="day.c_dinner"
              />
            </label>
            <div class="food-name">
              <label> {{ day.dinner_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              OTHER SERVICE 1
              <input
                v-if="day.other_service_1 == true"
                type="checkbox"
                v-model="day.c_other_service_1"
              />
            </label>
            <div class="food-name">
              <label> {{ day.other_service_1_name }} </label>
            </div>
            <label
              class="food-all-checkbox d-flex align-items-center justify-content-between"
            >
              OTHER SERVICE 2
              <input
                v-if="day.other_service_2 == true"
                type="checkbox"
                v-model="day.c_other_service_2"
              />
            </label>
            <div class="food-name">
              <label> {{ day.other_service_2_name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-start mt-0 mb-2 me-2 ms-4">
      <button class="submit-btn" variant="success" @click="postEvent()">
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      event_categories: [],
    };
  },

  watch: {
    $route: {
      handler: function () {
        this.getEvent();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);

      await axios
        .post(`${this.$store.getters.baseUrl}/manage-event`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            var categories = response.data.event_Details.categories;
            var services = response.data.event_services;
            for (var x = 0; x < categories.length; x++) {
              this.event_categories.push({
                category_name: categories[x].name,
                category_id: categories[x].id,
                days: [],
              });
              for (var y = 0; y < services.length; y++) {
                this.event_categories[x].days.push({
                  breakfast: services[y].break_fast == 1 ? true : false,
                  breakfast_name: services[y].break_fast_name,
                  lunch: services[y].lunch == 1 ? true : false,
                  lunch_name: services[y].lunch_name,
                  dinner: services[y].dinner == 1 ? true : false,
                  dinner_name: services[y].dinner_name,
                  other_service_1:
                    services[y].other_service_1 == 1 ? true : false,
                  other_service_1_name: services[y].other_service_1_name,
                  other_service_2:
                    services[y].other_service_2 == 1 ? true : false,
                  other_service_2_name: services[y].other_service_2_name,
                });
              }
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async postEvent() {
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      for (var z = 0; z < this.event_categories.length; z++) {
        formdata.append(
          "category_services[" + z + "][category]",
          this.event_categories[z].category_id
        );

        for (var s = 0; s < this.event_categories[z].days.length; s++) {
          formdata.append(
            "category_services[" + z + "][days][" + s + "][break_fast]",
            this.event_categories[z].days[s].c_breakfast == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][lunch]",
            this.event_categories[z].days[s].c_lunch == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][dinner]",
            this.event_categories[z].days[s].c_dinner == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][other_service_1]",
            this.event_categories[z].days[s].c_other_service_1 == true ? 1 : 0
          );
          formdata.append(
            "category_services[" + z + "][days][" + s + "][other_service_2]",
            this.event_categories[z].days[s].c_other_service_2 == true ? 1 : 0
          );
        }
      }

      await axios
        .post(`${this.$store.getters.baseUrl}/event/service`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal({
              position: "middle",
              icon: "success",
              title: "Event food privilege added successfully",
              timer: 1500,
            });
            // this.$swal(
            //   "Success",
            //   "Event food privilege added successfully",
            //   "success"
            // );
            this.$router.push({
              name: "morecreateevent",
              params: { id: this.$route.params.id, type: 4 },
            });
            // window.location.href = "/more-create-event/"+this.$route.params.id+"/"+4;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
