<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Conference Name</label>
              <select
                v-model="event_detail"
                @change="getEventDetails()"
                @focus="event_detailEmpty = false"
                :value="event.name"
              >
                <option :value="event.id">
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Title</label>
              <select
                v-model="event_title"
                @change="getEvents()"
                @focus="event_titleEmpty = false"
                read_only
                placeholder="Select Event Name"
              >
                <option :value="registration.id">
                  {{ registration.title_id }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Full Name</label>
              <input
                type="text"
                v-on:keyup.enter="editRegistration()"
                v-model="spot_name"
                @focus="spotnameEmpty = false"
              />
            </div>
            <div v-if="spotnameEmpty" style="color: red">
              Full Name is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Company Name</label>
              <input
                type="text"
                v-on:keyup.enter="editRegistration()"
                v-model="company"
                @focus="companyEmpty = false"
              />
            </div>
            <div v-if="companyEmpty" style="color: red">
              Company Name is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Registration No</label>
              <input
                type="text"
                v-on:keyup.enter="editRegistration()"
                v-model="reg_no"
                @focus="reg_noEmpty = false"
                required
              />
            </div>
            <div v-if="reg_noEmpty" style="color: red">
              Registration Number is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Category Name</label>
              <select
                v-model="category"
                @change="getEvents()"
                @focus="categoryEmpty = false"
                required
                placeholder="Select Category"
              >
                <option
                  v-for="(event_category, index) in event_categories"
                  :key="index"
                  :value="event_category.id"
                >
                  {{ event_category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Designation</label>
              <input
                type="text"
                v-on:keyup.enter="editRegistration()"
                v-model="designation"
                @focus="designationEmpty = false"
              />
            </div>
            <div v-if="designationEmpty" style="color: red">
              Designation is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">City</label>
              <input
                type="text"
                v-on:keyup.enter="editRegistration()"
                v-model="city"
                @focus="cityEmpty = false"
              />
            </div>
            <div v-if="cityEmpty" style="color: red">City is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Mobile</label>
              <input
                type="number"
                v-on:keyup.enter="editRegistration()"
                v-model="mobile"
                @focus="mobileEmpty = false"
              />
            </div>
            <div v-if="mobileEmpty" style="color: red">Mobile is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Email</label>
              <input
                type="email"
                v-on:keyup.enter="editRegistration()"
                v-model="email"
                @focus="emailEmpty = false"
              />
            </div>
            <div v-if="emailEmpty" style="color: red">Email is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Snap Shot</label>
              <input
                type="file"
                name="file"
                :v-model="file"
                @focus="fileEmpty = false"
                required
              />
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <button class="submit-btn" v-on:click="editRegistration()">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      spot_name: "",
      spotnameEmpty: false,
      company: "",
      companyEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      designation: "",
      designationEmpty: false,
      category: "",
      categoryEmpty: false,
      city: "",
      cityEmpty: false,
      mobile: "",
      mobileEmpty: false,
      email: "",
      emailEmpty: false,
      file: "",
      fileEmpty: false,
      event_detail: "",
      event_detailEmpty: false,
      event_title: "",
      event_titleEmpty: false,
      events: [],
      event: "",
      eventEmpty: false,
      registration: "",
      eventId: localStorage.getItem("eventId"),
    };
  },

  mounted() {
    this.getEventDetails();
    if (localStorage.eventId) {
      this.event = localStorage.eventId;
    }
  },
  methods: {
    set() {
      localStorage.eventId = this.event;
      console.log(this.event);
    },
    async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/registration-events`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventDetails() {
      const formdata = new FormData();
      formdata.append("registration_id", this.$route.params.id);
      formdata.append("event_id", this.$route.params.eventId);
      await axios
        .post(`${this.$store.getters.baseUrl}/registration-edit`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event = response.data.event;
            this.registration = response.data.registration;
            this.event_categories = response.data.categories;
            this.spot_name = response.data.registration.full_name;
            this.company = response.data.registration.company;
            this.reg_no = response.data.registration.reg_no;
            this.designation = response.data.registration.designation;
            this.city = response.data.registration.city;
            this.mobile = response.data.registration.mobile;
            this.email = response.data.registration.email;
            this.file = response.data.registration.photo;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async registration() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event", this.event);
      await axios
        .post(`${this.$store.getters.baseUrl}/events`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Title added successfully", "success");
            this.$router.push({ name: "titles" });
            this.title = "";
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async editRegistration() {
      if (this.spot_name == "") {
        this.spotnameEmpty = true;
        return;
      }
      if (this.event_title == "") {
        this.event_titleEmpty = true;
        return;
      }
      if (this.company == "") {
        this.companyEmpty = true;
        return;
      }
      if (this.reg_no == "") {
        this.reg_noEmpty = true;
        return;
      }
      if (this.category == "") {
        this.categoryEmpty = true;
        return;
      }
      if (this.designation == "") {
        this.designationEmpty = true;
        return;
      }
      if (this.city == "") {
        this.cityEmpty = true;
        return;
      }
      if (this.email == "") {
        this.emailEmpty = true;
        return;
      }
      if (this.mobile == "") {
        this.mobileEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("title", this.event_title);
      formdata.append("category", this.category);
      formdata.append("full_name", this.spot_name);
      formdata.append("city", this.city);
      formdata.append("company", this.company);
      formdata.append("mobile", this.mobile);
      formdata.append("designation", this.designation);
      formdata.append("email", this.email);
      formdata.append("event_id", this.eventId);
      formdata.append("reg_no", this.reg_no);
      formdata.append("photo", this.file);
      formdata.append("badge_name", this.badge_name);
      formdata.append("kitbag", this.kitbag);
      formdata.append("certificates", this.certificates);
      formdata.append("magazine", this.magazine);
      formdata.append("payment", this.payment);
      formdata.append("register_amount", this.register_amount);
      formdata.append("workshop_amount", this.workshop_amount);
      formdata.append("other_amount", this.other_amount);
      formdata.append("total_amount", this.total_amount);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Admin added successfully", "success");
            this.$router.push({
              name: "print",
              params: { id: response.data.registration.id },
            });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
