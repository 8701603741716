<template>
  <html>
    <div class="ps-3 mt-3">
      <div class="general-page px-3 py-3 w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2"></div>
          <!-- <router-link class="add-role" to="{ name: 'kiosk' }" title="Role"> Back</router-link>  -->

          <!-- <router-link class="add-role"
                    :to="{ name: 'registrationkiosok'}"
                >Back</router-link> -->

          <button class="set-btn" @click="back()">Back</button>
        </div>
        <div class="col-12">
          <!-- <label>QR Code</label> -->
          <img
            v-bind:src="settings.banner_path"
            style="width: 100% !important"
          />
        </div>

        <div class="">
          <div class="event-select mb-3">
            <p class="choose-evnet">Registration. No./ Mobile No.</p>
            <input
              v-if="mobile"
              class="input"
              type="text"
              v-on:keyup.enter="set()"
              placeholder="Enter mobile number"
              name="mobile"
              v-model="mobile"
              @focus="mobileEmpty = false"
            />
            <input
              v-else
              class="input"
              type="text"
              placeholder="Enter Reg number"
              v-on:keyup.enter="set()"
              name="reg_no"
              v-model="reg_no"
              @focus="reg_noEmpty = false"
            />
            <div v-if="mobileEmpty" style="color: red">
              Mobile.No / Registration.No is required
            </div>
          </div>
        </div>
        <div class="simple-keyboard"></div>

        <div class="col-12 text-center">
          <div class="d-flex justify-content-center mt-0 ms-0 mb-4">
            <button v-on:click="set()" class="submit-btn text-center">
              Submit
            </button>
          </div>
        </div>

        <!-- <div class="col-md-6 mt-2">
          <button type="submit" class="btn btn-primary" v-on:click="set()">
            Submit
          </button>
        </div> -->
      </div>
    </div>
  </html>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import VueQrcode from "vue-qrcode";

import "simple-keyboard/build/css/index.css";
import keyboard from "vue-keyboard";

export default {
  name: "HomeView",
  el: "#app",
  components: {
    VueQrcode,
    keyboard,
  },
  data() {
    return {
      mobile: "",
      mobileEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      reg_info: "",
      input: "",
      value: "",
      keyboard: "",
      settings: "",
      reloaded: false,
    };
  },
  // watch: {
  //  '$route': {
  //       handler: function() {
  //          this.getKiosk();
  //       },
  //       deep: true,
  //       immediate: true
  //     }
  //   },
  mounted() {
    if (window) {
      console.log("keyboard");
      import("simple-keyboard").then((KeyboardClass) => {
        const Keyboard = KeyboardClass.default;
        let keyboard = new Keyboard({
          onChange: (input) => this.onChange(input),
          onKeyPress: (button) => this.onKeyPress(button),
        });
      });
    }
    this.getKiosk();
  },
  methods: {
    async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.settings = response.data.settings;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    onChange(input) {
      document.querySelector(".input").value = input;
      console.log("Input changed", input);
      this.mobile = input;
      console.log("mobile", this.mobile);
    },

    onKeyPress(button) {
      console.log("Button pressed", button);
    },

    async set() {
      const formdata = new FormData();

      if (this.mobile) {
        formdata.append("mobile", this.mobile);
      }
      if (this.reg_no) {
        formdata.append("reg_no", this.reg_no);
      }
      await axios
        .post(
          `${this.$store.getters.baseUrl}/kiosk-pre-registration`,
          formdata,
          {}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.reg_info = response.data.reg_info;
            this.$router.push({
              name: "kiosokpreregpost",
              params: {
                id: response.data.reg_info.id,
                reg_no: response.data.reg_info.mobile
                  ? response.data.reg_info.mobile
                  : response.data.reg_info.reg_no,
              },
            });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async back() {
      // this.$router.push({name: 'registrationkiosok'});
      window.location.href = "/#/registration-kiosk";
      window.location.reload();
    },
  },
};
</script>
