<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Add Registration</h5>
          <!-- <router-link class="add-role" to="/titles" title="Event Report">
            Manage Title
          </router-link> -->
        </div>

        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Conference Name</label>
              <select
                v-model="event_name"
                @change="getEventDetails()"
                @focus="event_nameEmpty = false"
                required
              >
                <option :value="event.id">
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Title</label>
              <select
                v-model="event_title"
                @change="getEventDetails()"
                @focus="event_titleEmpty = false"
                read_only
                placeholder="Select Event Name"
              >
                <option
                  v-for="(event_title, index) in event_titles"
                  :key="index"
                  :value="event_title.id"
                  readonly
                >
                  {{ event_title.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Full Name</label>
              <input
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="spot_name"
                @focus="spotnameEmpty = false"
              />
            </div>
            <div v-if="spotnameEmpty" style="color: red">
              Full Name is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Company Name</label>
              <input
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="company"
                @focus="companyEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Category Name</label>
              <select
                v-model="category"
                @change="getEventDetails()"
                @focus="categoryEmpty = false"
                required
                placeholder="Select Category"
              >
                <option
                  v-for="(event_category, index) in event_categories"
                  :key="index"
                  :value="event_category.id"
                >
                  {{ event_category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Designation</label>
              <input
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="designation"
                @focus="designationEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">City</label>
              <input
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="city"
                @focus="cityEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Mobile</label>
              <input
                type="number"
                v-on:keyup.enter="addRegistration()"
                v-model="mobile"
                @focus="mobileEmpty = false"
                maxlength="10"
              />
            </div>
            <div v-if="mobileEmpty" style="color: red">
              Mobile Number is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Email</label>
              <input
                type="email"
                v-on:keyup.enter="addRegistration()"
                v-model="email"
                @focus="emailEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Snap Shot</label>
              <input
                type="file"
                name="file"
                v-on:change="uploadFiles"
                @focus="fileEmpty = false"
                required
              />
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <button class="submit-btn" v-on:click="addRegistration()">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      spot_name: "",
      spotnameEmpty: false,
      company: "",
      companyEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      designation: "",
      designationEmpty: false,
      category: "",
      categoryEmpty: false,
      city: "",
      cityEmpty: false,
      mobile: "",
      mobileEmpty: false,
      email: "",
      emailEmpty: false,
      file: "",
      fileEmpty: false,
      event_title: "",
      event_titleEmpty: false,
      event: "",
      eventEmpty: false,
      event: [],
      event_name: "",
      event_nameEmpty: false,
      event_categories: [],
      event_titles: [],
    };
  },

  mounted() {
    this.getEventDetails();
  },
  methods: {
    async getEventDetails() {
      await axios
        .post(
          `${this.$store.getters.baseUrl}/get-spot-registration/${this.$route.params.id}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event = response.data.event;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    uploadFiles(event) {
      this.file = event.target.files[0];
    },
    async addRegistration() {
      if (this.spot_name == "") {
        this.spotnameEmpty = true;
        return;
      }
      if (this.event_title == "") {
        this.event_titleEmpty = true;
        return;
      }
      if (this.category == "") {
        this.categoryEmpty = true;
        return;
      }
      if (this.mobile == "") {
        this.mobileEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("title_id", this.event_title);
      formdata.append("category_id", this.category);
      formdata.append("name", this.spot_name);
      formdata.append("mobile", this.mobile);
      if (this.city) {
        formdata.append("city", this.city);
      }
      if (this.company) {
        formdata.append("company_name", this.company);
      }
      if (this.designation) {
        formdata.append("designation", this.designation);
      }
      if (this.email) {
        formdata.append("email", this.email);
      }
      formdata.append("event_id", this.event_name);
      if (this.file) {
        formdata.append("photo", this.file);
      }
      await axios
        .post(`${this.$store.getters.baseUrl}/spot-registration`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal(
              "Success",
              "Spot Registration added successfully",
              "success"
            );
            // this.$router.push({name: 'print', params: { id: response.data.registration.id }});
            this.$router.push("/");
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
