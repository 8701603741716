<template>
  <div class="col p-2">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <TopHeader />
    <div
      class="ps-3 mt-3"
      v-if="$store.getters.adminDetail.includes('CAN-LIST-PRINT BADGE')"
    >
      <div class="general-page px-3 py-3">
        <!-- <div class="d-flex justify-content-between align-items-center mb-2">
          <h5 class="mb-0">Import Registration CSV File</h5>
          <div class="d-flex">
            <button v-on:click="addRole" class="upload-img">
              Upload Image
            </button>
            <button class="add-role ms-2">Download</button>
          </div>
        </div> -->
        <div class="event-select">
          <div class="d-flex">
            <div class="col px-1">
              <p class="choose-evnet">Select Conference</p>
              <select
                v-if="this.eventId != null"
                v-model="event"
                @change="setEvent()"
                @focus="eventEmpty = false"
              >
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
              <select
                v-else
                v-model="event"
                @change="setEvent()"
                @focus="eventEmpty = false"
              >
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>

              <div v-if="eventEmpty" style="color: red">
                Event ID is required
              </div>
            </div>
            <div class="col px-1">
              <p class="choose-evnet">Select Category</p>
              <select
                v-model="category"
                @change="setCategory()"
                @focus="categoryEmpty = false"
                placeholder="Select Event Name"
              >
                <option
                  v-for="(event, index) in event_categories"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
            </div>
            <div class="col px-1">
              <p class="choose-evnet">Registration Type</p>
              <select
                v-model="reg_type"
                @change="setType()"
                @focus="reg_typeEmpty = false"
              >
                <option value="Registration">Registration</option>
                <option value="Spot Registration">Spot Registration</option>
              </select>
            </div>
            <div class="col px-1">
              <p class="choose-evnet">Registration No</p>
              <input
                type="text"
                v-on:keyup.enter="printEvent()"
                v-model="reg_no"
                @focus="reg_noEmpty = false"
              />
            </div>
          </div>
          <button
            class="submit-btn ms-1 mt-3"
            @click="
              (pageSelected = 1), (rowsAndFiltersClicked = true), printEvent()
            "
          >
            Choose
          </button>
        </div>
      </div>
    </div>
    <div class="ps-3 mt-3">
      <div class="general-page tabel-data-page px-4 py-3">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mt-2"
        >
          <div class="sort d-flex">
            <p class="mb-0">Show</p>
            <select
              class="mx-2 form-control"
              v-model="rows"
              @change="
                (pageSelected = 1), (rowsAndFiltersClicked = true), printEvent()
              "
            >
              <option value="10">10</option>
              <option value="100">100</option>
              <option value="1000">1000</option>
              <option value="5000">5000</option>
              <option value="10000">10000</option>
            </select>
            <p class="mb-0">Entries</p>
          </div>
          <div class="search-input d-flex">
            <!-- <input type="text" placeholder="Search" /> -->
            <input
              class="form-control"
              type="text"
              v-model="search"
              placeholder="Search..."
              aria-label="Search"
              style="width: 300px"
              @keyup="
                (pageSelected = 1), (rowsAndFiltersClicked = true), printEvent()
              "
            />
            <div class="text-uppercase">
              <button
                :style="{
                  width: '110px',
                  height: '32px',
                  fontSize: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 0,
                  background: '#a11c20',
                  color: '#fff',
                  marginLeft: '15px',
                }"
                @click="printDiv('batch-print')"
              >
                PRINT ALL
              </button>
            </div>
          </div>
        </div>
        <div class="" id="printableArea">
          <table class="table-page mt-2">
            <tr class="w-100">
              <th>#</th>
              <th>Registration No</th>
              <th>Category Name</th>
              <th>Full Name</th>
              <th>Mobile No</th>
              <th>City</th>
              <th>Company Name</th>
              <th>Print Staus</th>
              <th width="100px">Action</th>
            </tr>
            <tr v-if="registrations.length == 0">
              <td colspan="15" class="text-center">No records found</td>
            </tr>
            <tr
              v-else
              v-for="(registration, index) in registrations"
              :key="index"
            >
              <td>{{ (currentPage - 1) * rows + index + 1 }}</td>
              <td>{{ registration.reg_no }}</td>
              <td v-if="registration.category != null">
                {{ registration.category.name }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.full_name != null">
                {{ registration.full_name }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.mobile != null">
                {{ registration.mobile }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.city != null">{{ registration.city }}</td>
              <td v-else>-</td>
              <td v-if="registration.company != null">
                {{ registration.company }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.printed_from != null">Printed</td>
              <td v-else>Not Printed</td>
              <td>
                <router-link
                  v-if="
                    $store.getters.adminDetail.includes('CAN-EDIT-PRINT BADGE')
                  "
                  :to="{ name: 'editprinter', params: { id: registration.id } }"
                  ><i class="fa-solid fa-edit"></i
                ></router-link>
                <button class="print-ic" @click="printDivs('print-'+registration.id)">
                  <i class="fa-solid fa-print"></i>
                </button>
              </td>

              <td class="d-none">
                <div :id="'print-'+ registration.id"
                  :style="{
                    display: 'flex',
                    justifyContent: 'center',
                  }"
                >
                  <div
                    v-if="event_batch.template == 'Bottom'"
                    class="text-uppercase"
                    :style="{
                      display: 'flex',
                      width: '105mm',
                      height: '135mm',
                      alignItems: 'end',
                    }"
                  >
                    <div
                      :style="{
                        fontFamily: font_face,
                        fontSize: name_font_size,
                        margin: batch_margin,
                      }"
                    >
                      <dl
                        class="text-uppercase"
                        :style="{
                          padding: batch_margin,
                          textAlign: event_batch.template,
                        }"
                      >
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_name_font_size,
                            textAlign: event_batch.template,
                          }"
                        >
                          {{
                            registration.title ? registration.title.title : ""
                          }}
                          {{ registration.full_name }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_design_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                          }"
                        >
                          {{ registration.designation }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_company_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                            'margin-bottom': '10px',
                          }"
                        >
                          {{ registration.company }}
                        </dt>
                        <dt v-if="batch_info.includes('qrcode')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.qrcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                        <dt v-if="batch_info.includes('bar_code')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.barcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                      </dl>
                    </div>
                  </div>
                  <div
                    v-if="event_batch.template == 'Left'"
                    class="text-uppercase"
                    :style="{
                      display: 'flex',
                      width: '105mm',
                      height: '135mm',
                      justifyContent: 'left',
                    }"
                  >
                    <div
                      :style="{
                        fontFamily: font_face,
                        fontSize: name_font_size,
                        margin: batch_margin,
                      }"
                    >
                      <dl
                        class="text-uppercase"
                        :style="{
                          padding: batch_margin,
                          textAlign: event_batch.template,
                        }"
                      >
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_name_font_size,
                            textAlign: event_batch.template,
                          }"
                        >
                          {{
                            registration.title ? registration.title.title : ""
                          }}
                          {{ registration.full_name }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_design_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                          }"
                        >
                          {{ registration.designation }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_company_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                            'margin-bottom': '10px',
                          }"
                        >
                          {{ registration.company }}
                        </dt>
                        <dt v-if="batch_info.includes('qrcode')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.qrcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                        <dt v-if="batch_info.includes('bar_code')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.barcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                      </dl>
                    </div>
                  </div>
                  <div
                    v-if="event_batch.template == 'Center'"
                    class="text-uppercase"
                    :style="{
                      display: 'flex',
                      justifyContent: 'center',
                      marginLeft: '19px',
                    }"
                  >
                    <div
                      :style="{
                        fontFamily: font_face,
                        fontSize: name_font_size,
                        margin: batch_margin,
                      }"
                    >
                      <dl
                        class="text-uppercase"
                        :style="{
                          padding: batch_margin,
                          textAlign: event_batch.template,
                        }"
                      >
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_name_font_size,
                            textAlign: event_batch.template,
                          }"
                        >
                          {{
                            registration.title ? registration.title.title : ""
                          }}
                          {{ registration.full_name }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_design_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                          }"
                        >
                          {{ registration.designation }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_company_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                            'margin-bottom': '10px',
                          }"
                        >
                          {{ registration.company }}
                        </dt>
                        <dt v-if="batch_info.includes('qrcode')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.qrcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                        <dt v-if="batch_info.includes('bar_code')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.barcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                      </dl>
                    </div>
                  </div>

                  <div
                    v-if="event_batch.template == 'Right'"
                    class="text-uppercase"
                    :style="{
                      display: 'flex',
                      width: '105mm',
                      height: '135mm',
                      justifyContent: 'right',
                    }"
                  >
                    <div
                      :style="{
                        fontFamily: font_face,
                        fontSize: name_font_size,
                        margin: batch_margin,
                      }"
                    >
                      <dl
                        class="text-uppercase"
                        :style="{
                          padding: batch_margin,
                          textAlign: event_batch.template,
                        }"
                      >
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_name_font_size,
                            textAlign: event_batch.template,
                          }"
                        >
                          {{
                            registration.title ? registration.title.title : ""
                          }}
                          {{ registration.full_name }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_design_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                          }"
                        >
                          {{ registration.designation }}
                        </dt>
                        <dt
                          :style="{
                            color: '#000',
                            'font-size': event_batch_company_font_size,
                            textAlign: event_batch.template,
                            'margin-top': '10px',
                            'margin-bottom': '10px',
                          }"
                        >
                          {{ registration.company }}
                        </dt>
                        <dt v-if="batch_info.includes('qrcode')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.qrcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                        <dt v-if="batch_info.includes('bar_code')">
                          <div>
                            <img
                              v-bind:src="
                                'data:image/png;base64,' +
                                registration.barcode_data
                              "
                              :style="{
                                width: event_batch_barcode_width,
                                height: event_batch_barcode_height,
                                textAlign: event_batch.template,
                              }"
                            />
                          </div>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </td>

            </tr>
          </table>
        </div>

        <div class="pagi-nation">
          <ul class="d-flex mt-3 mb-0 p-0">
            <li v-if="lastPage != 1 && registrations.length > 0">
              <div
                class="pagi-a left-rit-arrow disabled"
                variant="outline-primary"
                @click="first()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[0].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-double-left"></i>
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                variant="outline-primary"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[0].display
                      ? true
                      : false
                    : false
                "
                @click="previousPage()"
              >
                <i class="fas fa-angle-left"></i>
              </div>
            </li>
            <li class="d-flex">
              <div
                class="pagi-a"
                :pressed="page.activeStatus == true ? true : false"
                v-show="page.display"
                :id="page.id"
                @click="buttonClick(index)"
                size="lg"
                variant="outline-primary"
                v-for="(page, index) in totalPages"
                :key="index"
              >
                {{ index + 1 }}
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                href="#"
                variant="outline-primary"
                @click="nextPage()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[totalPages.length - 1].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-right"></i>
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                href="#"
                variant="outline-primary"
                @click="last()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[totalPages.length - 1].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-double-right"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-upload-img" v-bind:class="{ uploadImg: isActive }">
      <div class="in-modal-upload-img">
        <div class="bg-white px-5 py-4 rounded-1 rounded">
          <div class="form-action">
            <p class="text-dark mb-1">Choose Event</p>
            <input
              class="text-input"
              type="text"
              placeholder="Add Categories"
            />
          </div>
          <div class="form-action mt-2 mb-2">
            <p class="text-dark mb-1">Upload File</p>
            <input class="mt-0 ms-0 p-0" type="file" />
          </div>
          <button class="save-btn">Upload</button>
        </div>
      </div>
      <div @click="addRole" v-bind:class="{ dummy_add_cate: isActive }"></div>
    </div>
  </div>

  <div class="something d-none print-beark" id="batch-print">
    <div
      class="print-beark"
      v-for="(registrationb, index) in total_registrations"
      :key="index"
      id="printableArea"
    >
      <div
        v-if="event_batch.template == 'Bottom'"
        class="text-uppercase"
        :style="{
          display: 'flex',
          width: '105mm',
          height: '135mm',
          alignItems: 'end',
        }"
      >
        <div
          :style="{
            fontFamily: font_face,
            fontSize: name_font_size,
            margin: batch_margin,
          }"
        >
          <dl
            class="text-uppercase"
            :style="{
              padding: batch_margin,
              textAlign: event_batch.template,
            }"
          >
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_name_font_size,
              }"
            >
              {{ registrationb.title ? registrationb.title.title : "" }}
              {{ registrationb.full_name }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_design_font_size,
                'margin-top': '10px',
              }"
            >
              {{ registrationb.designation }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_company_font_size,

                'margin-bottom': '10px',
                'margin-top': '10px',
              }"
            >
              {{ registrationb.company }}
            </dt>
            <dt v-if="batch_info.includes('qrcode')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.qrcode_data
                  "
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </dt>
            <dt v-if="batch_info.includes('bar_code')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.barcode_data
                  "
                />
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div
        v-if="event_batch.template == 'Left'"
        class="text-uppercase"
        :style="{
          display: 'flex',
          width: '105mm',
          height: '135mm',
          justifyContent: 'left',
        }"
      >
        <div
          :style="{
            fontFamily: font_face,
            fontSize: name_font_size,
            margin: batch_margin,
          }"
        >
          <dl
            class="text-uppercase"
            :style="{
              padding: batch_margin,
              textAlign: event_batch.template,
            }"
          >
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_name_font_size,

                'margin-bottom': '10px',
                'margin-top': '10px',
              }"
            >
              {{ registrationb.title ? registrationb.title.title : "" }}
              {{ registrationb.full_name }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_design_font_size,

                'margin-bottom': '10px',
                'margin-top': '10px',
              }"
            >
              {{ registrationb.designation }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_company_font_size,

                'margin-bottom': '10px',
                'margin-top': '10px',
              }"
            >
              {{ registrationb.company }}
            </dt>
            <dt v-if="batch_info.includes('qrcode')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.qrcode_data
                  "
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </dt>
            <dt v-if="batch_info.includes('bar_code')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.barcode_data
                  "
                />
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div
        v-if="event_batch.template == 'Center'"
        class="text-uppercase print-beark"
        :style="{
          display: 'flex',
          marginLeft: '18px',
          justifyContent: 'center',
        }"
      >
        <div
          className="print-beark"
          :style="{
            fontFamily: font_face,
            margin: batch_margin,
          }"
        >
          <dl
            class="text-uppercase"
            :style="{
              padding: batch_margin,
              textAlign: event_batch.template,
            }"
          >
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_name_font_size,
                width: '100mm',
                padding: '0 30px',
              }"
            >
              {{ registrationb.title ? registrationb.title.title : "" }}
              {{ registrationb.full_name }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_design_font_size,
                'margin-top': '10px',
                width: '100mm',
                padding: '0 30px',
              }"
            >
              {{ registrationb.designation }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_company_font_size,
                'margin-bottom': '10px',
                'margin-top': '10px',
                width: '100mm',
                padding: '0 30px',
              }"
            >
              {{ registrationb.company }}
            </dt>
            <dt v-if="batch_info.includes('qrcode')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.qrcode_data
                  "
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </dt>
            <dt v-if="batch_info.includes('bar_code')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.barcode_data
                  "
                />
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div
        v-if="event_batch.template == 'Right'"
        class="text-uppercase"
        :style="{
          display: 'flex',
          width: '105mm',
          height: '135mm',
          justifyContent: 'right',
        }"
      >
        <div
          :style="{
            fontFamily: font_face,
            fontSize: name_font_size,
            margin: batch_margin,
          }"
        >
          <dl
            class="text-uppercase"
            :style="{
              padding: batch_margin,
              textAlign: event_batch.template,
            }"
          >
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_name_font_size,
              }"
            >
              {{ registrationb.title ? registrationb.title.title : "" }}
              {{ registrationb.full_name }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_design_font_size,
                'margin-top': '10px',
              }"
            >
              {{ registrationb.designation }}
            </dt>
            <dt
              :style="{
                color: '#000',
                'font-size': event_batch_company_font_size,
                'margin-bottom': '10px',
                'margin-top': '10px',
              }"
            >
              {{ registrationb.company }}
            </dt>
            <dt v-if="batch_info.includes('qrcode')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.qrcode_data
                  "
                  :style="{
                    width: event_batch_barcode_width,
                    height: event_batch_barcode_height,
                  }"
                />
              </div>
            </dt>
            <dt v-if="batch_info.includes('bar_code')">
              <div>
                <img
                  v-bind:src="
                    'data:image/png;base64,' + registrationb.barcode_data
                  "
                />
              </div>
            </dt>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TopHeader from "../components/TopHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    TopHeader,
  },
  data() {
    return {
      isLoading: false,
      isActive: false,
      event: "",
      eventEmpty: false,
      category: "",
      categoryEmpty: false,
      reg_type: "",
      reg_typeEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      events: [],
      event_details: [],
      event_categories: [],
      registrations: [],
      total_registrations: [],

      rowsAndFiltersClicked: false,
      search: "",
      firstDisableStatus: false,
      lastDisableStatus: false,
      rows: 10,
      pageSelected: "",
      currentPage: "",
      lastPage: "",
      totalPages: [],
      buttonVariant: "success",

      eventId: localStorage.getItem("eventId"),
      categoryId: localStorage.getItem("categoryId"),

      event_batch: "",
      batch_margin: "",
      event_batch_category_font_size: "",
      event_batch_city_font_size: "",
      event_batch_company_font_size: "",
      event_batch_design_font_size: "",
      event_batch_regno_font_size: "",
      event_batch_name_font_size: "",
      event_batch_barcode_width: "",
      event_batch_barcode_height: "",
      batch_info: "",
      font_face: "",

      // event_batch: "",
      // batch_margin: "",
      // event_batch_category_font_size: "",
      // event_batch_city_font_size: "",
      // event_batch_company_font_size: "",
      // event_batch_design_font_size: "",
      // event_batch_regno_font_size: "",
      // event_batch_name_font_size: "",
      // event_batch_barcode_width: "",
      // event_batch_barcode_height: "",
      // batch_info: "",
    };
  },
  mounted() {
    this.getEvents();
    this.pagination();
    if (this.eventId) {
      this.printEvent();
    }
  },
  methods: {
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      // window.close();
      location.reload();
    },

    printDivs(divName) {
      console.log("name", divName);
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      location.reload();
    },

    setEvent() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      localStorage.eventId = this.event;
      this.getEventDetails();
    },
    setCategory() {
      if (this.category == "") {
        this.categoryEmpty = true;
        return;
      }
      localStorage.categoryId = this.category;
      console.log(this.category);
      console.log(this.categoryId);
      this.getEventDetails();
    },
    setType() {
      if (this.reg_type == "") {
        this.reg_typeEmpty = true;
        return;
      }
      localStorage.reg_type = this.reg_type;
      console.log(this.reg_type);
      console.log(this.reg_type);
      this.getEventDetails();
    },

    buttonClick(index) {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[index].activeStatus = true;
      let displayedPages = this.totalPages.filter((ele) => ele.display == true);
      if (displayedPages[0] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
          this.totalPages[index + 4].display = false;
        }
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == true
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index + 4].display = false;
        }
      }
      if (displayedPages[1] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
        }
      }
      if (displayedPages[3] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
        }
      }
      if (displayedPages[4] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
          this.totalPages[index - 4].display = false;
        }
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == true
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index - 4].display = false;
        }
      }
      this.pageSelected = this.totalPages[index].id.split(" ")[1];
      this.printEvent();
    },
    last() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[this.totalPages.length - 1].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      if (this.lastPage + 1 > 5) {
        for (var j = 0; j < 5; j++) {
          this.totalPages[this.totalPages.length - 1 - j].display = true;
        }
      } else {
        for (var k = 0; k < this.totalPages.length; k++) {
          this.totalPages[this.totalPages.length - 1 - k].display = true;
        }
      }
      this.pageSelected = this.totalPages.length;
      this.printEvent();
    },
    first() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[0].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      for (var j = 0; j < 5; j++) {
        this.totalPages[j].display = true;
      }
      this.pageSelected = 1;
      this.printEvent();
    },
    previousPage() {
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first - 1].display = true;
      this.totalPages[last].display = false;
      this.totalPages = this.totalPages.map((ele, index, totalArray) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
          totalArray[index - 1].activeStatus = true;
        }
        return ele;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.printEvent();
    },
    nextPage() {
      var nextArrStatus = false;
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first].display = false;
      this.totalPages[last + 1].display = true;
      this.totalPages = this.totalPages.map((ele) => {
        var data = ele;
        if (data.activeStatus == true) {
          data.activeStatus = false;
          nextArrStatus = true;
        } else if (nextArrStatus) {
          data.activeStatus = true;
          nextArrStatus = false;
        }
        return data;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.printEvent();
    },

    pagination() {
      console.log("page");
      if (this.lastPage + 1 > 5) {
        this.totalPages = [];
        for (var i = 1; i < this.lastPage + 1; i++) {
          this.totalPages.push({
            id: `page ${i}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: index < 5,
          activeStatus: index == 0,
        }));
      } else {
        this.totalPages = [];
        for (var j = 1; j < this.lastPage + 1; j++) {
          this.totalPages.push({
            id: `page ${j}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: true,
          activeStatus: index == 0,
        }));
      }
    },

    async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/general-default-list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.default_settings = response.data.default_settings;
            this.event_services = response.data.event_service;
            if (this.eventId != null) {
              this.event = this.eventId;
              this.getEventDetails();
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventDetails() {
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/get-spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_name = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async printEvent() {
      const formdata = new FormData();
      if (this.eventId) {
        formdata.append("event_id", this.eventId);
      }
      if (this.event) {
        formdata.append("event_id", this.event);
      }
      if (this.category) {
        formdata.append("category_id", this.category);
      }
      if (this.reg_type) {
        formdata.append("reg_type", this.reg_type);
      }
      if (this.reg_no) {
        formdata.append("reg_no", this.reg_no);
      }
      this.isLoading = true;
      await axios
        .post(
          `${this.$store.getters.baseUrl}/print-all-batch?page=${this.pageSelected}&row=${this.rows}&search=${this.search}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.registrations = response.data.registrations;
            this.total_registrations = response.data.total_registrations;

            this.event_batch = response.data.batch_info;
            this.batch_info = response.data.batch_info.batch_info;
            this.font_face = response.data.batch_info.font_face;
            this.batch_margin = response.data.batch_info.batch_margin;
            this.event_batch_category_font_size =
              response.data.batch_info.category_font_size + "px";
            this.event_batch_city_font_size =
              response.data.batch_info.city_font_size + "px";
            this.event_batch_company_font_size =
              response.data.batch_info.company_font_size + "px";
            this.event_batch_design_font_size =
              response.data.batch_info.design_font_size + "px";
            this.event_batch_regno_font_size =
              response.data.batch_info.regno_font_size + "px";
            this.event_batch_name_font_size =
              response.data.batch_info.name_font_size + "px";
            this.event_batch_barcode_width =
              response.data.batch_info.barcode_width + "px";
            this.event_batch_barcode_height =
              response.data.batch_info.barcode_height + "px";

            this.lastPage = response.data.pagination.last_page;
            this.currentPage = response.data.pagination.current_page;
            if (this.rowsAndFiltersClicked) {
              this.pagination();
              this.rowsAndFiltersClicked = false;
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    addRole: function () {
      this.isActive = !this.isActive;
    },
    dummy_add_cate: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
.print-beark {
  /* page-break-before: auto; */
  page-break-after: always;
  /* page-break-inside: avoid; */
}
.print-ic {
  background: transparent;
  color: #555;
}
.rit-menu-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@print {
  @page {
    size: A6;
    margin: 0;
  }
  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}

/* page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}*/
/* page[size="A6"] {
  width: 210mm;
  height: 297mm;
} */
@media print {
  body,
  page {
    page-break-before: auto;
  }
}
</style>
