<template>
  <div class="">
    <div class="px-3 mt-3 pb-2">
      <div class="general-page px-4 py-3">
        <div class="d-flex flex-wrap">
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0">Top</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="top"
              />
            </div>
          </div>
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0">Bottom</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="bottom"
              />
            </div>
          </div>
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0">Left</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="left"
              />
            </div>
          </div>
          <div class="mt-2 mb-3 col-12">
            <div class="input-box">
              <label class="mb-0">Right</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="right"
              />
            </div>
          </div>
        </div>

        <h5 class="mt-4">Choose Badge Information:</h5>
        <div class="d-flex flex-wrap">
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="title" />
                Title
              </label>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="name" />
                Name
              </label>
            </div>
            <div class="fs-cust">
              <label>Font Size</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="name_font_size"
              />
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="company" />
                Company
              </label>
            </div>
            <div class="fs-cust">
              <label>Font Size</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="company_font_size"
              />
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="mobile" />
                Mobile No
              </label>
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="designation" />
                Designation
              </label>
            </div>
            <div class="fs-cust">
              <label>Font Size</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="designation_font_size"
              />
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="city" /> City
              </label>
            </div>
            <div class="fs-cust">
              <label>Font Size</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="city_font_size"
              />
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="reg_no" /> Registration No
              </label>
            </div>
            <div class="fs-cust">
              <label>Font Size</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="reg_font_size"
              />
            </div>
          </div>
          <div class="mt-1 mb-5 col-12">
            <div class="single-select col">
              <label class="mb-0">
                <input type="checkbox" v-model="category" />
                Category
              </label>
            </div>
            <div class="fs-cust">
              <label>Font Size</label>
              <input
                type="number"
                v-on:keyup.enter="designBatch()"
                v-model="category_font_size"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="col-12">
            <div class="single-select col">
              <h5 class="mb-0 mt-3">
                <input type="checkbox" v-model="photo" />Photo
              </h5>
            </div>

            <div class="d-flex flex-wrap">
              <div class="mt-3 col-6">
                <div class="input-box">
                  <label class="mb-0">Width</label>
                  <input
                    type="number"
                    v-on:keyup.enter="designBatch()"
                    v-model="photo_width"
                  />
                </div>
              </div>
              <div class="mt-3 col-6">
                <div class="input-box">
                  <label class="mb-0">Height</label>
                  <input
                    type="number"
                    v-on:keyup.enter="designBatch()"
                    v-model="photo_height"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="single-select col">
              <h5 class="mb-0 mt-4">
                <input type="checkbox" v-model="qr_code" />Qrcode
              </h5>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <div class="mt-3 col-6">
                <div class="input-box">
                  <label class="mb-0"> Width</label>
                  <input
                    type="number"
                    v-on:keyup.enter="designBatch()"
                    v-model="bar_code_width"
                  />
                </div>
              </div>
              <div class="mt-3 col-6">
                <div class="input-box">
                  <label class="mb-0"> Height</label>
                  <input
                    type="number"
                    v-on:keyup.enter="designBatch()"
                    v-model="bar_code_height"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-select col">
          <h5 class="mb-0 mt-4">
            <input type="checkbox" v-model="bar_code" />Bar code
          </h5>
        </div>

        <div class="d-flex flex-wrap">
          <div class="input-box col">
            <label class="mb-0">Font Family</label>
            <select name="font_family" id="font_family" v-model="font_family">
              <option value="calibri">calibri</option>
              <option value="open sance">open sance</option>
              <option value="Arial">Arial</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Helvetica">Helvetica</option>
            </select>
          </div>
          <div class="input-box col">
            <label class="mb-0">Choose Template</label>
            <select name="template" id="template" v-model="template">
              <option value="Center">Center</option>
              <option value="Top">Top</option>
              <option value="Left">Left</option>
              <option value="Bottom">Bottom</option>
            </select>
          </div>
          <div class="input-box col">
            <label class="mb-0">Choose Badge Image</label>
            <input type="file" @change="uploadImage($event)" />
          </div>

          <div class="col-12">
            <div v-if="qr_code_data">
              <h5 class="mb-0 mt-0">QR code :</h5>
              <img
                v-bind:src="'data:image/png;base64,' + qr_code_data"
                :style="{
                  width: barcode_width,
                  height: barcode_height,
                }"
              />
            </div>
            <div v-if="barcode_data">
              <img v-bind:src="'data:image/png;base64,' + barcode_data" />
            </div>

            <div class="col-6">
              <div v-if="batch_image_path">
                <div class="input-box ps-2">
                  <h5 class="mb-0 mt-0">Batch Details :</h5>
                </div>
                <img
                  class="bar_code_width bar_code_height"
                  v-bind:src="batch_image_path"
                  :style="{
                    width: batch_photo_width,
                    height: batch_photo_height,
                  }"
                />
              </div>
            </div>

            <div
              class="id-card"
              :style="{
                margin: batch_margin,
                backgroundImage: `url(${batch_image_path})`,
                width: '250px',
                height: '250px',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              }"
            >
              <div
                class="bac d-flex align-items-center justify-content-center text-center"
                :style="{
                  margin: '0 0px 0px 0px',
                  background: 'rgba(0,0,0,0.5)',
                  fontFamily: font_family,
                  'font-size': name_font_size,
                  width: '250px',
                  height: '250px',
                  color: '#fff',
                }"
              >
                <div class="">
                  <h5
                    :style="{
                      'font-size': name_font_size,
                      fontWeight: 'bold',
                      color: '#fff',
                      marginBottom: '10px',
                    }"
                  >
                    Mr. CANDIDATE NAME
                  </h5>

                  <div
                    :style="{
                      width: '80px',
                      height: '80px',
                      borderRadius: '50px',
                      border: '1px solid #000',
                      overflow: 'hidden',
                      background: '#fff',
                      margin: 'auto',
                    }"
                  >
                    <img
                      :style="{ width: '80px', height: '80px' }"
                      src="../../assets/img/user1.png"
                      alt=""
                    />
                  </div>
                  <!-- <img v-if="this.photo"
            class="bar_code_width bar_code_height"
            v-bind:src="batch_image_path"
            :style="{
              'width': batch_photo_width,
              'height': batch_photo_height,
            }"
          /> -->
                  <h5
                    :style="{
                      'font-size': designation_font_size,
                      color: '#fff',
                    }"
                  >
                    ASST. MANAGER
                  </h5>
                  <p :style="{ 'font-size': company_font_size, color: '#fff' }">
                    COMPANY NAME
                  </p>
                  <p>9587458956</p>
                   <img
                  v-if="qr_code_data"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + qr_code_data"
                  :style="{
                    width: barcode_width,
                    height: barcode_height,
                  }"
                />
                <img
                  v-if="bar_code == true"
                  class="bar_code_width bar_code_height"
                  v-bind:src="'data:image/png;base64,' + barcode_data"
                  :style="{
                    width: barcode_width,
                    height: barcode_height,
                  }"
                />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-start me-3">
              <button @click="designBatch" class="submit-btn">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueQrcode from "vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      top: "",
      left: "",
      right: "",
      bottom: "",
      title: false,
      // title_font_size:"",
      name: false,
      name_font_size: "",
      company: false,
      company_font_size: "",
      mobile: false,
      designation: false,
      designation_font_size: "",
      city: false,
      city_font_size: "",
      reg_no: false,
      reg_font_size: "",
      category: false,
      category_font_size: "",
      photo: false,
      photo_width: "",
      photo_height: "",
      bar_code: false,
      bar_code_width: "",
      bar_code_height: "",
      qr_code: false,
      qr_code_width: "",
      qr_code_height: "",
      font_family: "",
      template: "",
      file: "",
      barcode_data: "",
      selectedImage: [],
      qr_code_data: "",
      batch_image_path: "",
      batch_image: "",
      batch_margin: "",
      batch_photo_width: "",
      batch_photo_height: "",
      barcode_width: "",
      barcode_height: "",
    };
  },

  watch: {
    "$route.params.type": {
      handler: function (type) {
        console.log("type", type);
        if (type == "6") {
          this.design();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    uploadImage(event) {
      this.selectedImage = event.target.files[0];
    },

    async design() {
      console.log("design");

      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      await axios
        .post(`${this.$store.getters.baseUrl}/event/batch`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            var batch_info = response.data.event_batch.batch_info;
            this.batch_margin = response.data.event_batch.batch_margin;

            this.batch_image = response.data.event_batch.image;
            this.batch_image_path = response.data.event_batch.batch_image_path;

            //this.barcode_data = response.data.barcode_data;
            this.font_family = response.data.event_batch.font_face;
            this.template = response.data.event_batch.template;

            this.top = response.data.top.replace("px", "");
            this.left = response.data.left.replace("px", "");
            this.right = response.data.right.replace("px", "");
            this.bottom = response.data.bottom.replace("px", "");

            this.event_batch = response.data.event_batch;
            this.name_font_size = this.event_batch.name_font_size;
            this.reg_font_size = this.event_batch.regno_font_size;
            this.designation_font_size = this.event_batch.design_font_size;
            this.company_font_size = this.event_batch.company_font_size;
            this.city_font_size = this.event_batch.city_font_size;
            this.category_font_size = this.event_batch.category_font_size;

            this.photo_width = this.event_batch.photo_width;
            this.photo_height = this.event_batch.photo_height;
            this.qr_code_width = this.event_batch.barcode_width;
            this.qr_code_height = this.event_batch.barcode_height;
            this.bar_code_width = this.event_batch.barcode_width;
            this.bar_code_height = this.event_batch.barcode_height;

            this.batch_photo_width =
              response.data.event_batch.photo_width + "px";
            this.batch_photo_height =
              response.data.event_batch.photo_height + "px";
            this.barcode_width = response.data.event_batch.barcode_width + "px";
            this.barcode_height =
              response.data.event_batch.barcode_height + "px";

            if (batch_info.includes("title")) {
              this.title = true;
            }
            if (batch_info.includes("badgename")) {
              this.name = true;
            }
            if (batch_info.includes("company")) {
              this.company = true;
            }
            if (batch_info.includes("mobile")) {
              this.mobile = true;
            }
            if (batch_info.includes("email")) {
              this.email = true;
            }
            if (batch_info.includes("designation")) {
              this.designation = true;
            }
            if (batch_info.includes("category")) {
              this.category = true;
            }
            if (batch_info.includes("city")) {
              this.city = true;
            }
            if (batch_info.includes("reg_no")) {
              this.reg_no = true;
            }
            if (batch_info.includes("photo")) {
              this.photo = true;
            }
            if (batch_info.includes("qrcode")) {
              this.qr_code_data = response.data.qrCode;
              this.qr_code = true;
            } else if(batch_info.includes("bar_code")){
              this.bar_code = true;
              this.barcode_data = response.data.barcode_data;
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async designBatch() {
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      if (this.title == true) {
        formdata.append("batch_info[]", "title");
      }
      if (this.name == true) {
        formdata.append("batch_info[]", "name");
      }
      if (this.company == true) {
        formdata.append("batch_info[]", "company");
      }
      if (this.mobile == true) {
        formdata.append("batch_info[]", "mobile");
      }
      if (this.designation == true) {
        formdata.append("batch_info[]", "designation");
      }
      if (this.category == true) {
        formdata.append("batch_info[]", "category");
      }
      if (this.city == true) {
        formdata.append("batch_info[]", "city");
      }
      if (this.reg_no == true) {
        formdata.append("batch_info[]", "reg_no");
      }
      if (this.qr_code == true) {
        formdata.append("batch_info[]", "qrcode");
      }
      if (this.photo == true) {
        formdata.append("batch_info[]", "photo");
      }
      if (this.bar_code == true) {
        formdata.append("batch_info[]", "bar_code");
      }
      // if(this.name_font_size == ""){
      //   formdata.append("name_font_size",this.name_font_size);
      // }
      //  if(this.company_font_size == ""){
      //   formdata.append("company_font_size",this.company_font_size);
      // }
      //  if(this.designation_font_size == ""){
      //   formdata.append("design_font_size",this.designation_font_size);
      // }
      //  if(this.reg_font_size == ""){
      //   formdata.append("reg_font_size",this.reg_font_size);
      // }
      //  if(this.category_font_size == ""){
      //   formdata.append("category_font_size",this.category_font_size);
      // }
      //  if(this.photo_height == ""){
      //   formdata.append("photoHeight",this.photo_height);
      // }
      //  if(this.photo_width == ""){
      //   formdata.append("photo_width",this.photo_width);
      // }
      //  if(this.bar_code_height == ""){
      //   formdata.append("barHeight",this.bar_code_height);
      // }
      //  if(this.bar_code_width == ""){
      //   formdata.append("barWidth",this.bar_code_width);
      // }
      //  if(this.qr_code_width == ""){
      //   formdata.append("qr_code_width",this.qr_code_width);
      // }
      //  if(this.qr_code_height == ""){
      //   formdata.append("qr_code_height",this.qr_code_height);
      // }
      //  if(this.font_family == ""){
      //    formdata.append("font_family",this.font_family);
      // }

      formdata.append("top", this.top);
      formdata.append("left", this.left);
      formdata.append("right", this.right);
      formdata.append("bottom", this.bottom);
      formdata.append("template", this.template);
      formdata.append("name_font_size", this.name_font_size);
      formdata.append("company_font_size", this.company_font_size);
      formdata.append("design_font_size", this.design_font_size);
      formdata.append("city_font_size", this.city_font_size);
      formdata.append("reg_font_size", this.reg_font_size);
      formdata.append("category_font_size", this.category_font_size);
      formdata.append("photoHeight", this.photo_height);
      formdata.append("photo_width", this.photo_width);
      formdata.append("barHeight", this.bar_code_height);
      formdata.append("font_family", this.font_family);
      formdata.append("barWidth", this.bar_code_width);
      formdata.append("qr_code_height", this.qr_code_height);
      formdata.append("qr_code_width", this.qr_code_width);

      if (this.selectedImage != null) {
        formdata.append("file", this.selectedImage);
      }

      await axios
        .post(`${this.$store.getters.baseUrl}/event/design-batch`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            var batch_info = response.data.event_batch.batch_info;
            this.barcode_data = response.data.barcode_data;
            if (batch_info.includes("qrcode")) {
              this.qr_code_data = response.data.qrCode;
            } else if(batch_info.includes("bar_code")) {
              this.barcode_data = response.data.barcode_data;
            }
            this.$swal({
              position: "middle",
              icon: "success",
              title: "Event Batch added & Event created successfully",
              timer: 1500,
            });
            // this.$swal(
            //   "Success",
            //   "Event Batch added & Event created successfully",
            //   "success"
            // );
            this.$router.push({ name: "eventlist" });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
