<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="px-2">
          <!-- <input type="text"
              v-on:keyup.enter="savePermissions()"  v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
          <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
        </div>
        <router-link class="add-role" to="/roles" title="Role">
          Back</router-link
        >
      </div>
      <div class="general-page add-role-page px-2 py-3">
        Role Name
        <div class="px-2">
          <input
            type="text"
            v-on:keyup.enter="savePermissions()"
            v-model="roleName"
            class="role-name-input"
            placeholder="Role Name"
          />
          <div v-if="roleNameEmpty" style="color: red">
            Role name is required
          </div>
        </div>

        <div class="d-flex flex-wrap">
          <div
            class="mt-3 col-12 col-md-6 col-lg-4 col-xl-3 px-2"
            v-for="(permission, index) in permissions"
            :key="index"
            switches
            stacked
          >
            <div class="per-missions">
              <div class="all-select d-flex align-items-center">
                <label>{{ permission.module_type }}</label>
              </div>
              <div
                class="single-select mt-1"
                v-for="(can, index1) in permission.permissions"
                :key="index1"
                :value="permissions[index].permissions[index1].id"
              >
                <label>
                  <input
                    class=""
                    :checked="
                      selected.includes(
                        permissions[index].permissions[index1].id
                      )
                        ? true
                        : false
                    "
                    @change="
                      addPermission(permissions[index].permissions[index1])
                    "
                    switches
                    stacked
                    type="checkbox"
                  />{{ can.code }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex px-2 justify-content-end"
          v-if="selectedPermissions != ''"
        >
          <button class="submit-btn" @click="savePermissions()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      loader: "",
      selected: [],
      roleNameEmpty: false,
      roleName: "",
      selectedPermissions: [],
      permissions: [],
    };
  },
  mounted() {
    this.getPermissions();
    this.loadData();
  },
  methods: {
    async loadData() {
      await axios
        .get(`${this.$store.getters.baseUrl}/role/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.roleName = response.data.role.role;
            this.selected = response.data.permissions;
            this.selectedPermissions = response.data.permissions;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    addPermission(permissionData) {
      if (this.selectedPermissions.includes(permissionData.id)) {
        let index = this.selectedPermissions.indexOf(permissionData.id);
        this.selectedPermissions.splice(index, 1);
      } else {
        this.selectedPermissions.push(permissionData.id);
      }
      console.log(this.selectedPermissions);
    },

    async savePermissions() {
      this.loader = true;
      if (this.roleName == "") {
        this.roleNameEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("role", this.roleName);
      formdata.append("_method", "PUT");
      for (var i = 0; i < this.selectedPermissions.length; i++) {
        formdata.append("permissions[" + i + "]", this.selectedPermissions[i]);
      }
      await axios
        .post(
          `${this.$store.getters.baseUrl}/role/${this.$route.params.id}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.loader = false;
            this.$swal(
              "Success",
              "Permission updated for given role successfully",
              "success"
            );
            this.roleName = "";
            this.selectedPermissions = [];
            this.selected = [];
            this.$router.push({ name: "roles" });
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.loader = false;
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getPermissions() {
      await axios
        .get(`${this.$store.getters.baseUrl}/role/create`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.permissions = response.data.modules;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
