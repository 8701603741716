<template>
  <div class="">
    <div class="row">
      <div class="d-flex justify-content-between align-items-center">
        <div class="px-2">
          <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
          <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
        </div>
        <!-- <router-link class="add-role" to="/kiosk-registration" title="Role"> Back</router-link>  -->
        <button class="set-btn" @click="back()">Back</button>
      </div>
      <div class="col-12">
              <!-- <label>QR Code</label> -->
              <img
                v-bind:src="settings.banner_path"
                style="width: 100% !important"
              />
     </div>
     
      <div class="col-md-4"></div>
      <div class="text-center mt-5 col-md-4" id="printableArea">
        <table
          class="table table-bordered"
          style="background: #fff; font-size: 16px"
        >
          <tbody>
            <tr>
              <th>Reg. No.</th>
              <td>{{ reg_info.reg_no }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{{ reg_info.full_name }}</td>
            </tr>
            <tr>
              <th>Mobile No.</th>
              <td>{{ reg_info.mobile }}</td>
            </tr>
            <tr>
              <th>Email ID</th>
              <td>{{ reg_info.email }}</td>
            </tr>
            <tr>
              <th>Company Name</th>
              <td>{{ reg_info.company }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-sm-6 text-center dah-color">
            <button class="btn btn-danger btn-lg">
              <router-link :to="{ name: 'kioskpreregpostupdate' }" style="color: #fff !important;"
                >Edit</router-link
              >
            </button>
          </div>
          <div class="col-sm-6 text-center dah-color">
            <input type="hidden" name="reg_id" value="23" />
            <button class="btn btn-danger btn-lg">
              <router-link :to="{ name: 'kioskprint' }"  style="color: #fff !important;"> Print </router-link>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      isActive: false,
      addCategory: false,
      reg_info: "",
      reg_info_id: "",
      settings: "",
    };
  },
  mounted() {
    this.getAdmins();
    this.getKiosk();
  },
  methods: {
    async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.settings = response.data.settings;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    addRole: function () {
      this.isActive = !this.isActive;
    },
    dummy_add_cate: function () {
      this.isActive = !this.isActive;
    },

    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },

    async getAdmins() {
      const formdata = new FormData();
      formdata.append("reg_no", this.$route.params.reg_no);
      formdata.append("mobile", this.$route.params.reg_no);
      await axios
        .post(`${this.$store.getters.baseUrl}/kiosk-pre-registration`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.reg_info = response.data.reg_info;
            this.reg_info_id = response.data.reg_info.id;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async back() {
      // this.$router.push({name: 'registrationkiosok'});
      window.location.href = "/#/kiosk-registration";
      window.location.reload();
    },
  },
};
</script>
