<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="px-2">
          <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
          <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
        </div>
        <router-link class="add-role" to="/titles" title="Role">
          Back</router-link
        >
      </div>
      <div class="general-page px-4 py-3">
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Title</label>
              <input
                type="text"
                v-on:keyup.enter="save()"
                v-model="title"
                @focus="titleEmpty = false"
                placeholder="example (Arun)"
              />
              <div v-if="titleEmpty" style="color: red">Title is required</div>
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <!-- <button class="submit-btn">Submit</button> -->
            <button class="submit-btn" variant="success" @click="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      title: "",
      titleEmpty: false,
    };
  },
  methods: {
    async save() {
      if (this.title == "") {
        this.titleEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("title", this.title);
      await axios
        .post(`${this.$store.getters.baseUrl}/titles`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Title added successfully", "success");
            this.$router.push({ name: "titles" });
            this.title = "";
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
