<template>
  <div class="">
    <TopHeader />

    <div
      class="ps-3 mt-3 d-flex nex-page-scan"
      v-if="
        default_setting &&
        $store.getters.adminDetail.includes('CAN-SCAN ENTRY DEFAULT-SCAN ENTRY')
      "
    >
      <router-link
        v-if="
          $store.getters.adminDetail.includes(
            'CAN-SCAN KITBAG MAGAZINE CERTIFICATE-SCAN ENTRY'
          )
        "
        class="ms-2"
        to="/magazin-entry"
      >
        Kid / Certificate / Magazine Entry
      </router-link>
      <router-link
        v-if="
          $store.getters.adminDetail.includes('CAN-SCAN HALL ENTRY-SCAN ENTRY')
        "
        class="ms-2"
        to="/hall-entry"
        >Hall Entry
      </router-link>
      <router-link
        v-if="
          $store.getters.adminDetail.includes('CAN-SCAN HALL EXIT-SCAN ENTRY')
        "
        class="ms-2"
        to="/hall-exit"
        >Hall Exit
      </router-link>
      <router-link
        v-if="$store.getters.adminDetail.includes('CAN-SCAN FOOD-SCAN ENTRY')"
        class="ms-2"
        to="/food-entry"
        >Food / Services Entry
      </router-link>

      <!-- <a href="/magazin-entry">Kid / Certificate / Magazine Entry</a> -->
      <!-- <a class="ms-2" href="/hall-entry">Hall Entry</a>
      <a class="ms-2" href="/hall-exit">Hall Exit</a>
      <a class="ms-2" href="/food-entry">Food / Services Entry</a> -->
    </div>
    <div class="ps-3 mt-3 d-flex nex-page-scan" v-else>
      <router-link
        v-if="
          $store.getters.adminDetail.includes(
            'CAN-SCAN KITBAG MAGAZINE CERTIFICATE-SCAN ENTRY'
          )
        "
        class="ms-2"
        to="/general"
        >Kid / Certificate / Magazine Entry
      </router-link>
      <router-link
        v-if="
          $store.getters.adminDetail.includes('CAN-SCAN HALL ENTRY-SCAN ENTRY')
        "
        class="ms-2"
        to="/general"
        >Hall Entry
      </router-link>
      <router-link
        v-if="
          $store.getters.adminDetail.includes('CAN-SCAN HALL EXIT-SCAN ENTRY')
        "
        class="ms-2"
        to="/general"
        >Hall Exit
      </router-link>
      <router-link
        v-if="$store.getters.adminDetail.includes('CAN-SCAN FOOD-SCAN ENTRY')"
        class="ms-2"
        to="/general"
        >Food / Services Entry
      </router-link>
      <!-- <a href="/general">Kid / Certificate / Magazine Entry</a>
      <a class="ms-2" href="/general">Hall Entry</a>
      <a class="ms-2" href="/general">Hall Exit</a>
      <a class="ms-2" href="/general">Food / Services Entry</a> -->
    </div>
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0">Make It Default</h5>
          <button class="add-role" type="submit" @click="setDefault()">
            Set Default
          </button>
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Event</label>
              <select
                v-model="event"
                @change="getEventDetails()"
                @focus="(eventEmpty = false), getEvents()"
                required
              >
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Day</label>
              <select
                v-if="default_setting"
                v-model="day"
                @focus="dayEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in event_name.total_days"
                  :key="index"
                  :value="event"
                >
                  Day-{{ event }}
                </option>
                <!-- <option :value="default_setting.days" > Day-{{ default_setting.days }} </option> -->
              </select>
              <select v-else v-model="day" @focus="dayEmpty = false" required>
                <option
                  v-for="(event, index) in events_details.total_days"
                  :key="index"
                  :value="event"
                >
                  Day-{{ event }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Session</label>
              <select
                v-if="default_setting"
                v-model="session"
                @focus="sessionEmpty = false"
                required
              >
                <!-- <option 
                  :value="default_setting.session"
                >
                  Session-{{ default_setting.session }}
                </option> -->
                <option
                  v-for="(event, index) in event_name.max_session_per_day"
                  :key="index"
                  :value="event"
                >
                  Session-{{ event }}
                </option>
              </select>
              <select
                v-else
                v-model="session"
                @focus="sessionEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in events_details.max_session_per_day"
                  :key="index"
                  :value="event"
                >
                  Session-{{ event }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Service Entry </label>

              <!-- <select v-if="default_setting" v-model="event_type" @focus="event_typeEmpty = false" required>
                <option :value="default_setting.entry_type"> {{ default_setting.entry_type }}  </option> 
                 <option v-if="default_setting.kitbag == 1" value="kitbag"> kitbag  </option>
                 <option v-if="default_setting.magazine == 1" value="magazine"> magazine  </option>
                 <option v-if="default_setting.certificates == 1" value="certificates"> certificates  </option>
              </select>
              <select v-else v-model="event_type" name="event_name.type" @focus="event_typeEmpty = false" required>
                 <option v-for="(event, index) in events_details.total_hall_entry" :key="index" :value="event"> HAll-{{ event }} </option>
                  <option v-if="events_details.kitbag == 1" value="kitbag"> kitbag  </option>
                 <option v-if="events_details.magazine == 1" value="magazine"> magazine  </option>
                 <option v-if="events_details.certificates == 1" value="certificates"> certificates  </option>                 
              </select> -->

              <select
                v-if="default_setting"
                v-model="event_type"
                @focus="event_typeEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in events_details.total_hall_entry"
                  :key="index"
                  :value="'Hall-' + event"
                >
                  Hall-{{ event }}
                </option>
                <option v-if="events_details.kitbag == 1" value="kitbag">
                  kitbag
                </option>
                <option v-if="events_details.magazine == 1" value="magazine">
                  magazine
                </option>
                <option
                  v-if="events_details.certificates == 1"
                  value="certificates"
                >
                  certificates
                </option>
                <option
                  v-if="event_services.break_fast == 1"
                  value="break_fast"
                >
                  break_fast
                </option>
                <option v-if="event_services.lunch == 1" value="lunch">
                  lunch
                </option>
                <option v-if="event_services.dinner == 1" value="dinner">
                  dinner
                </option>
              </select>
              <select
                v-else
                v-model="event_type"
                @focus="event_typeEmpty = false"
                required
              >
                <option
                  v-for="(event, index) in events_details.total_hall_entry"
                  :key="index"
                  :value="'Hall-' + event"
                >
                  Hall-{{ event }}
                </option>
                <option v-if="events_details.kitbag == 1" value="kitbag">
                  kitbag
                </option>
                <option v-if="events_details.magazine == 1" value="magazine">
                  magazine
                </option>
                <option
                  v-if="events_details.certificates == 1"
                  value="certificates"
                >
                  certificates
                </option>
                <option
                  v-if="event_services.break_fast == 1"
                  value="break_fast"
                >
                  break_fast
                </option>
                <option v-if="event_services.lunch == 1" value="lunch">
                  lunch
                </option>
                <option v-if="event_services.dinner == 1" value="dinner">
                  dinner
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end me-2">
          <button class="set-btn" @click="setDefault()">Set Default</button>
        </div>
      </div>
    </div>
    <div class="ps-3 mt-3">
      <div class="general-page d-flex flex-wrap px-2 py-3">
        <div class="col-sm-6 col-lg-4 col-xl-3 px-1 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <h5>Spot Register</h5>
            <button
              class="ms-2 add-role mb-2"
              @click="printDiv('printableArea')"
            >
              <i class="fa-solid fa-print"></i>
            </button>
          </div>
          <div class="" id="printableArea">
            <table class="table-page">
              <tr class="w-100">
                <th>SNO</th>
                <th>Category</th>
                <th>Total</th>
              </tr>
              <tr v-if="spot_registrations.length == 0">
                <td colspan="15" class="text-center">No records found</td>
              </tr>
              <tr
                v-else
                v-for="(registration, index) in spot_registrations"
                :key="index"
              >
                <td class="">{{ registration.sno }}</td>
                <td>{{ registration.category_name }}</td>
                <td>{{ registration.category_count }}</td>
              </tr>
              <tr>
                <td></td>
                <td><b>Total Count</b></td>
                <td v-if="spot_registrations != null">
                  {{ total_count_spot_registration }}
                </td>
                <td v-else>-</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-3 px-1 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <h5>Registered</h5>
            <button
              class="ms-2 add-role mb-2"
              @click="printDiv1('printableArea1')"
            >
              <i class="fa-solid fa-print"></i>
            </button>
          </div>
          <div class="" id="printableArea1">
            <table class="table-page">
              <tr class="w-100">
                <th>SNO</th>
                <th>Category</th>
                <th>Total</th>
              </tr>
              <tr v-if="registrations.length == 0">
                <td colspan="15" class="text-center">No records found</td>
              </tr>
              <tr
                v-else
                v-for="(registration, index) in registrations"
                :key="index"
              >
                <td class="">{{ registration.sno }}</td>
                <td>{{ registration.category_name }}</td>
                <td>{{ registration.category_count }}</td>
              </tr>
              <tr>
                <td></td>
                <td><b>Total Count</b></td>
                <td v-if="spot_registrations != null">
                  {{ total_count_registration }}
                </td>
                <td v-else>-</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-3 px-1 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <h5>Kitbag Count</h5>
            <button
              class="ms-2 add-role mb-2"
              @click="printDiv2('printableArea2')"
            >
              <i class="fa-solid fa-print"></i>
            </button>
          </div>
          <div class="" id="printableArea2">
            <table class="table-page">
              <tr class="w-100">
                <th>SNO</th>
                <th>Category</th>
                <th>Total</th>
              </tr>
              <tr v-if="service_details.length == 0">
                <td colspan="15" class="text-center">No records found</td>
              </tr>
              <tr
                v-else
                v-for="(registration, index) in service_details"
                :key="index"
              >
                <td class="">{{ registration.sno }}</td>
                <td>{{ registration.category_name }}</td>
                <td>{{ registration.category_count }}</td>
              </tr>
              <tr>
                <td></td>
                <td><b>Total Count</b></td>
                <td v-if="spot_registrations != null">
                  {{ total_count_service_details }}
                </td>
                <td v-else>-</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      event: "",
      eventEmpty: false,
      event_type: "",
      event_typeEmpty: false,
      day: "",
      dayEmpty: false,
      session: "",
      sessionEmpty: false,
      events: [],
      event_categories: [],
      default_setting: "",
      event_name: "",
      event_service: "",
      service_details: [],
      registrations: [],
      spot_registrations: [],
      event_services: "",
      events_details: "",
      eventId: localStorage.getItem("eventId"),
    };
  },

  mounted() {
    this.getEvents();
    this.scanEntryLists();
  },
  methods: {
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.href = "/#/scan";
      this.$router.go();
    },
    printDiv1(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.href = "/#/scan";
      this.$router.go();
    },
    printDiv2(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.href = "/#/scan";
      this.$router.go();
    },

    async getEvents() {
      await axios
        .get(`${this.$store.getters.baseUrl}/general-default-list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.default_setting = response.data.default_settings;
            if (this.default_setting) {
              this.event = this.default_setting.event_id;
              this.getEventDetails();
              this.getEventsDetail();
              this.event_type = this.default_setting.entry_type;
              this.day = this.default_setting.days;
              this.session = this.default_setting.session;
            }
            this.event_service = response.data.event_service;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventsDetail() {
      console.log(this.event);
      await axios
        .get(
          `${this.$store.getters.baseUrl}/general-default-data/${this.event}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events_details = response.data.events;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventDetails() {
      const formdata = new FormData();
      console.log(this.event);
      formdata.append("event_id", this.event);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/get-spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_name = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async setDefault() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      if (this.event_type == "") {
        this.event_typeEmpty = true;
        return;
      }
      if (this.day == "") {
        this.dayEmpty = true;
        return;
      }
      if (this.session == "") {
        this.sessionEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      formdata.append("entry_type", this.event_type);
      formdata.append("day", this.day);
      formdata.append("session", this.session);
      await axios
        .post(`${this.$store.getters.baseUrl}/general-default-add`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Default setting successfully", "success");
            this.$router.push({ name: "scan" });
            this.$router.go();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async scanEntryLists() {
      await axios
        .get(`${this.$store.getters.baseUrl}/scan-entry-list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.spot_registrations = response.data.spot_registrations;
            this.registrations = response.data.registrations;
            this.service_details = response.data.service_details;
            this.event_services = response.data.event_services;
            this.total_count_registration =
              response.data.total_count_registration;
            this.total_count_service_details =
              response.data.total_count_service_details;
            this.total_count_spot_registration =
              response.data.total_count_spot_registration;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
