<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2">
            <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
            <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
          </div>
          <!-- <router-link class="add-role" to="/kiosk-registration" title="Role">
            Back</router-link
          > -->
          <button class="set-btn" @click="back()">Back</button>
        </div>

        <div class="col-12">
          <!-- <label>QR Code</label> -->
          <img
            v-bind:src="settings.banner_path"
            style="width: 100% !important"
          />
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Add Spot Registration</h5>
          <!-- <router-link class="add-role" to="/titles" title="Event Report">
            Manage Title
          </router-link> -->
        </div>

        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for=""> Name</label>
              <input
                type="text"
                v-model="spot_name"
                readonly
                @focus="spotnameEmpty = false"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Mobile</label>
              <input
                type="number"
                readonly
                v-model="mobile"
                @focus="mobileEmpty = false"
                maxlength="10"
              />
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Email</label>
              <input
                type="email"
                v-on:keyup.enter="updateRegistration()"
                v-model="email"
                @focus="emailEmpty = false"
              />
            </div>
            <div v-if="emailEmpty" style="color: red">Email is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Company Name</label>
              <input
                type="text"
                v-model="company"
                v-on:keyup.enter="updateRegistration()"
                @focus="companyEmpty = false"
              />
            </div>
            <div v-if="companyEmpty" style="color: red">
              Company Name is required
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <button class="submit-btn" v-on:click="updateRegistration()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      spot_name: "",
      spotnameEmpty: false,
      company: "",
      companyEmpty: false,
      designation: "",
      designationEmpty: false,
      category: "",
      categoryEmpty: false,
      source: "",
      sourceEmpty: false,
      mobile: "",
      mobileEmpty: false,
      email: "",
      emailEmpty: false,
      title: "",
      titleEmpty: false,
      event_detail: "",
      event_detailEmpty: false,
      event_title: "",
      event_titleEmpty: false,
      events: [],
      event: "",
      eventEmpty: false,
      event_details: [],
      event_name: "",
      event_nameEmpty: false,
      event_categories: [],
      event_titles: [],
      event_names: [],
      settings: "",
      eventId: localStorage.getItem("eventId"),
      categoryId: localStorage.getItem("categoryId"),
    };
  },

  mounted() {
    this.getRegistrations();
    this.getKiosk();
  },
  methods: {
    async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.settings = response.data.settings;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getRegistrations() {
      const formdata = new FormData();
      formdata.append("reg_id", this.$route.params.id);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/kiosk-pre-registration-edit`,
          formdata
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.spot_name = response.data.reg_info.full_name;
            this.mobile = response.data.reg_info.mobile;
            this.email = response.data.reg_info.email;
            this.company = response.data.reg_info.company;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async viewRegistration() {
      const formdata = new FormData();
      formdata.append("reg_id", this.$route.params.id);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/kiosk-pre-registration-edit`,
          formdata
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.reg_info = response.data.reg_info;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async updateRegistration() {
      if (this.company == "") {
        this.companyEmpty = true;
        return;
      }
      if (this.email == "") {
        this.emailEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("reg_id", this.$route.params.id);
      formdata.append("company", this.company);
      formdata.append("email", this.email);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/kiosk-pre-registration-Update`,
          formdata
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal(
              "Success",
              "Spot Registration updated successfully",
              "success"
            );
            this.$router.push({ name: "registrationkiosok" });
            window.location.href = "/#/registration-kiosk";
            window.location.reload();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async back() {
      // this.$router.push({name: 'registrationkiosok'});
      window.location.href = "/#/kiosk-registration";
      window.location.reload();
    },
  },
};
</script>
