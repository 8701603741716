<template>
  <html>
    <!-- <page size="A6"> -->
    <!-- <div class="table-responsive text-center  text-uppercase" v-bind:style="{margin:batch_margin, backgroundImage: 'url(' + event_batch.batch_image_path + ')', 'background-size': '100% 100%', width: '378px'}"  id="printableArea"> -->
    <div
      class="table-responsive text-center text-uppercase"
      id="printableArea"
      v-bind:style="{
        margin: batch_margin,
        justifyContent: event_batch.template,
        display: 'flex',
      }"
    >
      <div
        v-if="event_batch.template == 'Left'"
        :style="{
          fontFamily: font,
          'font-size': name_font_size,
          width: '378px',
          padding: '15px 0px 15px 15px',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }"
      >
        <!-- <div :style="{
            fontFamily:font,
            'font-size':name_font_size, width: '378px', padding:'15px 0px 15px 15px', wordWrap:'break-word', wordBreak:'break-word',overflowWrap:'break-word',
            }"> -->
        <dl
          class="text-uppercase"
          :style="{ padding: batch_margin, textAlign: event_batch.template }"
        >
          <dt :style="{ 'font-size': event_batch_name_font_size }">
            {{ title ? title.title : "" }} {{ registration.full_name }}
          </dt>
          <!-- <div :style="{width:event_batch.photo_width+'px', height:event_batch.photo_height+'px', borderRadius:'50px', border:'1px solid #000', overflow:'hidden', background:'#fff', margin:'auto'}">
            <img :style="{width: event_batch.photo_width+'px', height : event_batch.photo_height+'px'}" :src="event_batch.batch_image_path" /> 
            </div> -->
          <dt :style="{ 'font-size': event_batch_design_font_size }">
            {{ registration.designation }}
          </dt>
          <dt :style="{ 'font-size': event_batch_company_font_size }">
            {{ registration.company }}
          </dt>
          <dt v-if="qrCode != null && batch_info.includes('qrcode')">
            <div>
              <img
                v-bind:src="'data:image/png;base64,' + qrCode"
                :style="{
                  width: event_batch_barcode_width,
                  height: event_batch_barcode_height,
                }"
              />
            </div>
          </dt>
          <dt v-if="barcode_data != null && batch_info.includes('bar_code')">
            <div>
              <img v-bind:src="'data:image/png;base64,' + barcode_data" />
            </div>
          </dt>
        </dl>
      </div>

      <div
        v-else-if="event_batch.template == 'Right'"
        :style="{
          fontFamily: font,
          'font-size': name_font_size,
          width: '378px',
          padding: '15px 0px 15px 15px',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }"
      >
        <dl
          class="text-uppercase"
          :style="{ padding: batch_margin, textAlign: event_batch.template }"
        >
          <dt :style="{ 'font-size': event_batch_name_font_size }">
            {{ title ? title.title : "" }} {{ registration.full_name }}
          </dt>
          <dt :style="{ 'font-size': event_batch_design_font_size }">
            {{ registration.designation }}
          </dt>
          <dt :style="{ 'font-size': event_batch_company_font_size }">
            {{ registration.company }}
          </dt>
          <dt v-if="qrCode != null && batch_info.includes('qrcode')">
            <div>
              <img
                v-bind:src="'data:image/png;base64,' + qrCode"
                :style="{
                  width: event_batch_barcode_width,
                  height: event_batch_barcode_height,
                }"
              />
            </div>
          </dt>
          <dt v-if="barcode_data != null && batch_info.includes('bar_code')">
            <div>
              <img v-bind:src="'data:image/png;base64,' + barcode_data" />
            </div>
          </dt>
        </dl>
      </div>

      <div
        v-else-if="event_batch.template == 'Center'"
        :style="{
          fontFamily: font,
          'font-size': name_font_size,
          width: '378px',
          padding: '15px 0px 15px 15px',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          margin: '0 auto 0 auto',
        }"
      >
        <dl
          class="text-uppercase"
          :style="{ padding: batch_margin, textAlign: event_batch.template }"
        >
          <dt :style="{ 'font-size': event_batch_name_font_size }">
            {{ title ? title.title : "" }} {{ registration.full_name }}
          </dt>
          <dt :style="{ 'font-size': event_batch_design_font_size }">
            {{ registration.designation }}
          </dt>
          <dt :style="{ 'font-size': event_batch_company_font_size }">
            {{ registration.company }}
          </dt>
          <dt v-if="qrCode != null && batch_info.includes('qrcode')">
            <div>
              <img
                v-bind:src="'data:image/png;base64,' + qrCode"
                :style="{
                  width: event_batch_barcode_width,
                  height: event_batch_barcode_height,
                }"
              />
            </div>
          </dt>
          <dt v-if="barcode_data != null && batch_info.includes('bar_code')">
            <div>
              <img v-bind:src="'data:image/png;base64,' + barcode_data" />
            </div>
          </dt>
        </dl>
      </div>
    </div>
    <!-- <div> <vue-qrcode v-bind:value="barcode_data" /></div> -->
    <div class="text-uppercase">
      <button
        :style="{
          width: '80px',
          height: '28px',
          fontSize: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 0,
          background: '#a11c20',
          color: '#fff',
          marginLeft: '15px',
        }"
        @click="printDiv('printableArea')"
      >
        PRINT
      </button>
    </div>
    <!-- </page> -->
  </html>
</template>
<style>
/* page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
page[size="A6"] {
  width: 105mm;
  height: 135mm;
}
@media print {
  body,
  page[size="A6"] {
    width: 105mm;
    height: 135mm;
  }
} */
</style>

<script>
// @ is an alias to /src
import axios from "axios";
import VueQrcode from "vue-qrcode";
export default {
  name: "HomeView",
  el: "#app",
  components: {
    VueQrcode,
  },
  data() {
    return {
      event_batch: "",
      registration: "",
      barcode_data: "",
      qrCode: "",
      title: "",
      batch_margin: "",
      output: null,
      barcodeValue: "test",
      event_batch_name_font_size: "",
      batch_info: "",
    };
  },

  mounted() {
    this.getPrints();
  },

  //   watch: {
  //  '$route': {
  //       handler: function() {
  //         this.getPrints();
  //           if(this.registration){
  //             this.printDiv('printableArea');
  //           }
  //       },
  //       deep: true,
  //       immediate: true
  //     }
  //   },

  // watch: {
  //    batch_info: function(val){
  //          var printContents = document.getElementById('printableArea').innerHTML;
  //       var originalContents = document.body.innerHTML;
  //       document.body.innerHTML = printContents;
  //       window.print();
  //       document.body.innerHTML = originalContents;
  //       window.location.href = "/#/printer";
  //       this.$router.go();
  //    }
  // },
  methods: {
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.href = "/#/printer";
      this.$router.go();
    },
    async getPrints() {
      const formdata = new FormData();
      formdata.append("registration_id", this.$route.params.id);
      await axios
        .post(`${this.$store.getters.baseUrl}/print-registration`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_batch = response.data.event_batch;
            this.batch_info = response.data.event_batch.batch_info;
            this.batch_margin = response.data.event_batch.batch_margin;
            this.event_batch_category_font_size =
              response.data.event_batch.category_font_size + "px";
            this.event_batch_city_font_size =
              response.data.event_batch.city_font_size + "px";
            this.event_batch_company_font_size =
              response.data.event_batch.company_font_size + "px";
            this.event_batch_design_font_size =
              response.data.event_batch.design_font_size + "px";
            this.event_batch_regno_font_size =
              response.data.event_batch.regno_font_size + "px";
            this.event_batch_name_font_size =
              response.data.event_batch.name_font_size + "px";
            this.event_batch_barcode_width =
              response.data.event_batch.barcode_width + "px";
            this.event_batch_barcode_height =
              response.data.event_batch.barcode_height + "px";
            this.registration = response.data.registration;
            this.title = response.data.registration.title;
            this.barcode_data = response.data.barcode_data;
            this.qrCode = response.data.qrCode;
            console.log(this.event_batch_name_font_size);
            // if(this.registration != null){
            //   this.printDiv('printableArea');
            // }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.rit-menu-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
