<template>
  <div class="">
    <!-- <TopHeader /> -->
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <h5 class="mb-0">Kiosk Settings</h5>
        <div class="d-flex justify-content-between align-items-center">
          <div class="px-2">
            <!-- <input type="text" v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
              <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
          </div>
          <!-- <router-link class="add-role" to="/event-report" title="Role"> Back</router-link>  -->
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Select Conference</label>
              <select v-model="event"   
                   @focus="eventEmpty = false " required>
                <option         
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-3 col-12 col-lg-6">
          <div class="input-box">
            <label for="">Banner</label>
            <input type="file" name="file" v-on:change="uploadFiles" @focus="fileEmpty = false"  />
          </div>
           <img
                v-bind:src="settings.banner_path"
                style="width: 100% !important"
              />
        </div>
        <div class="d-flex justify-content-end me-2">
          <button class="set-btn" @click="goKishok()">KioskRegistration</button>
          <button class="set-btn" style="margin-left: 40px;" @click="setDefault()">Set Default</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      event: "",
      eventEmpty:false,
      file: "",
      fileEmpty:false,
      kiosk_settings: '',
      settings:'',
      events: [],
    };
  },

   mounted() {
    this.getEvents();
    this.getKiosk();
  },
  methods: {  

     async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.settings = response.data.settings;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
     async getEvents() {
      console.log(this.$route.name);
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk-setting`, {
          
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.events = response.data.events;
            this.kiosk_settings = response.data.kiosk_settings;
            if(this.kiosk_settings != null){
              this.event = this.kiosk_settings.event_id;
            }
          } else {
            this.$swal("Error", response.data.message, "warning");
          }          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    
    uploadFiles(event) {
      this.file = event.target.files[0];
    },
    async setDefault() {
      if (this.event == "") {
        this.eventEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      if (this.file) {
        formdata.append("banner", this.file);
      }
      await axios
        .post(`${this.$store.getters.baseUrl}/post-kiosk-setting`, formdata, {
          
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal("Success", "Default setting successfully", "success");
            this.$router.push({name: 'registrationkiosok'});
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async goKishok(){
      this.$router.push({name: 'registrationkiosok'});
    },
    
  }
};
</script>

     