<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page">
        <div
          class="step-wizard d-flex flex-wrap align-items-center justify-content-center mt-3"
        >
          <button
            
            v-bind:class="{ active: isActive1 }"
            class="steps"
          >
            <p>1</p>
            <h6>Basic Details</h6>
          </button>
          <button
            
            v-bind:class="{ active: isActive2 }"
            class="steps"
          >
            <p>2</p>
            <h6>Event Categories</h6>
          </button>
          <button
            class="steps"
            
            v-bind:class="{ active: isActive3 }"
          >
            <p>3</p>
            <h6>Food Privilege</h6>
          </button>
          <button
            class="steps"
            
            v-bind:class="{ active: isActive4 }"
          >
            <p>4</p>
            <h6>Hall Categories</h6>
          </button>

          <button
            class="steps"
            
            v-bind:class="{ active: isActive5 }"
          >
            <p>5</p>
            <h6>Batch Setup</h6>
          </button>

          <button
            class="steps"
            
            v-bind:class="{ active: isActive6 }"
          >
            <p>6</p>
            <h6>Batch Design</h6>
          </button>
        </div>
        <StepWizard1 class="hid-step" v-bind:class="{ step1: isStep1 }" />
        <StepWizard2 class="hid-step" v-bind:class="{ step2: isStep2 }" />
        <StepWizard3 class="hid-step" v-bind:class="{ step3: isStep3 }" />
        <StepWizard4 class="hid-step" v-bind:class="{ step4: isStep4 }" />
        <StepWizard5 class="hid-step" v-bind:class="{ step5: isStep5 }" />
        <StepWizard6 class="hid-step" v-bind:class="{ step6: isStep6 }" />
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import StepWizard1 from "../components/wizard/StepWizard1.vue";
import StepWizard2 from "../components/wizard/StepWizard2.vue";
import StepWizard3 from "../components/wizard/StepWizard3.vue";
import StepWizard4 from "../components/wizard/StepWizard4.vue";
import StepWizard5 from "../components/wizard/StepWizard5.vue";
import StepWizard6 from "../components/wizard/StepWizard6.vue";

export default {
  name: "HomeView",
  components: {
    TopHeader,
    StepWizard2,
    StepWizard1,
    StepWizard3,
    StepWizard4,
    StepWizard5,
    StepWizard6,
  },
  data() {
    return {
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isStep4: false,
      isStep5: false,
      isStep6: false,
      isActive1: true,
       isActive2: false,
        isActive3: false,
         isActive4: false,
          isActive5: false,
          isActive6: false,
    };
  },
  
  mounted(){
    this.RouteCheck();
  },
  watch:{
   '$route.params.type': {
        handler: function(type) {
           console.log(type)
           this.RouteCheck();
        },
        deep: true,
        immediate: true
      }
  },
  methods: {
    
    RouteCheck(){
       console.log(this.$route.path);
      if(this.$route.params.id && this.$route.params.type == 2){
        this.step2();
      }else if(this.$route.params.id && this.$route.params.type == 3){
        this.step3();
      }else if(this.$route.params.id && this.$route.params.type == 4){
        this.step4();
      }else if(this.$route.params.id && this.$route.params.type == 5){
        this.step5();
      }else if(this.$route.params.id && this.$route.params.type == 6){
        this.step6();
      }
    },

    step1: function () {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isStep6 = false;
      this.isActive1 = true;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
    },
    step2: function () {
      this.isStep1 = false;
      this.isStep2 = true;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isStep6 = false;
      this.isActive1 = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
    },
    step3: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = true;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isStep6 = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = true;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
    },
    step4: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = true;
      this.isStep5 = false;
      this.isStep6 = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = true;
      this.isActive5 = false;
      this.isActive6 = false;
    },
    step5: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = true;
      this.isStep6 = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = true;
      this.isActive6 = false;
    },
    step6: function () {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isStep6 = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = true;
    },
  },
};
</script>
