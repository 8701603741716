<template>
  <div class="d-md-flex tot-body">
    <div
      class="p-2 menu-bar-page"
      v-if="
        this.$store.state.token &&
        this.$route.name != 'kiosk' &&
        this.$route.name != 'signIn' &&
        this.$route.name != 'kioskspotregistration' &&
        this.$route.name != 'kiosksetting' &&
        this.$route.name != 'registrationkiosok' &&
        this.$route.name != 'kiosokpreregpost' &&
        this.$route.name != 'kioskpreregpostupdate' &&
        this.$route.name != 'kioskspotregistrationlist' &&
        this.$route.name != 'kioskprint'
      "
    >
      <div class="left-menu-bar">
        <div class="top-menu d-flex justify-content-center">
          <div class="main-logo">
            <router-link to="/dashboard" title="Home">
              <img class="b-logo" src="./assets/img/b-logo.png" alt="" />
              <img class="logo" src="./assets/img/logo.jpg" alt="" />
            </router-link>
          </div>
          <!-- <button class="menu-btn" @click="showMobileMenu = !showMobileMenu">
          <div class="">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line3"></div>
          </div>
        </button> -->
        </div>
        <ul :class="{ new: showMobileMenu }" class="left-nav">
          <!-- <li>
            <router-link to="/" title="Home">
              <img src="./assets/img/dashboard.png" />
            </router-link>
          </li> -->
          <li v-if="$store.getters.adminDetail.includes('CAN-ADD-GENERAL')">
            <router-link to="/general" title="Home">
              <img src="./assets/img/home.png" />
              <p>General</p>
            </router-link>
          </li>
          <li v-if="$store.getters.adminDetail.includes('CAN-LIST-ROLES')">
            <router-link to="/roles" title="Role">
              <img src="./assets/img/role.png" />
              <p>Role</p>
            </router-link>
          </li>
          <li v-if="$store.getters.adminDetail.includes('CAN-LIST-USERS')">
            <router-link to="/users" title="User">
              <img src="./assets/img/user.png" />
              <p>Users</p>
            </router-link>
          </li>
          <li v-if="$store.getters.adminDetail.includes('CAN-LIST-CATEGORY')">
            <router-link to="/category" title="Category">
              <img src="./assets/img/category.png" />
              <p>Category</p>
            </router-link>
          </li>
          <li v-if="$store.getters.adminDetail.includes('CAN-LIST-EVENT')">
            <router-link to="/event-list" title="Event List">
              <img src="./assets/img/add-event.png" />
              <p>Event List</p>
            </router-link>
          </li>
          <li
            v-if="$store.getters.adminDetail.includes('CAN-LIST-MANAGE EVENTS')"
          >
            <router-link to="/manage-event" title="Manage Events">
              <img src="./assets/img/manage-event.png" />
              <p>Manage Events</p>
            </router-link>
          </li>
          <li
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-REGISTRATION-REGISTRATION'
              )
            "
          >
            <router-link to="/registration" title="Regitration">
              <img src="./assets/img/regist.png" />
              <p>Registration</p>
            </router-link>
          </li>
          <li
            v-if="$store.getters.adminDetail.includes('CAN-LIST-PRINT BADGE')"
          >
            <router-link to="/printer" title="Print Badge">
              <img src="./assets/img/printer.png" />
              <p>Print Badge</p>
            </router-link>
          </li>
          <li
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-SCAN ENTRY DEFAULT-SCAN ENTRY'
              )
            "
          >
            <router-link to="/scan" title="Scan Entry">
              <img src="./assets/img/scan.png" />
              <p>Scan Entry</p>
            </router-link>
          </li>
          <li
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-EVENT REPORT DEFAULT-EVENT REPORT'
              )
            "
          >
            <router-link to="/event-report" title="Event Report">
              <img src="./assets/img/report.png" />
              <p>Event Report</p>
            </router-link>
          </li>
          <li>
            <router-link to="/kiosk" title="Kiosk">
              <img src="./assets/img/kiosk.png" />
              <p>Kiosk</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-view class="col rit-menu-body ps-0 p-2" />
  </div>
</template>

<style>
@import "./assets/css/style.css";
@import "./assets/css/responsive.css";
@import "./assets/css/keyframe.css";
@import "./assets/fontawsome/css/all.css";
</style>

<script>
export default {
  data() {
    return { showMobileMenu: false, token: localStorage.getItem("token") };
  },
  mounted() {
    this.store();
  },
  methods: {
    async store() {
      this.token = localStorage.getItem("token");
      // console.log("url", this.$router.name);
    },
  },
};
</script>
