<template>
  <div class="vld-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <TopHeader />
    <div
      class="ps-3 mt-3"
      v-if="
        $store.getters.adminDetail.includes(
          'CAN-EVENT REPORT DEFAULT-EVENT REPORT'
        )
      "
    >
      <div class="general-page px-3 py-3">
        <div class="event-select">
          <div class="d-flex flex-wrap">
            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-xxl-1">
              <p class="choose-evnet">Select Conference</p>

              <select
                v-if="this.eventId != null"
                v-model="event"
                @change="getEventDetails()"
                @focus="eventEmpty = false"
              >
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
              <select
                v-else
                v-model="event"
                @change="getEventDetails()"
                @focus="eventEmpty = false"
              >
                <option
                  v-for="(event, index) in events"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>

              <!-- <select name="" id="">
                <option value="">New Event - 1</option>
                <option value="">New Event - 2</option>
              </select> -->
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-xxl-1">
              <p class="choose-evnet">Event Day</p>
              <select
                v-model="day"
                @change="getEventDetails()"
                @focus="dayEmpty = false"
              >
                <option
                  v-for="(event, index) in event_name.total_days"
                  :key="index"
                  :value="event"
                >
                  Day-{{ event }}
                </option>
              </select>
              <!-- <select name="" id="">
                <option value="">New Event - 1</option>
                <option value="">New Event - 2</option>
              </select> -->
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-xxl-1">
              <p class="choose-evnet">Select Category</p>
              <select
                v-model="category"
                @change="getEventDetails()"
                @focus="categoryEmpty = false"
                placeholder="Select Event Name"
              >
                <option
                  v-for="(event, index) in event_categories"
                  :key="index"
                  :value="event.id"
                >
                  {{ event.name }}
                </option>
              </select>
              <!-- <select name="" id="">
                <option value="">New Event - 1</option>
                <option value="">New Event - 2</option>
              </select> -->
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-xxl-1">
              <p class="choose-evnet">Filter By</p>
              <select
                v-model="filter"
                @focus="filterEmpty = false"
                placeholder="Select Event Name"
              >
                <option value="1">Printed</option>
                <option value="false">Not Printed</option>
              </select>
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-lg-1">
              <p class="choose-evnet">Registration Type</p>
              <select v-model="reg_type" @focus="reg_typeEmpty = false">
                <option value="Registration">Registration</option>
                <option value="Spot Registration">Spot Registration</option>
                <option value="Kiosk">Kiosk</option>
              </select>
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-lg-1">
              <p class="choose-evnet">Printed From</p>
              <select v-model="printed_from" @focus="printed_fromEmpty = false">
                <option value="crm">CRM</option>
                <option value="kiosk">Kiosk</option>
              </select>
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-sm-1">
              <p class="choose-evnet">Registration No</p>
              <input
                type="text"
                v-on:keyup.enter="eventReportList()"
                v-model="reg_no"
                @focus="reg_noEmpty = false"
              />
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xxl px-1 mb-4 mb-sm-1">
              <p class="choose-evnet">User</p>
              <select v-model="user" @focus="userEmpty = false">
                <option
                  v-for="(user, index) in admin_users_lists"
                  :key="index"
                  :value="user.id"
                >
                  {{ user.username }}
                </option>
              </select>
            </div>
          </div>
          <button
            class="submit-btn ms-1 mt-3"
            @click="
              (pageSelected = 1),
                (rowsAndFiltersClicked = true),
                eventReportList()
            "
          >
            Choose
          </button>
        </div>
      </div>
    </div>
    <div class="ps-3 mt-3">
      <div class="general-page tabel-data-page px-4 py-3">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mt-2"
        >
          <div class="sort d-flex">
            <p class="mb-0">Show</p>
            <select
              class="mx-2 form-control"
              v-model="rows"
              @change="
                (pageSelected = 1),
                  (rowsAndFiltersClicked = true),
                  eventReportList()
              "
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p class="mb-0">Entries</p>
          </div>
          <div class="search-input d-flex align-items-center">
            <!-- <input type="text" placeholder="Search" /> -->
            <input
              class="form-control"
              type="text"
              v-model="search"
              placeholder="Search..."
              aria-label="Search"
              style="width: 300px"
              @keyup="
                (pageSelected = 1),
                  (rowsAndFiltersClicked = true),
                  eventReportList()
              "
            />
            <button
              v-if="
                $store.getters.adminDetail.includes(
                  'CAN-EVENT REPORT PRINT-EVENT REPORT'
                )
              "
              class="print-btn ms-2 mt-0 px-2"
              @click="printDiv('printableArea')"
            >
              <i class="fa-solid fa-print me-2"></i>Print All
            </button>
            <button
              v-if="
                $store.getters.adminDetail.includes(
                  'CAN-EVENT REPORT EXPORT-EVENT REPORT'
                )
              "
              class="download-btn ms-2 mt-0 px-2"
              @click="downloadExcel()"
            >
              <i class="fa-solid fa-download me-2"></i> Excel
            </button>
          </div>
        </div>
        <div class="" id="printableArea">
          <table class="table-page mt-2">
            <tr class="w-100">
              <th>SNO</th>
              <th>Registration No</th>
              <th>Category Name</th>
              <th>Full Name</th>
              <th>Company Name</th>
              <th>Designation</th>
              <th>Mobile No</th>
              <th>Email</th>
              <th>City</th>
              <th>Payment(Rs)</th>
            </tr>
            <tr v-if="registrations.length == 0">
              <td colspan="15" class="text-center">No records found</td>
            </tr>
            <tr
              v-else
              v-for="(registration, index) in registrations"
              :key="index"
            >
              <td>{{ (currentPage - 1) * rows + index + 1 }}</td>
              <td>{{ registration.reg_no }}</td>
              <td v-if="registration.category != null">
                {{ registration.category.name }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.full_name != null">
                {{ registration.full_name }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.company != null">
                {{ registration.company }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.designation != null">
                {{ registration.designation }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.mobile != null">
                {{ registration.mobile }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.email != null">
                {{ registration.email }}
              </td>
              <td v-else>-</td>
              <td v-if="registration.city != null">{{ registration.city }}</td>
              <td v-else>-</td>
              <td v-if="registration.total_payment != null">
                {{ registration.total_payment }}
              </td>
              <td v-else>-</td>
            </tr>
          </table>
        </div>

        <div class="pagi-nation">
          <ul class="d-flex mt-3 mb-0 p-0">
            <li v-if="lastPage != 1 && registrations.length > 0">
              <div
                class="pagi-a left-rit-arrow disabled"
                variant="outline-primary"
                @click="first()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[0].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-double-left"></i>
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                variant="outline-primary"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[0].display
                      ? true
                      : false
                    : false
                "
                @click="previousPage()"
              >
                <i class="fas fa-angle-left"></i>
              </div>
            </li>
            <li class="d-flex">
              <div
                class="pagi-a"
                :pressed="page.activeStatus == true ? true : false"
                v-show="page.display"
                :id="page.id"
                @click="buttonClick(index)"
                size="lg"
                variant="outline-primary"
                v-for="(page, index) in totalPages"
                :key="index"
              >
                {{ index + 1 }}
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                href="#"
                variant="outline-primary"
                @click="nextPage()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[totalPages.length - 1].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-right"></i>
              </div>
            </li>
            <li>
              <div
                class="pagi-a"
                href="#"
                variant="outline-primary"
                @click="last()"
                :disabled="
                  totalPages.length > 0
                    ? totalPages[totalPages.length - 1].display
                      ? true
                      : false
                    : false
                "
              >
                <i class="fas fa-angle-double-right"></i>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="pagi-nation">
          <ul class="d-flex mt-3 mb-0 p-0">
            <li>
              <a class="pagi-a left-rit-arrow" href="#"
                ><i class="fa-solid fa-arrow-left"></i
              ></a>
            </li>
            <li class="active"><a class="pagi-a" href="#">1</a></li>
            <li><a class="pagi-a" href="#">2</a></li>
            <li><a class="pagi-a" href="#">3</a></li>
            <li><a class="pagi-a" href="#">4</a></li>
            <li><a class="pagi-a" href="#">5</a></li>
            <li>
              <a class="pagi-a left-rit-arrow" href="#"
                ><i class="fa-solid fa-arrow-right"></i
              ></a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="modal-upload-img" v-bind:class="{ uploadImg: isActive }">
      <div class="in-modal-upload-img">
        <div class="bg-white px-5 py-4 rounded-1 rounded">
          <div class="form-action">
            <p class="text-dark mb-1">Choose Event</p>
            <input
              class="text-input"
              type="text"
              placeholder="Add Categories"
            />
          </div>
          <div class="form-action mt-2 mb-2">
            <p class="text-dark mb-1">Upload File</p>
            <input class="mt-0 ms-0 p-0" type="file" />
          </div>
          <button class="save-btn">Upload</button>
        </div>
      </div>
      <div @click="addRole" v-bind:class="{ dummy_add_cate: isActive }"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TopHeader from "../components/TopHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    TopHeader,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isActive: false,
      addCategory: false,
      event: "",
      eventEmpty: false,
      category: "",
      categoryEmpty: false,
      filter: "",
      filterEmpty: false,
      reg_type: "",
      reg_typeEmpty: false,
      printed_from: "",
      printed_fromEmpty: false,
      user: "",
      userEmpty: false,
      reg_no: "",
      reg_noEmpty: false,
      day: "",
      dayEmpty: false,
      events: [],
      event_details: [],
      event_categories: [],
      registrations: [],
      event_reports: [],
      registration_lists: [],
      event_lists: [],
      admin_users_lists: [],
      event_name: "",
      eventId: localStorage.getItem("eventId"),
      categoryId: localStorage.getItem("categoryId"),

      totalUsers: 0,
      rowsAndFiltersClicked: false,
      search: "",
      firstDisableStatus: false,
      lastDisableStatus: false,
      rows: 10,
      pageSelected: "",
      currentPage: "",
      lastPage: "",
      totalPages: [],
      buttonVariant: "success",
    };
  },

  mounted() {
    this.getEvents();
    this.reportEventList();
    this.pagination();
  },
  methods: {
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.href = "/#/event-report";
      location.reload();
      // this.$router.go();
    },
    addRole: function () {
      this.isActive = !this.isActive;
    },
    dummy_add_cate: function () {
      this.isActive = !this.isActive;
    },

    buttonClick(index) {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[index].activeStatus = true;
      let displayedPages = this.totalPages.filter((ele) => ele.display == true);
      if (displayedPages[0] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
          this.totalPages[index + 4].display = false;
        }
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == true
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index + 4].display = false;
        }
      }
      if (displayedPages[1] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
        }
      }
      if (displayedPages[3] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
        }
      }
      if (displayedPages[4] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
          this.totalPages[index - 4].display = false;
        }
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == true
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index - 4].display = false;
        }
      }
      this.pageSelected = this.totalPages[index].id.split(" ")[1];
      this.eventReportList();
    },
    last() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[this.totalPages.length - 1].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      if (this.lastPage + 1 > 5) {
        for (var j = 0; j < 5; j++) {
          this.totalPages[this.totalPages.length - 1 - j].display = true;
        }
      } else {
        for (var k = 0; k < this.totalPages.length; k++) {
          this.totalPages[this.totalPages.length - 1 - k].display = true;
        }
      }
      this.pageSelected = this.totalPages.length;
      this.eventReportList();
    },
    first() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[0].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      for (var j = 0; j < 5; j++) {
        this.totalPages[j].display = true;
      }
      this.pageSelected = 1;
      this.eventReportList();
    },
    previousPage() {
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first - 1].display = true;
      this.totalPages[last].display = false;
      this.totalPages = this.totalPages.map((ele, index, totalArray) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
          totalArray[index - 1].activeStatus = true;
        }
        return ele;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.eventReportList();
    },
    nextPage() {
      var nextArrStatus = false;
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first].display = false;
      this.totalPages[last + 1].display = true;
      this.totalPages = this.totalPages.map((ele) => {
        var data = ele;
        if (data.activeStatus == true) {
          data.activeStatus = false;
          nextArrStatus = true;
        } else if (nextArrStatus) {
          data.activeStatus = true;
          nextArrStatus = false;
        }
        return data;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.eventReportList();
    },

    pagination() {
      console.log("page");
      if (this.lastPage + 1 > 5) {
        this.totalPages = [];
        for (var i = 1; i < this.lastPage + 1; i++) {
          this.totalPages.push({
            id: `page ${i}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: index < 5,
          activeStatus: index == 0,
        }));
      } else {
        this.totalPages = [];
        for (var j = 1; j < this.lastPage + 1; j++) {
          this.totalPages.push({
            id: `page ${j}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: true,
          activeStatus: index == 0,
        }));
      }
    },

    async getEvents() {
      this.isLoading = true;
      await axios
        .get(`${this.$store.getters.baseUrl}/general-default-list`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.events = response.data.events;
            this.default_settings = response.data.default_settings;
            this.event_services = response.data.event_service;
            if (this.eventId != null) {
              this.event = this.eventId;
              this.getEventDetails();
            }

            this.isLoading = false;
          } else {
            this.$swal("Error", response.data.message, "warning");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getEventDetails() {
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/get-spot-registration-event`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_name = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.category;
            this.event_titles = response.data.title;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async eventReportList() {
      this.isLoading = true;
      const formdata = new FormData();
      formdata.append("event_id", this.event);
      if (this.day) {
        formdata.append("day", this.day);
      }
      if (this.filter) {
        formdata.append("filter_by", this.filter);
      }
      if (this.category) {
        formdata.append("category_id", this.category);
      }
      if (this.reg_type) {
        formdata.append("reg_type", this.reg_type);
      }
      if (this.reg_no) {
        formdata.append("reg_no", this.reg_no);
      }
      if (this.printed_from) {
        formdata.append("printed_from", this.printed_from);
      }
      if (this.user) {
        formdata.append("user", this.user);
      }
      await axios
        .post(
          `${this.$store.getters.baseUrl}/event-report-filter?page=${this.pageSelected}&row=${this.rows}&search=${this.search}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_reports = response.data.event_reports;
            this.registrations = response.data.registrations;
            this.event_detail = response.data.event_detail;
            this.url = response.data.url;
            this.lastPage = response.data.pagination.last_page;
            this.currentPage = response.data.pagination.current_page;
            // this.pagination();
            if (this.rowsAndFiltersClicked) {
              this.pagination();
              this.rowsAndFiltersClicked = false;
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async reportEventList() {
      this.isLoading = true;
      await axios
        .get(`${this.$store.getters.baseUrl}/event-report`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_lists = response.data.event_reports;
            this.registration_lists = response.data.registrations;
            this.admin_users_lists = response.data.admin_users_list;
            this.isLoading = false;
          } else {
            this.$swal("Error", response.data.message, "warning");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async downloadExcel() {
      if (this.url) {
        this.isLoading = true;
        // window.location.href=this.url;
        const link = document.createElement("a");
        link.href = this.url;
        console.log(this.url);
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        axios
          .get(this.url)
          .then((response) => {
            console.log("success");
            this.$swal(
              "Success",
              "Test report exported successfully",
              "success"
            );
            this.isLoading = false;
          })
          .catch((error) => {
            console.log("error");
            this.$swal(
              "Success",
              "Test report exported successfully",
              "success"
            );
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
