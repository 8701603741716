<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">QR code</label>
                <img v-bind:src="path"  >
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import TopHeader from "../components/TopHeader.vue";
export default {
  name: "HomeView",
  components: { TopHeader },
  data(){
    return {
      path: '',
    };
  },
   mounted() {
    this.getRegistrations();
  },
  methods: {
    async getRegistrations() {
      await axios
        .get(`${this.$store.getters.baseUrl}/event/qr-code-spot-register/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.path = response.data.path;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

