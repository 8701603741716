<template>
  <div class="">
      <loading v-model:active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"/>
    <TopHeader />
    <div class="ps-3 mt-3" v-if="$store.getters.adminDetail.includes('CAN-LIST-ROLES')">
      <div class="general-page tabel-data-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Roles Management</h5>

          <router-link class="add-role" to="/add-role" title="Event Report"
           v-if="$store.getters.adminDetail.includes('CAN-ADD-ROLES')">
            Create Role
          </router-link>
        </div>
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mt-2"
        >
          <div class="sort d-flex">
            <p class="mb-0">Show</p>
            <select  class="mx-2 form-control"
              v-model="rows"
              @change="
                (pageSelected = 1), (rowsAndFiltersClicked = true), getRoles()
              ">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p class="mb-0">Entries</p>
          </div>
          <div class="search-input">
            <!-- <input type="text" placeholder="Search" /> -->
            <input
              class="form-control"
              type="text"
              v-model="search"
              placeholder="Search..."
              aria-label="Search"
              style="width: 300px"
              @keyup="
                (pageSelected = 1),
                  (rowsAndFiltersClicked = true),
                  getRoles()
              "
            />
          </div>
        </div>
        <table class="table-page mt-2">
          <tr class="w-100">
            <th>S.No</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
          <tr v-if="roles.length == 0">
            <td colspan="8" class="text-center"
                >No records found</td
              ></tr
            >
          <tr v-else v-for="(role, index) in roles" :key="index">
            <td>{{ (currentPage - 1) * rows + index + 1}}</td>
            <td>{{ role.role }}</td>
            <td>
              <router-link v-if="$store.getters.adminDetail.includes('CAN-EDIT-ROLES')"
                    :to="{ name: 'Edit Role', params: { id: role.id } }"
                ><i class="fa-solid fa-pencil"></i
              ></router-link>
            </td>
          </tr>
        </table>
        <div class="pagi-nation">
          <ul class="d-flex mt-3 mb-0 p-0">
            <li v-if="lastPage != 1 && roles.length > 0">
              <div class="pagi-a left-rit-arrow disabled" 
              variant="outline-primary"
              @click="first()"
              :disabled="
                totalPages.length > 0
                  ? totalPages[0].display
                    ? true
                    : false
                  : false
              "><i class="fas fa-angle-double-left"></i
            ></div>
            </li>
            <li><div class="pagi-a"
              variant="outline-primary"
              :disabled="
                totalPages.length > 0
                  ? totalPages[0].display
                    ? true
                    : false
                  : false
              "
              @click="previousPage()"><i class="fas fa-angle-left"></i
            ></div></li>
            <li class="d-flex"><div class="pagi-a" 
              :pressed="page.activeStatus == true ? true : false"
              v-show="page.display"
              :id="page.id"
              @click="buttonClick(index)"
              size="lg"
              variant="outline-primary"
              v-for="(page, index) in totalPages"
              :key="index"
              >{{ index + 1 }}</div></li>
            <li><div class="pagi-a" href="#"
              variant="outline-primary"
              @click="nextPage()"
              :disabled="
                totalPages.length > 0
                  ? totalPages[totalPages.length - 1].display
                    ? true
                    : false
                  : false
              "
              ><i class="fas fa-angle-right"></i
            ></div></li>
            <li><div class="pagi-a" href="#"
              variant="outline-primary"
              @click="last()"
              :disabled="
                totalPages.length > 0
                  ? totalPages[totalPages.length - 1].display
                    ? true
                    : false
                  : false
              "
              ><i class="fas fa-angle-double-right"></i
            ></div></li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import TopHeader from "../components/TopHeader.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
   components: {
            Loading,
            TopHeader,
        },
  data() {
     return {
       roles:"",
         isLoading: false,
        fullPage: true,

      totalUsers: 0,
      rowsAndFiltersClicked: false,
      search: "",
      firstDisableStatus: false,
      lastDisableStatus: false,
      rows: 10,
      pageSelected: "",
      currentPage: "",
      lastPage: "",
      totalPages: [],
      buttonVariant: "success",
     };
  },
  mounted: async function () {
    await this.getRoles();
    this.pagination();
  },
  methods:{
    
    buttonClick(index) {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[index].activeStatus = true;
      let displayedPages = this.totalPages.filter((ele) => ele.display == true);
      if (displayedPages[0] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
          this.totalPages[index + 4].display = false;
        }
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == true
        ) {
          this.totalPages[index - 1].display = true;
          this.totalPages[index + 4].display = false;
        }
      }
      if (displayedPages[1] === this.totalPages[index]) {
        if (
          this.totalPages[0].display == false &&
          this.totalPages[1].display == false
        ) {
          this.totalPages[index - 2].display = true;
          this.totalPages[index + 3].display = false;
        }
      }
      if (displayedPages[3] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
        }
      }
      if (displayedPages[4] === this.totalPages[index]) {
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == false
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index + 2].display = true;
          this.totalPages[index - 3].display = false;
          this.totalPages[index - 4].display = false;
        }
        if (
          this.totalPages[this.totalPages.length - 1].display == false &&
          this.totalPages[this.totalPages.length - 2].display == true
        ) {
          this.totalPages[index + 1].display = true;
          this.totalPages[index - 4].display = false;
        }
      }
      this.pageSelected = this.totalPages[index].id.split(" ")[1];
      this.getRoles();
    },
    last() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[this.totalPages.length - 1].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      if (this.lastPage + 1 > 5) {
        for (var j = 0; j < 5; j++) {
          this.totalPages[this.totalPages.length - 1 - j].display = true;
        }
      } else {
        for (var k = 0; k < this.totalPages.length; k++) {
          this.totalPages[this.totalPages.length - 1 - k].display = true;
        }
      }
      this.pageSelected = this.totalPages.length;
      this.getRoles();
    },
    first() {
      this.totalPages = this.totalPages.map((ele) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
        }
        return ele;
      });
      this.totalPages[0].activeStatus = true;
      for (var i = 0; i < this.totalPages.length; i++) {
        this.totalPages[i].display = false;
      }
      for (var j = 0; j < 5; j++) {
        this.totalPages[j].display = true;
      }
      this.pageSelected = 1;
      this.getRoles();
    },
    previousPage() {
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first - 1].display = true;
      this.totalPages[last].display = false;
      this.totalPages = this.totalPages.map((ele, index, totalArray) => {
        if (ele.activeStatus == true) {
          ele.activeStatus = false;
          totalArray[index - 1].activeStatus = true;
        }
        return ele;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.getRoles();
    },
    nextPage() {
      var nextArrStatus = false;
      var displayedPages = this.totalPages.filter((ele) => ele.display == true);
      let first = this.totalPages.indexOf(displayedPages[0]);
      let last = this.totalPages.indexOf(
        displayedPages[displayedPages.length - 1]
      );
      this.totalPages[first].display = false;
      this.totalPages[last + 1].display = true;
      this.totalPages = this.totalPages.map((ele) => {
        var data = ele;
        if (data.activeStatus == true) {
          data.activeStatus = false;
          nextArrStatus = true;
        } else if (nextArrStatus) {
          data.activeStatus = true;
          nextArrStatus = false;
        }
        return data;
      });
      let currentPage = this.totalPages.find((ele) => ele.activeStatus == true);
      this.pageSelected = currentPage.id.split(" ")[1];
      this.getRoles();
    },

    pagination() {
      console.log("page");
      if (this.lastPage + 1 > 5) {
        this.totalPages = [];
        for (var i = 1; i < this.lastPage + 1; i++) {
          this.totalPages.push({
            id: `page ${i}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: index < 5,
          activeStatus: index == 0,
        }));
      } else {
        this.totalPages = [];
        for (var j = 1; j < this.lastPage + 1; j++) {
          this.totalPages.push({
            id: `page ${j}`,
            display: false,
            activeStatus: false,
          });
        }
        this.totalPages = this.totalPages.map((ele, index) => ({
          ...ele,
          display: true,
          activeStatus: index == 0,
        }));
      }
    },

     async getRoles(){
      this.isLoading = true
       await axios
         .get(`${this.$store.getters.baseUrl}/role?page=${this.pageSelected}&row=${this.rows}&search=${this.search}`,{
          headers: { Authorization: `Bearer ${this.$store.getters.token}`},
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.roles = response.data.roles;
            this.lastPage = response.data.pagination.last_page;
            this.currentPage = response.data.pagination.current_page;
            this.totalUsers = response.data.pagination.total;
            if (this.rowsAndFiltersClicked) {
              this.pagination();
              this.rowsAndFiltersClicked = false;
            }
             this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false
          this.$swal("Error", error.response.data.message, "error");
        });
    },

  }
};
</script>
