<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="px-2">
          <!-- <input type="text"
              v-on:keyup.enter="save()"  v-model="roleName" @focus="roleNameEmpty = false" class="role-name-input" placeholder="Role Name" /> -->
          <!-- <div v-if="roleNameEmpty" style="color: red">Role name is required</div> -->
        </div>
        <router-link class="add-role" to="/category" title="Role">
          Back</router-link
        >
      </div>
      <div class="general-page add-role-page px-2 py-3">
        Category Name
        <div class="px-2">
          <input
            type="text"
            v-model="name"
            class="role-name-input"
            placeholder="Category Name"
          />
          <div v-if="nameEmpty" style="color: red">
            Category name is required
          </div>
        </div>

        <div class="d-flex px-2 justify-content-center">
          <button class="submit-btn" @click="save()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      loader: "",
      selected: [],
      nameEmpty: false,
      name: "",
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await axios
        .get(
          `${this.$store.getters.baseUrl}/category/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.name = response.data.category.name;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async save() {
      this.loader = true;
      if (this.name == "") {
        this.nameEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("name", this.name);
      formdata.append("_method", "PUT");
      await axios
        .post(
          `${this.$store.getters.baseUrl}/category/${this.$route.params.id}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.loader = false;
            this.$swal("Success", "Category updated successfully", "success");
            this.name = "";
            this.$router.push({ name: "category" });
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.loader = false;
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
