<template>
<html>
  <div class=""><div class="rit-body-home m-t-20">
    <div class="bac-rit-home">
      <div class="row">
        <div class="col-lg-12 margin-tb">               
          <div class="panel panel-default">             
            <div class="table-container">
              <div class="row">
                <div class="col-lg-12 margin-tb" id="printableArea">
                  <p><strong>Total Members : {{total_count}}</strong></p>
                  <table cellpadding="3" width="100%" border="1" rules="all">
                    <thead>
                      <tr>
                        <th style="width:2px">No</th>
                        <th style="width:0px">Registration No.</th>
                        <th style="width:0px">Category</th>
                        <th style="width:100px">Full Name</th>
                        <th style="width:140px">Company</th>
                        <th style="width:140px">Designation</th>
                        <th style="width:0px">Mobile</th>
                        <th style="width:0px">Email</th>
                        <th style="width:0px">City</th>
                        <th style="width:0px">Payment</th>
                      </tr>
                    </thead>
                    <tbody>                                                                           
                      <tr v-for="(registration, index) in registrations" :key="index">
                        <td>{{registration.id}}</td>
                        <td>{{registration.reg_no}}</td>
                        <td>{{registration.category.name}}</td>
                        <td>{{registration.full_name}}</td>
                        <td>{{registration.company}}</td>
                        <td>{{registration.designation}}</td>
                        <td>{{registration.mobile}}</td>
                        <td>{{registration.email}}</td>
                        <td>{{registration.city}}</td>
                        <td>{{registration.total_payment}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button class="btn btn-outline-primary mt-5" @click="printDiv('printableArea')">PRINT </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</html>
</template>

<script>
 
// @ is an alias to /src
import axios from "axios";
export default {
  name: "HomeView",
  el: '#app',
  components: {},
  data(){
    return {
      total_count:'',
      registrations:[],
      event_detail: '',
      output: null,
      eventId: localStorage.getItem('eventId'),
    };
  },
   mounted() {
    this.getPrints();
  },
  methods: {   
 
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
     async getPrints() {
      const formdata = new FormData();
      formdata.append("pdf_event_id", this.eventId);
      await axios
        .post(`${this.$store.getters.baseUrl}/report-event-pdf`,formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "success") {
            this.registrations = response.data.registrations;
            this.total_count = response.data.total_count;
            this.event_detail = response.data.event_detail;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

