<template>
  <!-- <div class="" v-bind:style="{backgroundImage: 'url(' + settings.banner_path + ')'}"> -->
  <div class="">
    <div class="ps-3 mt-3">
      <div class="general-page px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Add Spot Registration</h5>
          <!-- <router-link class="add-role" to="/titles" title="Event Report">
            Manage Title
          </router-link> -->
          <!-- <router-link class="add-role" to="/registration-kiosk" title="Role"> Back</router-link>  -->
          <!-- <router-link class="add-role"
                    :to="{ name: 'registrationkiosok'}"
                >Back</router-link> -->
          <button class="set-btn" @click="back()">Back</button>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center">
          <router-link class="add-role" to="/registration-kiosk" title="Role"> Back</router-link> 
        </div> -->
        <div class="col-12">
          <!-- <label>QR Code</label> -->
          <img
            v-bind:src="settings.banner_path"
            style="width: 100% !important"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Title</label>
              <select
                v-model="event_title"
                @change="getEventDetails()"
                @focus="event_titleEmpty = false"
                read_only
                placeholder="Select Event Name"
              >
                <option
                  v-for="(event_title, index) in event_titles"
                  :key="index"
                  :value="event_title.id"
                  readonly
                >
                  {{ event_title.title }}
                </option>
              </select>
            </div>
            <div v-if="event_titleEmpty" style="color: red">
              Title is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for=""> Name</label>
              <input
                class="input1"
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="spot_name"
                @focus="spotnameEmpty = false"
              />
            </div>
            <div v-if="spotnameEmpty" style="color: red">Name is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Category Name</label>
              <select
                v-model="category"
                @change="getEventDetails()"
                @focus="categoryEmpty = false"
                required
                placeholder="Select Category"
              >
                <option
                  v-for="(event_category, index) in event_categories"
                  :key="index"
                  :value="event_category.id"
                >
                  {{ event_category.name }}
                </option>
              </select>
            </div>
            <div v-if="categoryEmpty" style="color: red">
              Category is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Mobile</label>
              <input
                class="input"
                type="tel"
                v-on:keyup.enter="addRegistration()"
                v-model="mobile"
                @focus="mobileEmpty = false"
                maxlength="10"
              />
            </div>
            <div v-if="mobileEmpty" style="color: red">Mobile is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Email</label>
              <input
                class="input2"
                type="email"
                v-on:keyup.enter="addRegistration()"
                v-model="email"
                @focus="emailEmpty = false"
              />
            </div>
            <div v-if="emailEmpty" style="color: red">Email is required</div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Company Name</label>
              <input
                class="input3"
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="company"
                @focus="companyEmpty = false"
              />
            </div>
            <div v-if="companyEmpty" style="color: red">
              Company Name is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Designation</label>
              <input
                class="input4"
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="designation"
                @focus="designationEmpty = false"
              />
            </div>
            <div v-if="designationEmpty" style="color: red">
              Designation is required
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label for="">Source</label>
              <input
                class="input5"
                type="text"
                v-on:keyup.enter="addRegistration()"
                v-model="source"
                @focus="sourceEmpty = false"
                placeholder="How did you come to know about the event?"
              />
            </div>
          </div>

          <div class="simple-keyboard"></div>

          <div class="d-flex justify-content-end me-2">
            <button class="submit-btn" v-on:click="addRegistration()">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import "simple-keyboard/build/css/index.css";
import keyboard from "vue-keyboard";

export default {
  el: "#app",
  components: {
    keyboard,
  },
  data() {
    return {
      spot_name: "",
      spotnameEmpty: false,
      company: "",
      companyEmpty: false,
      designation: "",
      designationEmpty: false,
      category: "",
      categoryEmpty: false,
      source: "",
      sourceEmpty: false,
      mobile: "",
      mobileEmpty: false,
      email: "",
      emailEmpty: false,
      title: "",
      titleEmpty: false,
      event_detail: "",
      event_detailEmpty: false,
      event_title: "",
      event_titleEmpty: false,
      events: [],
      event: "",
      event_kiosk_id: "",
      eventEmpty: false,
      event_details: [],
      event_name: "",
      event_nameEmpty: false,
      event_categories: [],
      event_titles: [],
      event_names: [],
      input: "",
      value: "",
      keyboard: "",
      settings: "",
    };
  },

  mounted() {
    this.getKisoks();
    if (window) {
      import("simple-keyboard").then((KeyboardClass) => {
        const Keyboard = KeyboardClass.default;
        let keyboard = new Keyboard({
          onChange: (input) => this.onChange(input),
          onChange: (input1) => this.onChange(input1),
          onChange: (input2) => this.onChange(input2),
          onChange: (input3) => this.onChange(input3),
          onChange: (input4) => this.onChange(input4),
          onChange: (input5) => this.onChange(input5),
          onKeyPress: (button) => this.onKeyPress(button),
          onKeyPress: (button1) => this.onKeyPress(button1),
          onKeyPress: (button2) => this.onKeyPress(button2),
          onKeyPress: (button3) => this.onKeyPress(button3),
          onKeyPress: (button4) => this.onKeyPress(button4),
          onKeyPress: (button5) => this.onKeyPress(button5),
        });
      });
    }

    this.getKiosk();
  },
  methods: {
    async getKiosk() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.settings = response.data.settings;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    onChange(input) {
      document.querySelector(".input").value = input;
      if (this.mobile) {
        this.mobile = input;
        console.log("mobile", this.mobile);
      }
    },
    onChange(input1) {
      document.querySelector(".input1").value = input1;
      if (this.spot_name) {
        this.spot_name = input1;
        console.log("spot_name", this.spot_name);
      }
    },
    onChange(input2) {
      document.querySelector(".input2").value = input2;
      if (this.email) {
        this.email = input2;
        console.log("email", this.email);
      }
    },
    onChange(input3) {
      document.querySelector(".input3").value = input3;
      if (this.company) {
        this.company = input3;
        console.log("company", this.company);
      }
    },
    onChange(input4) {
      document.querySelector(".input4").value = input4;
      if (this.designation) {
        this.designation = input4;
        console.log("designation", this.designation);
      }
    },
    onChange(input5) {
      document.querySelector(".input5").value = input5;
      if (this.source) {
        this.source = input5;
        console.log("source", this.source);
      }
    },

    onKeyPress(button) {
      console.log("Button pressed", button);
    },
    onKeyPress(button1) {
      console.log("Button pressed", button1);
    },
    onKeyPress(button2) {
      console.log("Button pressed", button2);
    },
    onKeyPress(button3) {
      console.log("Button pressed", button3);
    },
    onKeyPress(button4) {
      console.log("Button pressed", button4);
    },
    onKeyPress(button5) {
      console.log("Button pressed", button5);
    },

    uploadFiles(event) {
      this.file = event.target.files[0];
    },

    async getKisoks() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk-setting`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.kiosk_settings = response.data.kiosk_settings;
            this.event_kiosk_id = response.data.kiosk_settings.event_id;
            this.getEventDetails();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async getEventDetails() {
      await axios
        .get(`${this.$store.getters.baseUrl}/kiosk-spot-reg`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.event_names = response.data.event;
            this.event_details = response.data.events;
            this.event_categories = response.data.categories;
            this.event_titles = response.data.titles;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async addRegistration() {
      if (this.spot_name == "") {
        this.spotnameEmpty = true;
        return;
      }
      if (this.category == "") {
        this.categoryEmpty = true;
        return;
      }
      if (this.company == "") {
        this.companyEmpty = true;
        return;
      }
      if (this.designation == "") {
        this.designationEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("title_id", this.event_title);
      formdata.append("category_id", this.category);
      formdata.append("fullname", this.spot_name);
      formdata.append("company", this.company);
      if (this.mobile) {
        formdata.append("mobile", this.mobile);
      }
      formdata.append("designation", this.designation);
      if (this.email) {
        formdata.append("email", this.email);
      }
      if (this.source) {
        formdata.append("source", this.source);
      }
      await axios
        .post(`${this.$store.getters.baseUrl}/kiosk-spot-reg-post`, formdata)
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal(
              "Success",
              "Spot Registration added successfully",
              "success"
            );
            this.$router.push({
              name: "kioskspotregistrationlist",
              params: { id: response.data.reg_info.id },
            });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async back() {
      // this.$router.push({name: 'registrationkiosok'});
      window.location.href = "/#/registration-kiosk";
      window.location.reload();
    },
  },
};
</script>
