import { createRouter, createWebHashHistory } from "vue-router";

import hasPermission from "../router/middleware";
//import App from "App.vue";
import HomeView from "../views/HomeView.vue";
import DashboardView from "../views/DashboardView";
import SignInView from "../views/SignInView.vue";
import GeneralView from "../views/GeneralView.vue";
import UserView from "../views/UserView.vue";
import CategoryView from "../views/CategoryView.vue";
import CategoryEdit from "../views/EditCategory.vue";
import RoleView from "../views/RolesView.vue";
import AddRoleView from "../views/AddRoleView.vue";
import EditRoleView from "../views/EditRoleView.vue";
import AddUserView from "../views/AddUserView.vue";
import EditUserView from "../views/EditUserView.vue";
import EventListView from "../views/EventListView.vue";
import CreateEventView from "../views/CreateEventView.vue";
import MoreCreateEventView from "../views/MoreCreateEventView.vue";
import ManageEventView from "../views/ManageEventView.vue";
import ManageTitleView from "../views/ManageTitleView.vue";
import AddTitleView from "../views/AddTitleView.vue";
import EditTitleView from "../views/EditTitleView.vue";
import RegistrationView from "../views/RegistrationView.vue";
import RegistrationPrintView from "../views/RegistrationPrintView.vue";
import RegistrationListView from "../views/RegistrationListView.vue";
import SpotRegistrationView from "../views/SpotRegistrationView.vue";
import NewSpotRegistrationView from "../views/NewSpotRegistrationView.vue";
import QrImageView from "../views/QrImageView.vue";
import SpotRegistrationListView from "../views/SpotRegistrationListView.vue";
import PrintView from "../views/PrintView.vue";
import KioskPrintView from "../views/KioskPrintView.vue";
import PrintEventReportView from "../views/PrintEventReportView.vue";
import PrintBadgeView from "../views/PrintBadgeView.vue";
import PrintBadgeEditView from "../views/PrintBadgeEditView.vue";
import ScanView from "../views/ScanView.vue";
import MagazinEntryView from "../views/MagazinEntryView.vue";
import HallEntryView from "../views/HallEntryView.vue";
import HallExitView from "../views/HallExitView.vue";
import FoodEntryView from "../views/FoodEntryView.vue";
import EventReportView from "../views/EventReportView.vue";
import KioskView from "../views/KioskView.vue";
import KioskSettingView from "../views/KioskSettingView.vue";
import KioskRegistrationView from "../views/KioskRegistrationView.vue";
import KioskSpotRegistrationView from "../views/KioskSpotRegistrationView.vue";
import KioskSpotRegistrationListView from "../views/KioskSpotRegistrationListView.vue";
import KioskPreRegPostView from "../views/KioskPreRegPostView.vue";
import KioskPreRegPostEdit from "../views/KioskPreRegPostEdit.vue";

// const Category_List = () => import("@/views/CategoryView.vue")
// const Category_Create = () => import("@/views/forms/CategoryCreate.vue")
// const Category_Edit = () => import("@/views/forms/CategoryEdit.vue")

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    beforeEnter: (to, from) => {
      if (!localStorage.token) {
        router.push({ name: "signIn" });
      } else {
        return true;
      }
    },
  },
  {
    path: "/",
    name: "signIn",
    component: SignInView,
    beforeEnter: (to, from) => {
      if (localStorage.token) {
        router.push("/dashboard");
      } else {
        return true;
      }
    },
  },
  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  //   component: DashboardView,
  // },
  // {
  //   path: "/",
  //   name: "signIn",
  //   component: SignInView,
  // },
  {
    path: "/general",
    name: "general",
    component: GeneralView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-ADD-GENERAL" },
  },
  {
    path: "/roles",
    name: "roles",
    component: RoleView,
  },
  {
    path: "/users",
    name: "users",
    component: UserView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-LIST-USERS" },
  },
  {
    path: "/print/:id",
    name: "print",
    component: PrintView,
  },
  {
    path: "/kiosk-print/:id",
    name: "kioskprint",
    component: KioskPrintView,
  },
  {
    path: "/printeventreport",
    name: "printeventreport",
    component: PrintEventReportView,
  },
  {
    path: "/category",
    name: "category",
    component: CategoryView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-LIST-CATEGORY" },
  },
  {
    path: "/category-edit/:id",
    name: "Edit Category",
    component: CategoryEdit,
  },
  {
    path: "/add-role",
    name: "addrole",
    component: AddRoleView,
  },
  {
    path: "/edit-role/:id",
    name: "Edit Role",
    component: EditRoleView,
  },
  {
    path: "/add-user",
    name: "adduser",
    component: AddUserView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-ADD-USERS" },
  },
  {
    path: "/edit-user/:id",
    name: "edituser",
    component: EditUserView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-EDIT-USERS" },
  },
  {
    path: "/event-list",
    name: "eventlist",
    component: EventListView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-LIST-EVENT" },
  },
  {
    path: "/create-event",
    name: "createevent",
    component: CreateEventView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-ADD-EVENT" },
  },
  {
    path: "/more-create-event/:id?/:type?",
    name: "morecreateevent",
    component: MoreCreateEventView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-ADD-EVENT" },
  },
  {
    path: "/manage-event",
    name: "manageevent",
    component: ManageEventView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-LIST-MANAGE EVENTS" },
  },
  {
    path: "/titles",
    name: "titles",
    component: ManageTitleView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-MANAGE TITLE-REGISTRATION" },
  },
  {
    path: "/add-title",
    name: "addtitle",
    component: AddTitleView,
  },
  {
    path: "/edit-title/:id",
    name: "edittitle",
    component: EditTitleView,
  },
  {
    path: "/registration",
    name: "registration",
    component: RegistrationView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-REGISTRATION-REGISTRATION" },
  },
  {
    path: "/registration-edit/:id/:eventId",
    name: "registrationview",
    component: RegistrationPrintView,
  },
  {
    path: "/registration-list",
    name: "registrationlist",
    component: RegistrationListView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-REGISTRATION-REGISTRATION" },
  },
  {
    path: "/spot-registration",
    name: "spotregistration",
    component: SpotRegistrationView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-SPOT REGISTRATION-REGISTRATION" },
  },
  {
    path: "/add-spot-registration/:id",
    name: "addspotregistration",
    component: NewSpotRegistrationView,
  },
  {
    path: "/qrcode/:id",
    name: "qrimage",
    component: QrImageView,
  },
  {
    path: "/spot-registration-list",
    name: "spotregistrationlist",
    component: SpotRegistrationListView,
  },
  {
    path: "/printer",
    name: "printbadge",
    component: PrintBadgeView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-LIST-PRINT BADGE" },
  },
  {
    path: "/edit-registration/:id",
    name: "editprinter",
    component: PrintBadgeEditView,
  },
  {
    path: "/scan",
    name: "scan",
    component: ScanView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-SCAN ENTRY DEFAULT-SCAN ENTRY" },
  },
  {
    path: "/magazin-entry",
    name: "magazinentry",
    component: MagazinEntryView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-SCAN KITBAG MAGAZINE CERTIFICATE-SCAN ENTRY" },
  },
  {
    path: "/hall-entry",
    name: "hallentry",
    component: HallEntryView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-SCAN HALL ENTRY-SCAN ENTRY" },
  },
  {
    path: "/hall-exit",
    name: "hallexit",
    component: HallExitView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-SCAN HALL EXIT-SCAN ENTRY" },
  },
  {
    path: "/food-entry",
    name: "foodentry",
    component: FoodEntryView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-SCAN FOOD-SCAN ENTRY" },
  },
  {
    path: "/event-report",
    name: "eventreport",
    component: EventReportView,
    beforeEnter: hasPermission,
    meta: { permission: "CAN-EVENT REPORT DEFAULT-EVENT REPORT" },
  },
  {
    path: "/kiosk",
    name: "kiosk",
    component: KioskView,
  },
  {
    path: "/kiosk-registration",
    name: "kiosksetting",
    component: KioskSettingView,
  },
  {
    path: "/kiosk-preregpost/:id/:reg_no",
    name: "kiosokpreregpost",
    component: KioskPreRegPostView,
  },
  {
    path: "/kiosk-pre-reg-post-update/:id",
    name: "kioskpreregpostupdate",
    component: KioskPreRegPostEdit,
  },
  {
    path: "/kiosk-spotregistration",
    name: "kioskspotregistration",
    component: KioskSpotRegistrationView,
  },
  {
    path: "/registration-kiosk",
    name: "registrationkiosok",
    component: KioskRegistrationView,
  },
  {
    path: "/kiosk-spotregistration-list/:id",
    name: "kioskspotregistrationlist",
    component: KioskSpotRegistrationListView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;

/*export default new Router({
  
  linkActiveClass: 'active',
  linkExactActiveClass: '',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "signIn",
    component: SignInView,
  },
  {
    path: "/general",
    name: "general",
    component: GeneralView,
  },
  {
    path: "/roles",
    name: "roles",
    component: RoleView,
  },
  {
    path: "/users",
    name: "users",
    component: UserView,
  },
],
});*/
