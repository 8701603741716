<template>
  <div class="d-flex align-items-center justify-content-center" >
    <div class="left-log col d-none d-lg-flex">
      <div class="position-relative left-log-img">
        <div class="squar1"></div>
        <div class="squar2"></div>
        <div class="squar3"></div>
        <img class="" src="../assets/img/login-form-img.png" alt="" />
      </div>
    </div>
    <div class="rit-log col">
      <div class="login-main">
        <img class="logo" src="../assets/img/logo.png" alt="" />
        <h4 class="regis-tration">Registration</h4>
        <form @submit.stop.prevent="onSubmit" class="flex-wrap">
          <div class="col-12 col-sm-12 label-input px-1 mt-2">
            <label for="">First Name</label>
            <input
              type="text"
              placeholder="First Name"
              v-model="v$.form.username.$model"
              :state="!v$.form.username.$error && null"
              aria-describedby="username-feedback"
            />
            <div v-if="v$.form.username.$error">
              Please enter your name
            </div>
          </div>
          <div class="col-12 col-sm-12 label-input px-1 mt-2">
            <label for="">Password</label>
            <input
              type="password"
              v-model="v$.form.password.$model"
              :state="!v$.form.password.$error && null"
              aria-describedby="password-feedback"
              placeholder="Password"
            />
             <div v-if="v$.form.password.$error">
              please enter your password
            </div>
          </div>
            <div v-if="loginErrors">
                    {{ loginErrors }}
           </div>
          <div class="login-submit mb-5">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import  useVuelidate  from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  //mixins: [validationMixin],
   setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(5),
      },
    },
  },
  computed: {
    loginErrors() {
      return this.$store.getters.loginErrors;
    },
  },
  methods: {
    onSubmit() {
      this.v$.form.$touch();

      if (this.v$.$errors.length > 0) {
        return;
      }
       console.log("store");
      let username = this.form.username;
      let password = this.form.password;
      this.$store.dispatch("login", { username, password });
    },
  },
};
</script>
