<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="p-2 rit-top w-100 overflow-x-scroll">
        <div class="rit-dash d-flex flex-wrap col">
          <div
            class="dasboard-grid px-1"
            v-if="$store.getters.adminDetail.includes('CAN-ADD-GENERAL')"
          >
            <a
              href="#/general"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-users-line"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/general" title="general">
                  <h4>GENERAL</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="$store.getters.adminDetail.includes('CAN-LIST-EVENT')"
          >
            <a
              href="#/more-create-event"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-check-double"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/more-create-event" title="events">
                  <h4>CREATIVE EVENTS</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="$store.getters.adminDetail.includes('CAN-LIST-MANAGE EVENTS')"
          >
            <a
              href="#/manage-event"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-coins"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/manage-event" title="manage events">
                  <h4>MANAGE EVENTS</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-REGISTRATION-REGISTRATION'
              )
            "
          >
            <a
              href="#/registration"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-spinner"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/registration" title="registration">
                  <h4>REGISTRATION</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="$store.getters.adminDetail.includes('CAN-LIST-PRINT BADGE')"
          >
            <a
              href="#/printer"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-rectangle-xmark"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/printer" title="print badge">
                  <h4>PRINT BADGE</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-LIST-EVENT REPORT DEFAULT-EVENT REPORT'
              )
            "
          >
            <a
              href="#/event-report"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-folder-tree"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/event-report" title="event report">
                  <h4>EVENT REPORT</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="
              $store.getters.adminDetail.includes(
                'CAN-SCAN ENTRY DEFAULT-SCAN ENTRY'
              )
            "
          >
            <a
              href="#/scan"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-user-shield"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/scan" title="scsn entry">
                  <h4>SCAN ENTRY</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div
            class="dasboard-grid px-1"
            v-if="$store.getters.adminDetail.includes('CAN-LIST-USERS')"
          >
            <a
              href="#/users"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-people-pulling"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/users" title="users">
                  <h4>USERS</h4>
                </router-link>
              </div>
            </a>
          </div>
          <div class="dasboard-grid px-1">
            <a
              href="#/kiosk"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-solid fa-bell"></i></p>
              <div class="dash-bord dah-color">
                <router-link to="/kiosk" title="kiosk">
                  <h4>KIOSK SETTINGS</h4>
                </router-link>
              </div>
            </a>
          </div>
          <!-- <div class="dasboard-grid px-1">
            <a
              href="pool-structure.php"
              class="box-index d-flex align-items-center user-box"
            >
              <p><i class="fa-brands fa-galactic-republic"></i></p>
              <div class="dash-bord">
                <h4>Pool Structure</h4>
                
              </div>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
export default { name: "HomeView", components: { TopHeader }, }
