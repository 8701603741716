<template>
  <div class="">
    <TopHeader />
    <div class="ps-3 mt-3">
      <div class="general-page mt-5 px-4 py-3">
        <h2 class="content-heading mb-5">change Password</h2>

        <div class="d-flex flex-wrap">
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">New Password</label>
              <input
                type="password"
                v-on:keyup.enter="save()"
                v-model="password"
                @focus="passwordEmpty = false"
                required
              />
              <div v-if="passwordEmpty" style="color: red">
                New Password is required
              </div>
            </div>
          </div>
          <div class="mt-3 col-12 col-lg-6">
            <div class="input-box">
              <label class="mb-0" for="">Confirm Password</label>
              <input
                type="password"
                v-on:keyup.enter="save()"
                v-model="password_confirmation"
                @focus="
                  (password_confirmationEmpty = false),
                    (confirmpasswordMatch = false)
                "
              />
              <div v-if="password_confirmationEmpty" style="color: red">
                Confirm Password is required
              </div>
              <div v-if="confirmpasswordMatch" style="color: red">
                password does not match required
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end me-2">
            <!-- <button class="submit-btn">Submit</button> -->
            <button class="submit-btn" variant="success" @click="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/TopHeader.vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { TopHeader },
  data() {
    return {
      password: "",
      passwordEmpty: false,
      password_confirmation: "",
      password_confirmationEmpty: false,
      confirmpasswordMatch: false,
    };
  },
  methods: {
    async save() {
      if (this.password == "") {
        this.passwordEmpty = true;
        return;
      }
      if (this.password_confirmation == "") {
        this.password_confirmationEmpty = true;
        return;
      }
      if (!(this.password_confirmation == this.password)) {
        this.confirmpasswordMatch = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("password", this.password);
      formdata.append("password_confirmation", this.password_confirmation);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/admin/change-password`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response);
            this.$swal(
              "Success",
              "Password can be changed successfully",
              "success"
            );
            this.$router.push({ name: "home" });
            this.password = "";
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
