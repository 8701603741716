<template>
  <div class="">
    <div class="ps-3 mt-3 pb-2">
      <div class="general-page badge-img px-2 py-3">
        <h5 class="mt-4">Badge Image:</h5>
        <div class="mt-3 mb-3 col-12">
          <div class="single-select">
            <label>Snap Shot</label>
            <input
              type="file"
              name="file"
              @change="uploadImage($event)"
              @focus="fileEmpty = false"
              required
            />
            
            <!-- <input type="file" @change="uploadImage($event)" /> -->

          </div>
        </div>

        <div>
          <h5 class="mt-4">Choose Badge Information:</h5>
        </div>
        <div class="mt-3 d-flex flex-wrap">
          <div class="mt-1 mb-2 col-6">
            <div class="single-select">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="title"
                  placeholder="10"
                />Title</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="name"
                  placeholder="10"
                />Name</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="company"
                  placeholder="10"
                />Company</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="mobile"
                  placeholder="10"
                />Mobile No</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="designation"
                  placeholder="10"
                />Designation</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="city"
                  placeholder="10"
                />City</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="reg_no"
                  placeholder="10"
                />Registration No</label
              >
            </div>
          </div>
          <div class="mt-1 mb-2 col-6">
            <div class="single-select col">
              <label class="mb-0">
                <input
                  type="checkbox"
                  v-model="category"
                  placeholder="10"
                />Category</label
              >
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="single-select">
            <h5 class="mb-0 mt-3">
              <input type="checkbox" v-model="photo" placeholder="10" />Photo
            </h5>
          </div>
          <div class="single-select">
            <h5 class="mb-0 mt-3 mx-5">
              <input type="checkbox" @change="changeQr" v-model="qr_code" placeholder="10" />
              Qrcode
            </h5>
          </div>
          <div class="single-select">
            <h5 class="mb-0 mt-3 me-0">
              <input type="checkbox" @change="changeBr" v-model="bar_code" placeholder="10" />
              Bar code
            </h5>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-start ms-1 mb-2">
        <button @click="designBatch" class="submit-btn mt-0">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueQrcode from "vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      title: false,
      name: false,
      company: false,
      mobile: false,
      designation: false,
      city: false,
      reg_no: false,
      category: false,
      photo: false,
      bar_code: false,
      qr_code: false,
      file: "",
      barcode_data: "",
      selectedImage: [],
      qr_code_data: "",
    };
  },
  mounted() {},
  methods: {
    uploadImage(event) {
      this.selectedImage = event.target.files[0];
    },
    
    changeQr(){
      if(this.qr_code == true){
        this.bar_code = false;
      }else{
        this.bar_code = true;
      }
    },

     changeBr(){
      if(this.bar_code == true){
        this.qr_code = false;
      }else{
        this.qr_code = true;
      }
    },

    async designBatch() {
      const formdata = new FormData();
      formdata.append("event_id", this.$route.params.id);
      if (this.title == true) {
        formdata.append("batch_info[]", "title");
      }
      if (this.name == true) {
        formdata.append("batch_info[]", "badgename");
      }
      if (this.company == true) {
        formdata.append("batch_info[]", "company");
      }
      if (this.mobile == true) {
        formdata.append("batch_info[]", "mobile");
      }
      if (this.designation == true) {
        formdata.append("batch_info[]", "designation");
      }
      if (this.category == true) {
        formdata.append("batch_info[]", "category");
      }
      if (this.city == true) {
        formdata.append("batch_info[]", "city");
      }
      if (this.reg_no == true) {
        formdata.append("batch_info[]", "reg_no");
      }
      if (this.qr_code == true) {
        this.bar_code = false;
        formdata.append("batch_info[]", "qrcode");
      }
      if (this.photo == true) {
        formdata.append("batch_info[]", "photo");
      }
      if (this.bar_code == true) {
        this.qr_code = false;
        formdata.append("batch_info[]", "bar_code");
      }
      if (this.confname == true) {
        formdata.append("batch_info[]", "confname");
      }
      if (this.selectedImage != null) {
        formdata.append("photo", this.selectedImage);
      }


      await axios
        .post(`${this.$store.getters.baseUrl}/event/upload-batch`, formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // var batch_info = response.data.event_batch.batch_info;
            // this.barcode_data = response.data.barcode_data;
            // if (batch_info.includes("qrcode")) {
            //   this.qr_code_data = response.data.qrCode;
            // } else {
            //   this.barcode_data = response.data.barcode_data;
            // }
            this.$swal({
              position: 'middle',
              icon: 'success',
              title: 'Event Batch setup details added successfully',
              timer: 1500
            })
            // this.$swal(
            //   "Success",
            //   "Event Batch setup details added successfully",
            //   "success"
            // );
            this.$router.push({
              name: "morecreateevent",
              params: { id: this.$route.params.id, type: 6 },
            });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
